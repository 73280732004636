import React, { useContext } from "react";
import "./QuestionItem.css";
import M from "materialize-css";
import DropDownMenu from "../../General/DropDownMenu/DropDownMenu";
import { QuestionContext } from "../../../Utils/contexts/QuestionContext";

const QuestionItem = ({ question }) => {
  const {  setSequence, selectedQuestion, setSelectedQuestion, isViewingAllQuestions } = useContext(QuestionContext);
  const mapSequenceToNumber = (string) => {
    switch(string){
      case "FIRST":
        return 1;
      case "SECOND":
        return 2;
      case "THIRD":
        return 3;
      case "FOURTH":
        return 4;
      case "FIFTH":
        return 5;
      default:
        throw new Error("InternalServerError");
    }
  };
  const openQuestionModal = () => {
    setSelectedQuestion(question);
    const sequence = [
      mapSequenceToNumber(question.answerSequence.answer1),
      mapSequenceToNumber(question.answerSequence.answer2),
      mapSequenceToNumber(question.answerSequence.answer3),
      mapSequenceToNumber(question.answerSequence.answer4),
      mapSequenceToNumber(question.answerSequence.answer5)
    ];
    setSequence(sequence); 
    document.getElementById("questionsModal").M_Modal.open();
  };

  const menuObjects = [
    {
      text: "Visa detaljer",
      icon: "aspect_ratio",
      customOnClick: 
        openQuestionModal,
    },
    {
      text: "Ta bort",
      icon: "delete",
      customOnClick: () => {
        setSelectedQuestion(question);
        document.getElementById("DeleteQuestionModal").M_Modal.open();
      }
    },
  ];

  const formulation = question.translations[0].formulation;
  return (
    <div
      className="Item-card-question"
      id={`question-item-${question.orderNumber}`}
    >
      <div className={"Q-body"}>
        <div>
          <p>
            {question.orderNumber}. {formulation}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "5vh",
              padding: "0 5px 0 5px"
            }}
          >
            <DropDownMenu
              ulId={`QuestionDropDownModal_${question.id}`}
              menuObjects={menuObjects}
            />
          </div>
          {isViewingAllQuestions && <i className="material-icons Drag-handler">drag_handle</i>}
        </div>
      </div>
    </div>
  );
};

export default QuestionItem;
