import React, { useContext, createRef, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ParticipantFormContext } from "../../../Utils/contexts/ParticipantFormContext";
import adviserpartnerlogo from "../../../assets/adviser-partner-logo.png";
import "materialize-css";

const ParticipantFormHeader = ({ items, isTabletOrMobile }) => {
  const useStyles = makeStyles({
    ul: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      display: "flex",
      background: "white",
      justifyContent: "center",
      borderBottom: "3px solid #98C742",
    },
  });
  const classes = useStyles();

  const ref = createRef();

  const { pageState, currentPage, formLanguage } = useContext(
    ParticipantFormContext
  );

  useLayoutEffect(() => {
    var elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, { enterDelay: 700 });
  }, [ref, pageState]);

  const toolTipTranslation = (className) => {
    if (className.includes("Answered-pagination")) {
      return formLanguage === "SE" ? "Besvarat" : "Fully answered";
    } else if (className.includes("Partially-answered-pagination")) {
      return formLanguage === "SE" ? "Delvis besvarat" : "Partially answered";
    } else {
      return formLanguage === "SE" ? "Ej besvarat" : "Not answered";
    }
  };

  return (
    <nav
      style={{
        margin: "0 0 5vh 0",
        background: "none",
        position: "fixed",
        top: "0",
        zIndex: 1,
      }}
    >
      {/* <img alt="adviser partner logo" className="logo" src={adviserpartnerlogo} /> */}
      <ul className={classes.ul}>
        {!isTabletOrMobile ? (
          items.map(({ page, type, selected, ...item }, index) => {
            let children = null;
            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = <span style={{ color: "black" }}> -...</span>;
            } else if (type === "page") {
              children = (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      color: index > 0 ? "black" : "none",
                      cursor: "default",
                    }}
                  >
                    -
                  </span>
                  <button
                    id={`pag_${page}`}
                    type="button"
                    data-position="top"
                    data-tooltip={toolTipTranslation(pageState[index])}
                    ref={ref}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#bababa",
                      margin: "0 5px 0 5px",
                      borderRadius: "50px",
                      borderStyle: "none",
                      minWidth: "25px",
                      minHeight: "25px",
                      cursor: "pointer",
                    }} // Partially-answered-pagination , Answered-pagination, Pagination-button-selected, Pagination-button-unselected
                    className={"tooltipped " + pageState[page - 1]}
                    {...item}
                  >
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        textAlign: "center",
                        fontSize: "0.7rem",
                      }}
                    >
                      {page}
                    </p>
                  </button>
                </div>
              );
            } else {
              children = (
                <button type="button" {...item}>
                  {type}
                </button>
              );
            }
            return <li key={index}>{children}</li>;
          })
        ) : (
          <p style={{ color: "black" }}>
            {currentPage}/{items.length}
          </p>
        )}
      </ul>
      <img
        style={{
          position: "absolute",
          top: "15%",
          right: "1%",
          height: "100px",
        }}
        src={adviserpartnerlogo}
        alt="Logo"
      />
    </nav>
  );
};

export default ParticipantFormHeader;
