import { API, graphqlOperation } from "aws-amplify";
import { changeCustomerConsultant } from "../../graphql/mutations";
import ApiError from "../error/ApiError";

const ChangeCustomerConsultant = async (customerId, user) => {
  try {
    const input = { id: customerId, username: user };
    const changedCustomer = await API.graphql(
      graphqlOperation(changeCustomerConsultant, input)
    );
    return changedCustomer;
  } catch (err) {
    throw new ApiError("Error when trying to change customer consultant", JSON.stringify(err));
  }
};

export default ChangeCustomerConsultant;
