import { API, graphqlOperation } from "aws-amplify";
import { getCostReport } from "../../graphql/queries";
import ApiError from "../error/ApiError";
import {pastDateValidator} from "./inputFieldValidation";

const GetCostReport = async (fromDate, toDate) => {
  try {
    const fromValidation = pastDateValidator.validate(fromDate);
    if(!(fromValidation.error === undefined)){
      throw new Error("Invalid date format:" + fromDate);
    }
    const toValidation = pastDateValidator.validate(toDate);
    if(!(toValidation.error === undefined)){
      throw new Error("Invalid date format:" + toDate);
    } 
  } catch (error) {
    throw error;
  }


  try {
    const input = { fromDate: fromDate, toDate: toDate };
    const costReport = await API.graphql(
      graphqlOperation(getCostReport, input)
    );
    return costReport.data.getCostReport;
  } catch (err) {
    throw new ApiError("Error when trying get cost report", JSON.stringify(err));
  }
};

export default GetCostReport;
