import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import "./ModalInput.css";

export const ModalInput = ({ input, inputField, meta }) => {
  return (
    <div className={inputField.divName} style={inputField.style}>
      {inputField.onLanguageChange && (
        <OnChange name={"languageChange"}>
          {inputField.onLanguageChange.setLanguage}
        </OnChange>
      )}
      {inputField.onDateChange && (
        <OnChange name={inputField.onDateChange.name}>
          {(value) => {
            try {
              let newDate = new Date(value);
              const dateToday = new Date();
              if (
                dateToday >
                newDate.setDate(
                  newDate.getDate() - inputField.onDateChange.difference
                )
              ) {
                newDate = dateToday;
              }
              input.onChange(newDate.toISOString().split("T")[0]);
            } catch (e) {}
          }}
        </OnChange>
      )}

      <label
        htmlFor={inputField.id}
        className={inputField.labelName}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {inputField.header}
      </label>

      {input && (
        <input
          {...input}
          autoComplete="off"
          data-testid={inputField.header}
          id={inputField.id}
          type={inputField.type}
          min={inputField.minDate}
          aria-label="modal-input"
          placeholder={inputField.placeHolder}
          value={inputField.disabled ? "" : input.value}
          className={inputField.labelName}
          disabled={inputField.disabled}
          readOnly={inputField.disabled ? "readonly" : ""}
        />
      )}
      {inputField.field && (
        <Field
          name={inputField.field.name}
          aria-label="modal-input"
          component={inputField.field.component}
          initialValue={inputField.field.initialValue}
          className={"hideSelector"}
        >
          {inputField.field.options.map((val) => {
            return <option key={val}>{val}</option>;
          })}
          ;
        </Field>
      )}

      {meta &&
        (meta.error || meta.submitError || meta.submitErrors) &&
        meta.touched && (
          <span style={{ color: "rgb(219, 75, 26)", textAlign: "left" }}>
            {meta.error || meta.submitError || meta.submitErrors}
          </span>
        )}
    </div>
  );
};
