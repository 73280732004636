import { useContext } from "react";
import { ReportContext } from "../../../Utils/contexts/ReportContext";
import {TRANSLATIONS} from "../Helpers/Translations";
import {LANGUAGE} from "../../../Utils/language";

const LanguageDropDown = () => {
  const {
    language, setLanguage
  } = useContext(ReportContext);
  return (
    <div className="input-field">
      <select
        className="Drop-down-selector hideSelector"
        id = "LanguageDropdown"
        onChange={(e) => {
          setLanguage(e.target.value);
        }}
      >
        <option value={LANGUAGE.SWEDISH}>{TRANSLATIONS.SWEDISH.SWEDISH}</option>
        <option value={LANGUAGE.ENGLISH}>{TRANSLATIONS.ENGLISH.SWEDISH}</option>
      </select>
      <label>{TRANSLATIONS.LANGUAGE.SWEDISH}</label>
    </div>
  );
};

export default LanguageDropDown;