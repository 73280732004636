import "./ReportComponentParent.css";
import CustomerSelector from "../CustomerSelector/CustomerSelector";
import LanguageDropDown from "../LanguageDropDown/LanguageDropDown";
import DropZoneList from "../DropZoneList/DropZoneList";
import React, { useEffect, useContext } from "react";
import M from "materialize-css";
import { listCustomers, generateReport } from "../../../graphql/queries";
import ProjectList from "../ProjectList/ProjectList";
import FormList from "../FormList/FormList";
import { DragDropContext} from "react-beautiful-dnd";
import { ReportContext } from "../../../Utils/contexts/ReportContext";
import Preloader from "../../General/Preloader/Preloader";
import TextInputField from "../TextInputField/TextInputField";
import { TRANSLATIONS } from "../Helpers/Translations";
import { validInputString } from "../../../Utils/functions/inputFieldValidation";
import { API, graphqlOperation } from "aws-amplify";
import { useSnackbar } from "notistack";
import { LANGUAGE } from "../../../Utils/language";
import { Auth } from "aws-amplify";

const ReportComponentParent = () => {
  const {
    fetchCustomers,
    setIsLoaded, isFetchingProjects,
    dropZoneForms, setDropZoneForms,
    generatingForm, setGeneratingForm,
    selectedProjectId,
    setInvalidName, 
    setLanguage,
    language, nameOfReport,
    formCopies, setFormCopies,
    setMinimumFormsError,
    fetchMyCustomers, setCurrentUser, currentUser,
    projects
  } = useContext(ReportContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    Promise.all([getAuth()]);
    M.AutoInit();
  }, []);

  useEffect(() => {
    if (currentUser !== ""){
      fetchMyCustomers().then(()=>{setIsLoaded(true);});
      fetchCustomers();
    }
  }, [currentUser]);
  

  const getAuth = async () => {
    const user = (await Auth.currentAuthenticatedUser());
    setCurrentUser(user?.signInUserSession.accessToken.payload.username);
  };

  const download = (url) => {
    var tempElement = document.createElement("a");
    tempElement.href = url;
    tempElement.setAttribute("download", nameOfReport);
    tempElement.click();
  };

  const submitDropZone = async () => {
    setMinimumFormsError(false);
    const makeAggregatedReport = document.getElementById("report_type").checked;
    const nameValidation = validInputString.validate(nameOfReport);
    if (dropZoneForms.length === 0) {
      createNotification("Kunde inte generera rapport.", "error");
      setTimeout(() => {
        createNotification(
          "Det behövs minst ett valt formulär för att generera en rapport.",
          "info",
          30000,
          (key) => (
            <div style={{margin: "5px", cursor: "pointer"}}
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              AVBÖJ
            </div>
          )
        );
      }, 500);
      setMinimumFormsError(true);
      return;
    }
    if (nameValidation.error !== undefined) {
      setInvalidName(true);
      createNotification("Felaktigt rapportnamn.", "error");
      return;
    }
    setInvalidName(false);
    setGeneratingForm(true);
    const formIds = dropZoneForms.map((e) => e.id);
    try {
      const response = await API.graphql(
        graphqlOperation(generateReport, {
          reportName: nameOfReport,
          language: language,
          formIds: formIds,
          makeAggregatedReport: makeAggregatedReport,
        })
      );
      const url = response.data.generateReport;
      download(url);
      setGeneratingForm(false);
      // window.location.reload();
      createNotification("Rapporten sparades på din dator.", "success");
    } catch (err) {
      setGeneratingForm(false);
      createNotification("Nedladdning av rapport misslyckades.", "error");
    }
  };

  const swapDropdownLanguage = (language) => {
    setLanguage(LANGUAGE.ENGLISH);
    const languageDropdown = document.getElementById("LanguageDropdown");
    languageDropdown.value = LANGUAGE.ENGLISH;
    M.FormSelect.init(languageDropdown);
  };
  const onDragEnd = (dropProps) => {
    const { destination, source } = dropProps;
    if (!destination) {
      return;
    }
    if (!(destination.droppableId === "formlist")) {
      if (dropZoneForms.length > 5) {
        return;
      }
    }
    const sourceList = [...((source.droppableId === "formlist") ? formCopies[selectedProjectId] : dropZoneForms)];
    const destinationList = [...((destination.droppableId === "formlist") ? formCopies[selectedProjectId] : dropZoneForms)];


    if ((source.droppableId !== destination.droppableId) && (source.droppableId === "formlist")) {
      const grabbedForm = sourceList[source.index];
      destinationList.splice(destination.index, 0, grabbedForm);
      sourceList.splice(source.index, 1);
      const tempFormCopies = {...formCopies};
      tempFormCopies[selectedProjectId] = sourceList;
      setFormCopies(tempFormCopies);
      setDropZoneForms(destinationList);
      if(grabbedForm.configuration.language === "EN") {swapDropdownLanguage("EN");}
    } else if (source.droppableId !== destination.droppableId) {
      const grabbedForm = sourceList[source.index];
      const copyOfDestList = formCopies[grabbedForm.projectId];
      copyOfDestList.splice(destination.index, 0, grabbedForm);
      sourceList.splice(source.index, 1);
      formCopies[grabbedForm.projectId] = copyOfDestList;
      if(grabbedForm.projectId !== selectedProjectId) createNotification(`"${grabbedForm.name}" har flyttats till "${projects.find(p=>p.id === grabbedForm.projectId).projectName}".`, "success");
      setDropZoneForms(sourceList);
    } else if ((source.droppableId === "formlist")) {
      const temp = sourceList[source.index];
      sourceList[source.index] = sourceList[destination.index];
      sourceList[destination.index] = temp;
      const tempFormCopies = {...formCopies};
      tempFormCopies[selectedProjectId] = sourceList;
      setFormCopies(tempFormCopies);
    } else {
      const temp = sourceList[source.index];
      sourceList[source.index] = sourceList[destination.index];
      sourceList[destination.index] = temp;
      setDropZoneForms(sourceList);
    }
  };

  const createNotification = (text, variant, autoHideDuration, action) => {
    enqueueSnackbar(`${text}`, { variant, autoHideDuration, action });
  };


  return (
    <>
      {generatingForm ? (
        <div className="Preloader">
          <h2>{TRANSLATIONS.GENERATING_REPORT.SWEDISH}</h2>
          <Preloader size="large" />
        </div>
      ) : (
        <div className="Report-generator-container">
          <div className="Trait-title-text Report-min-width">
            <p> {TRANSLATIONS.CREATE_REPORT_HEADER.SWEDISH} </p>
          </div>
          <div className="Report-input Report-min-width">
            <div className="Left-side"></div>
            <div className="Right-side">
              <LanguageDropDown />
            </div>
          </div>
          <div className="Report-input Report-min-width">
            <div className="Left-side">
              <CustomerSelector />
            </div>
            <div className="Right-side">
              <TextInputField fieldName={TRANSLATIONS.NAME_OF_REPORT.SWEDISH} />
            </div>
          </div>
          <div className="Report-drag-n-drop Report-min-width">
            <ProjectList />
            <DragDropContext onDragEnd={onDragEnd}>
              <FormList />
              <div className="Arrow-icon">
                <i
                  style={({ color: "rgb(157, 157, 156)" }, { opacity: "40%" })}
                  className="small material-icons"
                >
                  arrow_forward
                </i>
              </div>
              <DropZoneList />
            </DragDropContext>
          </div>

          <div className="Report-submit Report-min-width">
            <div className="flex-row">
              <label style={{minWidth: "fit-content"}}>Slå samman rapporter</label>  
              <div className="align-checkbox">
                <label>
                  <input type="checkbox" id="report_type" className="filled-in" name="report_type" value="aggregated" />
                  <span></span>
                </label>
              </div>
              <button onClick={submitDropZone} className="Create-report-button btn btn-success" style={{margin: 0}}>{TRANSLATIONS.GENERATE_REPORT.SWEDISH}</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportComponentParent;
