import React, { useContext, useEffect } from "react";
import CustomerListItem from "../CustomerListItem/CustomerListItem";
import { FormContext } from "../../../Utils/contexts/FormContext";
import Preloader from "../../General/Preloader/Preloader";

const CustomerList = () => {

  const { customers, filter, isFetchingCustomers } = useContext(FormContext);

  useEffect(() => {
  }, [customers]);

  return (
    <div className="Card-list">
      <p className="header">Kund</p>
      <div className="body">
        <div className="Items Card-min-width">
          {isFetchingCustomers ? <Preloader/> : customers &&
            customers.filter(c => c.companyName.toLowerCase().includes(filter.toLowerCase()) || c.consultant.toLowerCase().includes(filter.toLowerCase())).sort((a, b) => a.companyName.localeCompare(b.companyName, "en", { sensitivity: "base" })).map((customer) => (
              <CustomerListItem
                customer={customer}
                key={customer.id}
              />
            ))}
        </div>
      </div>
    </div >
  );
};

export default CustomerList;
