import {
  useContext,
  useEffect,
  useState,
  createRef,
  useLayoutEffect,
} from "react";
import M from "materialize-css";
import "./FormInfoListItem.css";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import SendReminderEmail from "../../../Utils/functions/SendReminderEmail";
import { FormContext } from "../../../Utils/contexts/FormContext";
import DropDownMenu from "../../General/DropDownMenu/DropDownMenu";
import { OpenModalById } from "../../../Utils/functions/OpenModalById";
import { API, graphqlOperation } from "aws-amplify";
import {
  getParticipantScores,
  getParticipantAnswers,
} from "../../../graphql/queries";
import Preloader from "../../General/Preloader/Preloader";
import FormScoreTable from "../FormScoreTable/FormScoreTable";
import { useSnackbar } from "notistack";
import { Auth } from "aws-amplify";

const ParticipantInfoListItem = (props) => {
  const { fetchParticipants, selectedFormId, forms } = useContext(FormContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = createRef();
  const [participantAnswers, setParticipantAnswers] = useState({});
  const [peekedAnswers, setPeekedAnswers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasParticipantScore, setHasParticipantScore] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let elems = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });
    M.AutoInit();

    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, []);

  useLayoutEffect(() => {
    let elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, { enterDelay: 700 });
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    }
  }, [ref]);

  const menuObjects = [];
  ["SUBMITTED_AND_PARTIALLY_ANSWERED", "SUBMITTED"].includes(
    props.participant.status
  ) &&
    menuObjects.push({
      icon: "assessment",
      text: "Visa poäng",

      customOnClick: async (e) => {
        e.stopPropagation();
        OpenModalById(`getParticipantScoreModal_${props.participant.id}`);
        try {
          setIsLoading(true);
          const response = await API.graphql(
            graphqlOperation(getParticipantScores, {
              formId: selectedFormId,
              participantId: props.participant.id,
            })
          );

          const answers = JSON.parse(response.data.getParticipantScores);
          delete answers["name"];
          delete answers["project"];
          setParticipantAnswers(answers);
          setIsLoading(false);
          setHasParticipantScore(true);
        } catch (error) {
          setHasParticipantScore(false);
          setIsLoading(false);
        }
      },
    });
  ["SUBMITTED_AND_PARTIALLY_ANSWERED", "SUBMITTED"].includes(
    props.participant.status
  ) &&
    menuObjects.push({
      icon: "assessment",
      text: "Visa svar",

      customOnClick: async (e) => {
        e.stopPropagation();
        OpenModalById(`getParticipantAnswers_${props.participant.id}`);
        try {
          setIsLoading(true);
          const responseTest = await API.graphql(
            graphqlOperation(getParticipantAnswers, {
              participantId: props.participant.id,
            })
          );
          const answers = JSON.parse(responseTest.data.getParticipantAnswers);
          setPeekedAnswers(answers);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      },
    });
  !["SUBMITTED_AND_PARTIALLY_ANSWERED", "SUBMITTED", "CREATED"].includes(
    props.participant?.status
  ) &&
    forms.find((f) => f.id === selectedFormId)?.status.includes("OPEN") &&
    menuObjects.push({
      icon: "send",
      text: "Skicka påminnelse",
      customOnClick: (e) => {
        e.stopPropagation();
        setErrorMessage("");
        OpenModalById(`sendReminderModal_${props.participant.id}`);
      },
    });
  const getStatusTranslation = (participantStatus) => {
    switch (participantStatus) {
      case "CREATED":
        return "Skapad";
      case "SENT":
        return "Skickad";
      case "OPENED":
        return "Öppnad";
      case "STARTED":
        return "Påbörjad";
      case "SUBMITTED_AND_PARTIALLY_ANSWERED":
        return "Delvis besvarad";
      case "SUBMITTED":
        return "Färdig";
      default:
        return "Okänd";
    }
  };

  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, { variant });
  };

  return (
    <div className="Item-card Form-info" style={{ height: "100px" }}>
      <div className="body" style={{ cursor: "default" }}>
        <div className="Body-container-column">
          <div className="Body-container-row">
            <div className="Format-text">
              <p
                className={showTooltip ? "tooltipped" : ""}
                data-position="top"
                data-tooltip={`${props.participant.firstName} ${props.participant.lastName}`}
                ref={ref}
              >
                {props.participant.firstName} {props.participant.lastName}
              </p>
              <p
                className={
                  showTooltip ? "tooltipped Date-formating" : "Date-formating"
                }
                data-position="top"
                data-tooltip={props.participant.email}
                ref={ref}
              >
                {props.participant.email}
              </p>
            </div>
            {menuObjects.length > 0 && (
              <DropDownMenu
                ulId={`sendReminderDropDownMenu_${props.participant.id}`}
                menuObjects={menuObjects}
              />
            )}
          </div>

          <div className="Right-align">
            <p
              className="Status"
              style={
                props.participant.status === "CREATED"
                  ? { backgroundColor: "lightgrey" }
                  : props.participant.status === "SENT"
                  ? { backgroundColor: "orangered" }
                  : props.participant.status === "OPENED"
                  ? { backgroundColor: "orange" }
                  : props.participant.status === "STARTED"
                  ? { backgroundColor: "#ffde00" }
                  : props.participant.status ===
                    "SUBMITTED_AND_PARTIALLY_ANSWERED"
                  ? { backgroundColor: "greenyellow" }
                  : props.participant.status === "SUBMITTED"
                  ? { backgroundColor: "#98C742" }
                  : { backgroundColor: "rgb(219, 75, 26)" }
              }
            >
              {props.participant.status === undefined
                ? "NoStatus"
                : getStatusTranslation(props.participant.status)}
            </p>
          </div>
        </div>
      </div>

      <FinalFormModal
        modalHTMLIdentifier={`sendReminderModal_${props.participant.id}`}
        data-testid={`sendReminderModal_${props.participant.id}`}
        inputFields={[]}
        onSubmit={async () => {
          try {
            await SendReminderEmail(props.participant.id);
            createNotification("Påminnelse skickades.", "success");
            document
              .getElementById(`sendReminderModal_${props.participant.id}`)
              .M_Modal.close();
            await fetchParticipants(selectedFormId);
          } catch (error) {
            setErrorMessage("Kunde inte skicka");
            createNotification("Kunde inte skicka.", "error");
          }
        }}
        submitButtonName={"Ja"}
        cancelButtonName={"Nej"}
        formInfo={{
          header: `Vill du skicka påminnelsemail till ${props.participant.firstName} ${props.participant.lastName}?`,
        }}
        errorMessage={errorMessage}
        bodyText={
          props.participant.remindersSent.length !== 0 &&
          `Senast skickad påminnelse: ${
            props.participant.remindersSent[
              props.participant.remindersSent.length - 1
            ]
          }`
        }
      />

      <FinalFormModal
        modalHTMLIdentifier={`getParticipantAnswers_${props.participant.id}`}
        inputFields={[]}
        onSubmit={() => {}}
        submitButtonName={"hide"}
        cancelButtonName={"Stäng"}
        formInfo={{
          header: "Deltagarsvar",
        }}
        errorMessage={errorMessage}
        bodyText={
          <ul>
            {peekedAnswers.map((a, index) => {
              return (
                <li key={index}>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: "0px",
                      paddingBottom: "0px",
                    }}
                  >{`${a.orderNumber}. ${a.formulation}`}</p>{" "}
                  <p>{`(${a.score}) ${a.chosenAnswer}`}</p>
                </li>
              );
            })}
          </ul>
        }
      />
      <FinalFormModal
        modalHTMLIdentifier={`getParticipantScoreModal_${props.participant.id}`}
        inputFields={[]}
        onSubmit={() => {}}
        submitButtonName={"hide"}
        cancelButtonName={"Stäng"}
        formInfo={{
          header: "Deltagarpoäng",
        }}
        errorMessage={errorMessage}
        bodyText={
          isLoading ? (
            <Preloader size="small" />
          ) : hasParticipantScore ? (
            <FormScoreTable
              firstTitle={props.participant.firstName}
              secondTitle={props.participant.lastName}
              answerData={participantAnswers}
            />
          ) : (
            <p>Deltagaren har inte skickat in sitt formulär.</p>
          )
        }
      />
    </div>
  );
};

export default ParticipantInfoListItem;
