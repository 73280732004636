import React, { useContext } from "react";
import { ParticipantFormContext } from "../../../Utils/contexts/ParticipantFormContext";

const LanguageSwitch = () => {
  const { 
    setFormLanguage
  }  = useContext(ParticipantFormContext);

  const updateLanguage = (language) => {
    switch (language) {
      case "SE":
        setFormLanguage("SE");
        break;
      case "EN":
        setFormLanguage("EN");
        break;
      default:
        break;
    }
  };

  return (
    <div className="aaa" style={{textAlign: "right", paddingRight: "30vw", paddingTop: "2vh"}}>
      <a style={{cursor: "pointer"}} onClick={() => {
        updateLanguage("SE");
      }}>SE</a> | <a style={{cursor: "pointer"}} onClick={() => {
        updateLanguage("EN");
      }}>EN</a>
    </div>
  );
};

export default LanguageSwitch;
