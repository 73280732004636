import React, { useEffect, useState } from "react";
import M from "materialize-css";
import "./HelpView.css";

const HelpView = () => {

  useEffect(() => {
    const elems = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems, { accordion: true });
  });

  return (
    <div className="Help-page-container Min-width">
      <h2>Översikt</h2>

      <div className="Help-page-intro-text-container">
        <p>
          Under <b><i>Kunder</i></b> skapas nya kunder, kundprojekt och deltagare läggs till kundprojekt.
          <br />
            Under <b><i>Formulär</i></b> skapas formulär från kundprojekt. Här administreras också utskick till formulär
          <br />
            Under <b><i>Rapporter</i></b> genereras Powerpoint-rapporter utifrån besvarade formulär.
          <br />
          <br />
        </p>
      </div>

      <ul className="collapsible Collapsible-container">



        <li>
          <div className="collapsible-header">
            <i className="material-icons">control_point</i>
            Exempel på flöde
          </div>
          <div className="collapsible-body">

            <div style={{ textAlign: "left", width: "50vw" }}>
              <h4>Exempel på flöde</h4>
              <ol>
                <li>Klicka på <b>Kunder</b></li>
                <li>Skapa en kund, tex <i>Exempelkund</i></li>
                <li>Skapa ett projekt för kunden, tex <i>Innesälj</i></li>
                <li>Skapa deltagare för projektet</li>
                <li>Klicka på <b>Formulär</b></li>
                <li>Välj kund i listan, <i>Exempelkund</i> och sedan projekt, <i>Innesälj</i></li>
                <li>Klicka på <b><i>Lägg till nytt formulär</i></b>, gör dina val och klicka sedan på <b><i>Skapa</i></b></li>
                <li>Valde du <i>Automatisk inbjudan</i> kommer inbjudningsmailen att skickas ut automatiskt på valt datum.
                        Annars måste du skicka ut dom maunellt vilket du gör genom att klicka på <b><i>Skicka inbjudningar</i></b> på formuläret</li>
                <li>Nu kan formulären besvaras och när det är dags för att generera en rapport klickar du på <b>Rapporter</b></li>
                <li>Sök på din kund, <b><i>Exempelkund</i></b>, och välj ditt projekt och dra formuläret till landningsrutan</li>
                <li>Klicka på <b><i>Generera Rapport</i></b></li> 
              </ol>
            </div>

          </div>
        </li>



        <li>
          <div className="collapsible-header">
            <i className="material-icons">control_point</i>
            Hantera kunder
          </div>
          <div className="collapsible-body">

            <div style={{ textAlign: "left", width: "50vw" }}>
              <h4>Hantera kunder</h4>
              <ol>
                <li>
                  I vyn <b>Kunder</b> i kolumnen längst till vänster listas de kunder som den inloggade användaren är kopplad till.
                  Ovan finns en filtreringsmeny där du kan söka på kundnamn.
                  Längst ned, under kolumnen, finns en knapp för att lägga till en ny kund.
                </li>
                <li>
                  I nästa kolumn listas den valda kundens projekt. Längst ned, under kolumnen,
                  finns en knapp för att lägga till nytt projekt. Projektet läggs då till för den kunden som är markerad.
                </li>
                <li>
                  I nästa kolumn listas valt projekts deltagare. Klickar du på en deltagare visas deltagarens information.
                  Längst ned, under kolumnen, finns det två knappar.
                </li>
                <li>
                  Klickar du på <b><i>Lägg till deltagare</i></b> och fyller i uppgifterna läggs en ny deltagare till till projektet.
                </li>
                <li>
                  Klickar du på <b><i>Lägg till flera deltagare</i></b> öppnas en pop-up dit du kan dra en excel-fil med deltagarinformation.
                  I denna pop-up kan du ladda ned excel-mallen där du fyller i deltagarinformation.
                  <br />
                  <b>OBS!</b> Använder du denna metod kommer de tidigare deltagarna att försvinna från projektet.
                </li>
              </ol>
            </div>

          </div>
        </li>

        <li>
          <div className="collapsible-header">
            <i className="material-icons">control_point</i>
            Formulär
          </div>
          <div className="collapsible-body">
            <div style={{ textAlign: "left", width: "50vw" }}>
              <h4>Formulär</h4>
              <p style={{ marginTop: "0.5vh" }}>
                I vyn <b>Formulär</b> i kolumnen längst till vänster listas de kunder som den inloggade användaren är kopplad till.
                Ovan finns en filtreringsmeny där du kan söka på kundnamn.
                I nästa kolumn listas den valda kundens projekt.
                Väljer du ett projekt visas projektets formulär i den tredje kolumnen. Klickar du på ett formulär kan du se formulärets deltagare och dess status.
              </p>

              <p style={{ marginTop: "0.5vh" }}>
                På ett <b><i>formulär</i></b> kan du göra följande:
              </p>
              <div>
                <ul className="customUl">
                  <li>Visa samlad poäng</li>
                  <ul className="customUlNestled">
                    <li>Du får du se hur poängen har fördelats på de olika områdena</li>
                  </ul>
                  <li>Ta bort</li>
                  <li>Stäng formulär</li>
                  <ul className="customUlNestled">
                    <li>Formuläret kan då inte längre läsas eller besvaras av formulärsdeltagarna</li>
                  </ul>
                  <li>Skicka inbjudningar</li>
                </ul>
              </div>
              <p style={{ marginTop: "0.5vh" }}>
                På en <i><b>formulärsdeltagare</b></i> kan du:
              </p>

              <div>
                <ul className="customUl">
                  <li>Skicka påminnelse</li>
                  <li>Visa svar</li>
                  <li>Visa poäng</li>
                </ul>
              </div>
              <p style={{ marginTop: "0.5vh" }}>
                Längst ned, under kolumnen, finns en knapp   <b><i>lägg till formulär</i></b>.
                Klicka här för att skapa ett nytt formulär genom att välja följande:
              </p>

              <div>
                <ul className="customUl">
                  <li>Språk</li>
                  <ul className="customUlNestled">
                    <li>Det språk formuläret ska visas på</li>
                  </ul>
                  <li>Deadline</li>
                  <ul className="customUlNestled">
                    <li>Denna information kommer att visas för formulärsdeltagarna men formuläret kommer att fortsätta kunna besvaras
                      även om deadline är passarad. För att ett formulär inte längre ska kunna visas och besvaras måste formuläret stängas.</li>
                  </ul>
                  <li>Automatisk påminnelse</li>
                  <ul className="customUlNestled">
                    <li>Detta datum kommer ett påminnelsemail att skickas ut till alla formulärets deltagare som ännu inte lämnat in formuläret</li>
                  </ul>
                  <li>Automatisk inbjudan</li>
                  <ul className="customUlNestled">
                    <li>Om detta väljs kommer ett inbjudningsmail att skickas ut till alla formulärets deltagare på valt datum.</li>
                  </ul>
                  <li>Områden</li>
                  <ul className="customUlNestled">
                    <li>Här väljs vilka områden som ska omfattas i själva formuläret.</li>
                  </ul>
                </ul>
              </div>
              <p style={{ marginTop: "0.5vh" }}>
                När du skapar ett formulär kopieras projektdeltagare till det skapade formuläret. Du kommer inte att kunna
                ändra deltagarinformation på ett skapat formulär.
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="collapsible-header">
            <i className="material-icons">control_point</i>
            Generera rapport
          </div>
          <div className="collapsible-body">
            <div style={{ textAlign: "left", width: "50vw" }}>
              <h4>Generera rapport</h4>

              <p style={{ marginTop: "0.5vh" }}>
                Vyn <b>Rapporter</b> används för att generera Powerpointrapporter av upp till sex formulär.
              </p>

              <p style={{ marginTop: "0.5vh" }}>
                Du börjar med att välja en kund, sedan ett projekt. Då får du fram en lista av projektets formulär.
                Dra sedan formulär till den grå rutan för att välja dessa till rapporten.
                Du kan välja formulär från olika projekt så länge de tillhör samma kund.
                När du valt önskade formulär klickar du på <b><i>Generera Rapport</i></b> och rapporten kommer att laddas ned på din dator.                
              </p>

              <div>
                <p style={{ marginTop: "0.5vh" }}>
                  Du får också följande val:
                </p>
                
                <ul className="customUl">
                  <li>Språk</li>
                  <ul className="customUlNestled">
                    <li>Önskat språk i rapporten</li>
                  </ul>
                  <li>Rapportnamn</li>
                  <li>Slå samman rapporter</li>
                  <ul className="customUlNestled">
                    <li>Med detta alternativ valt får du ut snittpoängen för de formulär du valt istället för att de listas separat</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="collapsible-header">
            <i className="material-icons">control_point</i>
            Webbläsare som stöds
          </div>
          <div className="collapsible-body">
            <div style={{ textAlign: "left", width: "50vw" }}>
              <h4>Webbläsare som stöds</h4>

              <p style={{ marginTop: "0.5vh" }}>
                Nedan visas vilka webbläsare som stöds och rekommenderas att användas för applikationen (Sales Health Check) och för deltagarformulären.
              </p>
              <div>
                <p style={{ marginTop: "0.5vh" }}>
                  <b>Sales Health Check:</b>
                </p>
                <ul className="customUl">
                  <li>Desktop/Laptop:</li>
                  <ul className="customUlNestled">
                    <li>Google Chrome</li>
                  </ul>
                </ul>
              </div>
              <div>
                <p style={{ marginTop: "0.5vh" }}>
                  <b>Deltagarformulär:</b>
                </p>
                <ul className="customUl">
                  <li>Desktop/Laptop:</li>
                  <ul className="customUlNestled">
                    <li>Google Chrome</li>
                    <li>Safari</li>
                    <li>Mozilla Firefox</li>
                    <li>Microsoft Edge</li>
                  </ul>
                  <li>Mobilenheter (Android/iOS):</li>
                  <ul className="customUlNestled">
                    <li>Google Chrome</li>
                    <li>Safari</li>
                    <li>Mozilla Firefox</li>
                    <li>Microsoft Edge</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>

    </div>
  );
};

export default HelpView;
