import React from "react";

const AddListItem = ({ dataTestId, icon, text, onClick }) => {
  return (
    <p className="button" data-testid={dataTestId} onClick={ onClick && onClick}>
      <i className="material-icons">{icon}</i>{text}
    </p>
  );
};

export default AddListItem;
