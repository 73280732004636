import LayoutListPage from "../LayoutListPage/LayoutListPage";
import List from "../List/List";
import AddUserModal from "./components/AddUserModal";
import DeleteUserModal from "./components/DeleteUserModal";
import PromoteUserModal from "./components/PromoteUserModal";
import DemoteUserModal from "./components/DemoteUserModal";
import MoveCustomerModal from "./components/MoveCustomerModal";

import { mapCustomersToListItems, mapUsersToListItems } from "./helpers";
import "./Users.css";

export const Users = ({
  isFetchingCustomers,
  isFetchingUsers,
  users,
  onUserClick,
  menuObjects,
  customers,
  customerMenuObjects,
  selectedUser,
  isLoaded,
  onCustomerClick,
  selectedCustomer,
  allCustomersMode,
  switchAllCustomersMode,
  currentUser
}) => (
  <LayoutListPage>
    <div className="Main-body" style={{display: "flex", justifyContent: "center"}}>
      <div className="Item-list-container">
        <div className="Item-list-container">
          <>
            <List
              isFetching={isFetchingUsers}
              listName="Användare"
              listItems={mapUsersToListItems(users, currentUser)}
              selectedUser={selectedUser}
              onListItemClick={onUserClick}
              menuObjects={menuObjects}
              listButtons={[
                {
                  modalProperties: {
                    className: "Users-add-user modal-trigger",
                    dataTestId: "add-user-modal-trigger",
                  },
                  icon: "add",
                  text: "Lägg till ny användare",
                },
              ]}
            />
            <List
              isFetching={isFetchingCustomers}
              listName="Kunder"
              listItems={mapCustomersToListItems(
                customers.sort((a, b) =>
                  a.companyName.localeCompare(b.companyName, "en", {
                    sensitivity: "base",
                  })
                )
              )}
              onListItemClick={onCustomerClick}
              menuObjects={customerMenuObjects}
              listButtons={[
                {
                  toggleProperties: {
                    className: "Users-show-customers toggle",
                    onIcon: "remove_red_eye",
                    onText: "Visa bara vald användares kunder",
                    offIcon: "remove_red_eye",
                    offText: "Visa alla kunder",
                    toggleState: allCustomersMode,
                    handleClick: switchAllCustomersMode,
                    dataTestId: "show-customer-toggle",
                  },
                },
              ]}
            />
          </>
        </div>
      </div>
    </div>
    <AddUserModal />
    <DeleteUserModal user={selectedUser} />
    <PromoteUserModal user={selectedUser} />
    <MoveCustomerModal
      user={selectedUser}
      users={users}
      customer={selectedCustomer}
    />
    <DemoteUserModal user={selectedUser} />
  </LayoutListPage>
);
