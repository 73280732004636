import React from "react";
import "./LayoutListPage.css";

const LayoutListPage = (props) => (
  <div className="Top-margin">
    <div className="Customer-list-container Min-width">{props.children}</div>
  </div>
);

export default LayoutListPage;
