import React, { useEffect } from "react";
import "../../Utils/CSS/views.css";
import FormComponentParent from "../../components/Forms/FormComponentParent/FormComponentParent";
import { FormProvider } from "../../Utils/contexts/FormContext";

const FormView = () => {

  useEffect(() => {
  }, []);

  return (
    <div className="Top-margin">
      <div className="Customer-list-container">
        <FormProvider>
          <FormComponentParent />
        </FormProvider>
      </div>
    </div>
  );
};

export default FormView;
