import React from "react";
import "./TraitTitle.css";

const TraitTitle = ({ title }) => {
  return (
    <div className="Trait-title">
      <h1 className="Trait-title-text">{title}</h1>
    </div>
  );
};

export default TraitTitle;
