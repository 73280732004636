import React from "react";
import "../../Utils/CSS/views.css";
import EconomyComponentParent from "../../components/Economy/EconomyComponentParent/EconomyComponentParent";
import { EconomyProvider } from "../../Utils/contexts/EconomyContext";

const EconomyView = () => {

  return (
    <div className="Top-margin">
      <div className="Customer-list-container Economy-min-width">
        <EconomyProvider>
          <EconomyComponentParent />
        </EconomyProvider>
      </div>
    </div>
  );
};

export default EconomyView;
