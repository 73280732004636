import React from "react";
import "./SubmitButton.css";

const SubmitButton = (props) => {

  return (
    <div className={"Button-margin "+props.direction}>
      <button
        className={`btn btn-success ${props.buttonTrigger}`}
        type={props.buttonType}
        style={{display: (props.buttonName === "hide") && "none"}}
        disabled={props.disabled}
      >
        {props.buttonName}
      </button>
    </div>
  );
};

export default SubmitButton;
