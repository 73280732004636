export const LANGUAGE = {
  SWEDISH: "SE",
  ENGLISH: "EN"
};
export const getLanguage = (language) => {
  switch(language){
    case 0: 
      return "Svenska";
    case 1: 
      return  "English";
    default:
      return "Svenska";   
  }
};

export const getIndexFromLanguageEnum = (language) => {
  switch(language){
    case "SE": 
      return 0;
    case "EN": 
      return  1;
    default:
      return 0;   
  }
};