/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const changeOrderNumber = /* GraphQL */ `
  mutation ChangeOrderNumber($id: ID!, $newOrderNumber: Int!) {
    changeOrderNumber(id: $id, newOrderNumber: $newOrderNumber)
  }
`;
export const setFormStatus = /* GraphQL */ `
  mutation SetFormStatus($id: ID!, $status: FormStatus!) {
    setFormStatus(id: $id, status: $status) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteForm = /* GraphQL */ `
  mutation DeleteForm($id: ID!) {
    deleteForm(id: $id)
  }
`;
export const createTrait = /* GraphQL */ `
  mutation CreateTrait($names: [StringTranslationInput!]!, $idealScenes: [StringTranslationInput!]!) {
    createTrait(names: $names, idealScenes: $idealScenes)
  }
`;
export const updateTrait = /* GraphQL */ `
  mutation UpdateTrait($id: ID!, $names: [StringTranslationInput!]!, $idealScenes: [StringTranslationInput!]!) {
    updateTrait(id: $id, names: $names, idealScenes: $idealScenes)
  }
`;
export const sendReminderEmail = /* GraphQL */ `
  mutation SendReminderEmail($participantId: ID!) {
    sendReminderEmail(participantId: $participantId) {
      id
      formId
      email
      firstName
      lastName
      title
      status
      selectedAnswers {
        question
        answer
      }
      remindersSent
      createdAt
      updatedAt
    }
  }
`;
export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $name: String!
    $configuration: ConfigurationInput!
    $projectId: ID!
  ) {
    createForm(
      name: $name
      configuration: $configuration
      projectId: $projectId
    )
  }
`;
export const updateCustomer2 = /* GraphQL */ `
  mutation UpdateCustomer2($id: ID!, $companyName: String!) {
    updateCustomer2(id: $id, companyName: $companyName) {
      id
      consultant
      companyName
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer2 = /* GraphQL */ `
  mutation CreateCustomer2($companyName: String!) {
    createCustomer2(companyName: $companyName) {
      id
      consultant
      companyName
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer2 = /* GraphQL */ `
  mutation DeleteCustomer2($id: ID!) {
    deleteCustomer2(id: $id)
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject($customerId: ID!, $projectName: String!) {
    createProject(customerId: $customerId, projectName: $projectName) {
      id
      projectName
      customerId
      participants {
        email
        firstName
        lastName
        title
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $id: ID!
    $projectName: String!
    $participants: [ParticipantInput!]!
  ) {
    updateProject(
      id: $id
      projectName: $projectName
      participants: $participants
    ) {
      id
      projectName
      customerId
      participants {
        email
        firstName
        lastName
        title
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id) 
  }
`;
export const updateOrderNumberForQuestion = /* GraphQL */ `
  mutation UpdateOrderNumberForQuestion($id: ID!, $orderNumber: Int!) {
    updateOrderNumberForQuestion(id: $id, orderNumber: $orderNumber) {
      id
      trait {
        id
        symbol
        idealScene {
          language
          value
        }
        name {
          language
          value
        }
        questions {
          items {
            id
            trait {
              id
              symbol
              createdAt
              updatedAt
            }
            translations {
              language
              formulation
            }
            scores {
              answer1
              answer2
              answer3
              answer4
              answer5
            }
            answerSequence {
              answer1
              answer2
              answer3
              answer4
              answer5
            }
            orderNumber
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      translations {
        language
        formulation
        answerOptions {
          answer1
          answer2
          answer3
          answer4
          answer5
        }
      }
      scores {
        answer1
        answer2
        answer3
        answer4
        answer5
      }
      answerSequence {
        answer1
        answer2
        answer3
        answer4
        answer5
      }
      orderNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $id: ID!
    $trait: ID!
    $translations: [QuestionTranslationInput!]!
    $scores: ScoresInput!
    $answerSequence: SequenceInput!
  ) {
    updateQuestion(
      id: $id
      trait: $trait
      translations: $translations
      scores: $scores
      answerSequence: $answerSequence
    ) {
      id
      trait {
        id
        symbol
        idealScene {
          language
          value
        }
        name {
          language
          value
        }
        questions {
          items {
            id
            trait {
              id
              symbol
              createdAt
              updatedAt
            }
            translations {
              language
              formulation
            }
            scores {
              answer1
              answer2
              answer3
              answer4
              answer5
            }
            answerSequence {
              answer1
              answer2
              answer3
              answer4
              answer5
            }
            orderNumber
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      translations {
        language
        formulation
        answerOptions {
          answer1
          answer2
          answer3
          answer4
          answer5
        }
      }
      scores {
        answer1
        answer2
        answer3
        answer4
        answer5
      }
      answerSequence {
        answer1
        answer2
        answer3
        answer4
        answer5
      }
      orderNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion($id: ID!) {
    deleteQuestion(id: $id) {
      id
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $trait: ID!
    $translations: [QuestionTranslationInput!]!
    $scores: ScoresInput!
    $answerSequence: SequenceInput!
  ) {
    createQuestion(
      trait: $trait
      translations: $translations
      scores: $scores
      answerSequence: $answerSequence
    ) {
      id
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($email: AWSEmail!) {
    createUser(email: $email) {
      username
      isSuperUser
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($username: AWSEmail!) {
    deleteUser(username: $username)
  }
`;
export const makeUserSuperUser = /* GraphQL */ `
  mutation MakeUserSuperUser($username: AWSEmail!) {
    makeUserSuperUser(username: $username)
  }
`;
export const removeUserFromSuperUser = /* GraphQL */ `
  mutation RemoveUserFromSuperUser($username: AWSEmail!) {
    removeUserFromSuperUser(username: $username)
  }
`;
export const changeCustomerConsultant = /* GraphQL */ `
  mutation ChangeCustomerConsultant($id: String!, $username: AWSEmail!) {
    changeCustomerConsultant(id: $id, username: $username)
  }
`;
export const submitForm = /* GraphQL */ `
  mutation SubmitForm($slug: String!) {
    submitForm(slug: $slug)
  }
`;
export const saveParticipantAnswer = /* GraphQL */ `
  mutation SaveParticipantAnswer(
    $questionId: ID!
    $answer: Int!
    $participantId: ID!
  ) {
    saveParticipantAnswer(
      questionId: $questionId
      answer: $answer
      participantId: $participantId
    )
  }
`;
export const acceptGDPRTerms = /* GraphQL */ `
  mutation AcceptGDPRTerms($participantId: ID!){
    acceptGDPRTerms(participantId: $participantId)
  }
`;
