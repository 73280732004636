import React, { useEffect, useContext } from "react";
import CustomerList from "../CustomerList/CustomerList";
import ProjectList from "../ProjectList/ProjectList";
import FormList from "../FormList/FormList";
import FormInfoList from "../FormInfoList/FormInfoList";
import "../../../Utils/CSS/cards.css";
import { FormContext } from "../../../Utils/contexts/FormContext";
import FilterBanner from "../../General/FilterBanner/FilterBanner";
import { SuperUserContext } from "../../../Utils/contexts/SuperUserContext";

const FormComponentParent = () => {
  const {
    fetchUserCustomers,
    isLoaded,
    setIsLoaded,
    showOnlyMyCustomers,
    toggleShowOnlyMyCustomers,
    setSelectedParticipantId,
    setSelectedProjectId,
    setProjects,
    setSelectedCustomerId,
    setFilter,
    setSelectedFormId,
    setForms,
    setParticipants,
    customers,
    selectedCustomerId,
  } = useContext(FormContext);
  const { isSuperUser, currentUser } = useContext(SuperUserContext);
  const handleFilterChange = (e) => {
    const customer = customers.find((c) => c.id === selectedCustomerId);
    if (
      customer?.companyName.toLowerCase().includes(e.target.value.toLowerCase())
    ) {
      setFilter(e.target.value.toLowerCase());
      return;
    }
    setSelectedParticipantId(-1);
    setSelectedProjectId(-1);
    setProjects([]);
    setSelectedCustomerId(-1);
    setForms([]);
    setSelectedFormId(-1);
    setParticipants([]);
    setFilter(e.target.value.toLowerCase());
  };

  useEffect(() => {
    const fetcher = async () =>
      Promise.all([fetchUserCustomers(currentUser)]).then(() => {
        setIsLoaded(true);
      });
    if (currentUser !== "") {
      fetcher();
    }
  }, [currentUser]);

  return (
    <div className="Main-body">
      <div className="Item-list-container">
        {isLoaded && (
          <div>
            <FilterBanner
              handleFilterChange={handleFilterChange}
              showOnlyMyCustomers={showOnlyMyCustomers}
              toggleShowOnlyMyCustomers={toggleShowOnlyMyCustomers}
              isSuperUser={isSuperUser}
            />
            <div className="Item-list-container">
              {/* condition customer inte null eller undefined */}
              {/* ska kunna visa komponent samt kunna lägga till kund när inga kunder finns från början. */}
              <CustomerList />
              <ProjectList />
              <FormList />
              <FormInfoList />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormComponentParent;
