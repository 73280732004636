import { API, graphqlOperation } from "aws-amplify";
import { participantValidator } from "./inputFieldValidation.js";
import { updateProject } from "../../graphql/mutations";


const AddParticipant = async (project, participant) => {

  const participantValidation = participantValidator.validate(participant);
  if(!(participantValidation.error === undefined)){
    throw new Error("Bad request");
  }
  const participantsCopy = JSON.parse(JSON.stringify(project.participants));
  participantsCopy.push(participant);

  const updatedProject = await API.graphql(
    graphqlOperation(updateProject, {
      id: project.id,
      projectName: project.projectName,
      participants: participantsCopy
    })
  );
  return updatedProject;
  
};

export default AddParticipant;
