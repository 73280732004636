import { useContext } from "react";
import { ReportContext } from "../../../Utils/contexts/ReportContext";
import { Draggable, Droppable } from "react-beautiful-dnd";
import FormListItem from "../FormListItem/FormListItem";
import "./DropZoneList.css";

const DropZoneList = () => {
  const { minimumFormsError, dropZoneForms } = useContext(ReportContext);

  return (
    <div className="Dropzone-list-container">
      <div className="blank-space" />
      <Droppable droppableId="dropzone" type="form">
        {(provided, snapshot) => (
          <div
            className={
              "Report-drop-zone " + (dropZoneForms.length === 0 && "Flex")
            }
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver ? "#dedede" : "#f6f6f6",
            }}
            {...provided.droppableProps}
          >
            {dropZoneForms.map((form, index) => {
              return (
                <Draggable
                  draggableId={`draggable-${form.id}`}
                  key={form.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <FormListItem
                        isDragging={snapshot.isDragging}
                        form={form}
                        key={`dropzone_${form.id}`}
                      />{" "}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            {snapshot.isDraggingOver
              ? dropZoneForms.length === 0 && (
                  <div>
                    <p
                      style={{ fontWeight: "lighter", fontSize: "3rem" }}
                    >{`${dropZoneForms.length}/6`}</p>
                  </div>
                )
              : dropZoneForms.length === 0 && (
                  <div className="Dropzone-inner-box">
                    <div>
                      <i
                        style={{ color: "grey" }}
                        className="material-icons medium"
                      >
                        add
                      </i>
                      <p>Välj formulär genom att dra in dem i rutan</p>
                    </div>
                  </div>
                )}
            {dropZoneForms.length > 0 && (
              <div>
                <p
                  style={{ fontWeight: "lighter", fontSize: "3rem" }}
                >{`${dropZoneForms.length}/6`}</p>
              </div>
            )}
            {minimumFormsError && dropZoneForms.length === 0 && (
              <p style={{ color: "rgb(219, 75, 26)" }}>
                Dra formulär hit för att generera rapport
              </p>
            )}
          </div>
        )}
      </Droppable>
    </div>
  );
};
export default DropZoneList;
