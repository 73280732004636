import React, { useContext, useEffect } from "react";
import { ReportContext } from "../../../Utils/contexts/ReportContext";
import ProjectListItem from "../ProjectListItem/ProjectListItem";
import { TRANSLATIONS } from "../Helpers/Translations";
import "./ProjectList.css";
import API, { graphqlOperation } from "@aws-amplify/api";
import { getCustomerProjects } from "../../../graphql/queries";
import Preloader from "../../General/Preloader/Preloader";

const ProjectList = () => {
  const {
    projects,
    isFetchingProjects,
    selectedCustomerId,
    selectedProjectId,
    setSelectedProjectId
  } = useContext(ReportContext);


  return (
    <div className="Project-list-container">
      <div>
        <p>{TRANSLATIONS.PROJECT.SWEDISH}</p>
      </div>
      <div className="Report-project">
        {!isFetchingProjects ? (
          <div>
            {projects.map((project, index) => {
              return (
                <ProjectListItem
                  project={project}
                  setSelectedProjectId={setSelectedProjectId}
                  selectedProjectId={selectedProjectId}
                  key={project.id}
                />
              );
            })}
          </div>
        ) : <Preloader/>}
      </div>
    </div> 
  );
};

export default ProjectList;
