import { API, graphqlOperation } from "aws-amplify";
import { updateProject } from "../../graphql/mutations";
import { validator } from "./inputFieldValidation.js";

const UpdateProjectName = async (project, projectName) => {
  const projectNameValidation = validator.validate({ inputString: projectName });
  if (!(projectNameValidation.error === undefined)) {
    throw new Error("Bad request");
  }

  try {
    const input = {
      id: project.id,
      projectName: projectName,
      participants: project.participants
    };

    const updatedProject = await API.graphql(
      graphqlOperation(updateProject, input)
    );
    return updatedProject;
  } catch (error) {
    throw new Error("InternalServerError");
  }
};

export default UpdateProjectName;
