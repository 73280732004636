import React, { useContext, useState } from "react";
import ListFormModal from "../../General/ListFormModal/ListFormModal";
import { mapUsersNameToName } from "../helpers";
import { UsersContext } from "../../../Utils/contexts/UsersContext";
import ChangeCustomerConsultant from "../../../Utils/functions/ChangeCustomerConsultant";
import ApiError from "../../../Utils/error/ApiError";
import { useSnackbar } from "notistack";

const MoveCustomerModal = ({ user, indentifyer = "MoveCustomerModal" }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {selectedCustomer, fetchUserCustomers, users} = useContext(UsersContext);
  const [newUser, setNewUser] = useState(user);

  const closeModal = () => {
    document.getElementById(indentifyer).M_Modal.close();
  };

  const submitChangeConsultantForm = async () => {
    if(newUser){
      try {
        await ChangeCustomerConsultant(selectedCustomer.customerId, newUser);
        createNotification(`"${selectedCustomer.name}" flyttades till "${newUser}".`,"success");
        await fetchUserCustomers(user);
      } catch (err) {
        createNotification(`Kunde inte flytta "${selectedCustomer.name}" till "${newUser}".`,"error");
      }
    }
    closeModal();
  };

  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, {variant});
  };

  return (
    <ListFormModal
      modalHTMLIdentifier={indentifyer}
      inputFields={mapUsersNameToName(users)}
      initialValues={user}
      onListSelect={(listItem) => {setNewUser(listItem.name);}}
      onClick={(e) => e.stopPropagation()}
      onSubmit={() => submitChangeConsultantForm(newUser)}
      submitButtonName={"Välj"}
      cancelButtonName={"Avbryt"}
      formInfo={{
        header: `Välj en ny användare för ${selectedCustomer?.name}`,
      }}
      errorMessage=""
      styling={{width:"100%"}}
    />
  );
};

export default MoveCustomerModal;
