import React, {
  createRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Dropdown } from "materialize-css";
import { CustomerContext } from "../../../Utils/contexts/CustomerContext";
import DropDownMenu from "../../General/DropDownMenu/DropDownMenu";
import { SuperUserContext } from "../../../Utils/contexts/SuperUserContext";


const CustomerListItem = ({customer}) => {
  const {
    setSelectedCustomerId,
    selectedCustomerId,
    setSelectedProjectId,
    setSelectedParticipantId,
    fetchProjects,
    isFetchingProjects
  } = useContext(CustomerContext);
  const {isSuperUser} = useContext(SuperUserContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = createRef();

  useEffect(() => {
    let elems = document.querySelectorAll(".dropdown-trigger");
    Dropdown.init(elems, { inDuration: 300, outDuration: 225 });
  }, [isFetchingProjects]);

  useLayoutEffect(() => {
    var elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, {enterDelay: 700});  
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    }
  }, [ref]);

  const selectCustomer = async () => {
    setSelectedProjectId(-1);
    setSelectedParticipantId(-1);
    setSelectedCustomerId(customer.id);
    fetchProjects(customer.id);
  };
  
  const openUpdateCustomerModal = async (e) => {
    e.stopPropagation();
    setSelectedCustomerId(customer.id);
    document
      .getElementById("UpdateCustomerNameModal")
      .M_Modal.open();
    document.getElementById("Ändra Kundnamn").focus();
  };

  const openDeleteCustomerModal = (e) => {
    e.stopPropagation();
    setSelectedCustomerId(customer.id);
    document.getElementById("removeCustomerModal").M_Modal.open();
  };


  

  const menuObjects = [
    {
      icon: "edit",
      text: "Ändra",
      customOnClick: openUpdateCustomerModal
    }
  ];
  if(isSuperUser) {
    menuObjects.push({
      icon: "delete", 
      text: "Ta bort",
      customOnClick: openDeleteCustomerModal
    });
  }
  return (
    <div className="Item-card" onClick={selectCustomer} id={`customer-list-${customer.id}`}>
      <div className={(customer.id === selectedCustomerId) ? "body-green" : "body"}>
        <div className="Body-container">
          <p
            className={showTooltip ? "tooltipped" : ""}
            data-position="top"
            data-tooltip={customer.companyName}
            ref={ref}
          >
            {customer.companyName}
          </p>
          {!isFetchingProjects && <DropDownMenu
            ulId={`customer${customer.id}`}
            menuObjects={menuObjects}
            isFetching={isFetchingProjects}
          />}
        </div>
      </div>
    </div>
  );
};

export default CustomerListItem;
