import React from "react";
import "./Preloader.css";

const Preloader = (props) => {
  return (
    <div style={{width:"100%", display: "flex", justifyContent: "center"}}>
      <div className={`preloader-wrapper ${props.size} active spacing`}>
        <div className="spinner-layer spinner-theme-color-only">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          {/* <div className="circle-clipper right">
          <div className="circle"></div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Preloader;
