import { useContext } from "react";
import { TRANSLATIONS } from "../Helpers/Translations";
import { ReportContext } from "../../../Utils/contexts/ReportContext";

const TextInputField = (props) => {
  const { setNameOfReport, nameOfReport, invalidName } =
    useContext(ReportContext);
  return (
    <div className="">
      <div style={{ display: "flex" }}>
        <label style={{ margin: "0px" }} htmlFor={props.fieldName}>
          {props.fieldName}
        </label>
      </div>
      <input
        data-testid={props.fieldName}
        onChange={(e) => {
          setNameOfReport(e.target.value);
        }}
        id={props.fieldName}
        type="text"
        defaultValue={nameOfReport}
        autoComplete="off"
        placeholder=""
      />
      {invalidName ? (
        <div>
          <p style={{ color: "rgb(219, 75, 26)", textAlign: "left" }}>
            {TRANSLATIONS.BAD_INPUT.SWEDISH}
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default TextInputField;
