import { useContext, useEffect } from "react";
import { ReportContext } from "../../../Utils/contexts/ReportContext";
import { Draggable, Droppable } from "react-beautiful-dnd";
import FormListItem from "../FormListItem/FormListItem";
import { TRANSLATIONS } from "../Helpers/Translations";
import Preloader from "../../General/Preloader/Preloader";
import "./FormList.css";

const FormList = () => {
  const { isFetchingForms, selectedProjectId, formCopies } = 
  useContext(
    ReportContext
  );

  useEffect(() => {
  }, [formCopies]);

  


  return (
    <div className="Form-list-container">
      <div>
        <p>{TRANSLATIONS.FORM.SWEDISH}</p>
      </div>
      <Droppable droppableId="formlist" type="form">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className="Report-form-dnd"
            style={{
              backgroundColor: snapshot.isDraggingOver
                ? "#dedede"
                : "#f6f6f6",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            {...provided.droppableProps}
          >
            {isFetchingForms ? <Preloader /> : 
              <div>
                {(formCopies[selectedProjectId] !== undefined) && formCopies[selectedProjectId].map((form, index) => {
                  return (
                    <Draggable
                      draggableId={`draggable-${form.id}`}
                      key={form.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FormListItem
                            isDragging={snapshot.isDragging}
                            form={form}
                            key={index}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div> }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};
export default FormList;
