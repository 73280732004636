import React, { useContext } from "react";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import { UsersContext } from "../../../Utils/contexts/UsersContext";
import { deleteUser } from "../../../Utils/functions/api/usersApi";
import ApiError from "../../../Utils/error/ApiError";
import { filterUsers } from "../helpers";
import { useSnackbar } from "notistack";

const DeleteUserModal = ({ user, indentifyer = "DeleteUserModal" }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { fetchUsers } = useContext(UsersContext);

  const closeModal = () => {
    document.getElementById(indentifyer)?.M_Modal.close();
  };

  const submitDeleteUserForm = async (user) => {
    try {
      await deleteUser(user);
      createNotification(`"${user}" har tagits bort.`,"success");
      await fetchUsers();
      closeModal();
    } catch (err) {
      createNotification(`"${user}" kunde inte tas bort. Flytta alla kunder till en annan konsult först.`,"error");
      document.getElementById(indentifyer)?.M_Modal.close();
    }
  };

  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, {variant});
  };

  return (
    <FinalFormModal
      modalHTMLIdentifier={indentifyer}
      inputFields={[]}
      initialValues={[]}
      onClick={(e) => e.stopPropagation()}
      onSubmit={() => submitDeleteUserForm(user)}
      submitButtonName={"Ja"}
      cancelButtonName={"Nej"}
      formInfo={{
        header: `Vill du ta bort ${user}?`,
      }}
      errorMessage=""
    />
  );
};

export default DeleteUserModal;
