import React, { useState } from "react";
import SubmittButton from "../SubmitButton/SubmitButton";

const DatePick = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(new Date().toJSON().split("T")[0]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    props.submit(startDate, endDate);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} style={{display: "flex", justifyContent: "center"}}>
        <div className="Items" style={{ display: "flex", flexDirection: "column", minWidth: "450px", width: "fit-content", backgroundColor: "white"}} >
          <div  style={{display: "flex", justifyContent: "center"}}>
            <div style={{margin: "5%"}}>
              <label className="control-label">
                Startdatum
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </label>
            </div>
            <div style={{margin: "5%"}}>
              <label className="control-label">
                Slutdatum
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={startDate}
                  max={new Date()}
                />
              </label>
            </div>
          </div>
          <div style={{margin: "0 5% 2% 0"}}>
            <SubmittButton
              direction="right"
              buttonName={"Hämta"}
              buttonType={"submit"}
              disabled={!startDate}
              buttonTrigger=""
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default DatePick;
