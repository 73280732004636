import React, { useContext, createRef, useLayoutEffect, useState } from "react";
import { FormContext } from "../../../Utils/contexts/FormContext";
import M from "materialize-css";

const CustomerListItem = (props) => {

  const {
    setForms,
    setProjects,
    selectedCustomerId, 
    setSelectedCustomerId,
    setSelectedProjectId,
    isFetchingForms,
    setSelectedParticipantId,
    fetchProjects,
    setParticipants
  } = useContext(FormContext);

  const [showTooltip, setShowTooltip] = useState(false);
  const ref = createRef();

  useLayoutEffect(() => {
    var elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, {enterDelay: 700});  
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    }
  }, [ref]);

  const foldCustomer = () => {
    setSelectedProjectId(-1);
    setSelectedParticipantId(-1);
  };

  return (
    <div
      className="Item-card"
      data-testid="testItem"
      onClick={async () => { 
        if(isFetchingForms) return;
        setParticipants([]);
        setSelectedCustomerId(props.customer.id);
        foldCustomer();
        setForms([]);
        setProjects([]);
        await fetchProjects(props.customer.id);
      }}
    >

      {/* classnames, ett lib som är användbart. */}
      <div data-testid={props.customer.companyName} className={(props.customer.id === selectedCustomerId) ? "body-green" : "body"}>
        <div className="Body-container">
          <p className={showTooltip ? "tooltipped" : ""} data-position="top" data-tooltip={props.customer.companyName} ref={ref}>{props.customer.companyName}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomerListItem;
