import { createRef, useLayoutEffect, useState, useContext } from "react";
import "./ProjectListItem.css";
import M from "materialize-css";
import { ReportContext } from "../../../Utils/contexts/ReportContext";


const ProjectListItem = ({project}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = createRef();
  
  useLayoutEffect(() => {
    var elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, {enterDelay: 700});  
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    } 
  }, [ref]);
  const {
    setSelectedProjectId,
    selectedProjectId,
    formCopies,
    fetchForms,
    setIsFetchingForms
  } = useContext(ReportContext);
  return (
    
    <div
      style={{height: "55px"}}
      className="Item-card"
      onClick={async (e) => {
        e.stopPropagation();
        setSelectedProjectId(project.id);
        setIsFetchingForms(true);
        if(formCopies[project.id] === undefined){
          await fetchForms(project.id);
        }
        setIsFetchingForms(false);
      }}
    >

      <div className={(project.id === selectedProjectId) ? "body-green" : "body"}>
        <div className="Body-container">
          <p className={showTooltip ? "tooltipped" : ""} data-position="top" data-tooltip={project.projectName} ref={ref}>{project.projectName}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectListItem;
