import React, { useContext, useEffect, useState } from "react";
import ParticipantListItem from "../ParticipantListItem/ParticipantListItem";
import * as XLSX from "xlsx";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import {
  validator,
  isValidEmail,
} from "../../../Utils/functions/inputFieldValidation";
import AddParticipant from "../../../Utils/functions/AddParticipant";
import AddParticipants from "../../../Utils/functions/AddParticipants";
import DropZoneModal from "../../General/DropZoneModal/DropZoneModal";
import { CustomerContext } from "../../../Utils/contexts/CustomerContext";
import AddListItem from "../../General/AddListItem/AddListItem";
import { useSnackbar } from "notistack";
import UpdateParticipantList from "../../../Utils/functions/UpdateParticipantList";

const ParticipantList = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [fileError, setFileError] = useState("");
  
  const [newParticipantId, setNewParticipantId] = useState("");
  useEffect(() => {
    document.getElementById(`participant-list-${newParticipantId}`)?.scrollIntoView();
  }, [newParticipantId]);

  const {
    projects,
    fetchProjects,
    selectedCustomerId,
    selectedProjectId,
    selectedParticipantId,
    setSelectedParticipantId,
  } = useContext(CustomerContext);
  useEffect(() => {
    M.AutoInit();

    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, [projects]);
  const project = projects.find(p => p.id === selectedProjectId);

  const openAddParticipantModal = () => {
    document.getElementById("addParticipantModal").M_Modal.open();
    document.querySelectorAll("[id=addParticipantEmail]")[0].focus();
  };

  const validateParticipantInput = (values) => {
    const formErrors = {};
    if (isValidEmail.validate({ email: values.Email }).error !== undefined) {
      formErrors["Email"] = "Inkorrekt email-format";
    }
    if (validator.validate({ inputString: values.Förnamn }).error !== undefined) {
      formErrors["Förnamn"] = "Felaktigt förnamn";
    }
    if (validator.validate({ inputString: values.Efternamn }).error !== undefined){
      formErrors["Efternamn"] = "Felaktigt efternamn";
    }
    if (validator.validate({ inputString: values.Titel }).error !== undefined) {
      formErrors["Titel"] = "Felaktig titel";
    }
    return formErrors;
  };

  const validateUpdateParticipantInput = (values) => {
    const formErrors = {};
    if (isValidEmail.validate({ email: values["Ändra email"] }).error !== undefined) {
      formErrors["Ändra email"] = "Inkorrekt email-format";
    }
    if (validator.validate({ inputString: values["Ändra förnamn"] }).error !== undefined) {
      formErrors["Ändra förnamn"] = "Felaktigt förnamn";
    }
    if (validator.validate({ inputString: values["Ändra efternamn"] }).error !== undefined){
      formErrors["Ändra efternamn"] = "Felaktigt efternamn";
    }
    if (validator.validate({ inputString: values["Ändra titel"] }).error !== undefined) {
      formErrors["Ändra titel"] = "Felaktig titel";
    }
    return formErrors;
  };
  const validateExcelInput = (participant, row) => {
    const formErrors = {"dropzone": ""};
    if (isValidEmail.validate({ email: participant.email }).error !== undefined) {
      formErrors["dropzone"] += ` Felaktig format på en email-adress på rad ${row}\n`;
    }
    if (validator.validate({ inputString: participant.firstName }).error !== undefined) {
      formErrors["dropzone"] += ` Felaktig format på förnamn på rad ${row}\n`;
    }
    if (validator.validate({ inputString: participant.lastName }).error !== undefined){
      formErrors["dropzone"] += ` Felaktig format på efternamn på rad ${row}\n`;
    }
    if (validator.validate({ inputString: participant.title }).error !== undefined) {
      formErrors["dropzone"] += ` Felaktig format på titel på rad ${row}`;
    }
    return formErrors;
  };
  const noDuplicateEmailsExist = (participants) => {
    const emailDictionary = {};
    for(const participant of participants){
      if(emailDictionary[participant.email] === true){
        return false;
      } else {
        emailDictionary[participant.email] = true;
      }
    };
    return true;
  };
  const submitUpdateParticipantForm = async (values, form) => {
    const formErrors = validateUpdateParticipantInput(values);
    if(Object.keys(formErrors).length > 0) {
      createNotification("Felaktig input.", "error");
      return formErrors;
    }
    try {
      const updatedInfo = {
        email: values["Ändra email"],
        firstName: values["Ändra förnamn"],
        lastName: values["Ändra efternamn"],
        title: values["Ändra titel"]
      };
      const tempParticipants = [...project.participants];
      tempParticipants.splice(selectedParticipantId, 1, updatedInfo);
      if(!noDuplicateEmailsExist(tempParticipants)) {
        createNotification("Duplicerad email.", "error");
        return {"Email": "En annan deltagare har denna email-adress."};
      };
      await UpdateParticipantList(project, tempParticipants);
      createNotification("Deltagaren har uppdaterats.", "success");
      document.getElementById("updateParticipantModal").M_Modal.close();
      await fetchProjects(selectedCustomerId);
      form.reset();
    } catch (error) {
      if(error.errors[0].message.toLowerCase() === "bad request"){
        createNotification(`${values["Ändra email"].split("@")[1].split(".")[1]} är inte en tillåten toppdomän för emailadresser`, "error");
        return {"Ändra email": `${values["Ändra email"].split("@")[1].split(".")[1]} är inte en tillåten toppdomän för emailadresser`};
      } else {
        throw new Error("Internal Server Error");
      }
    }
  };
  const submitAddParticipantForm = async (values, form) => {
    const formErrors = validateParticipantInput(values);
    if(Object.keys(formErrors).length > 0) {
      createNotification("Felaktig input.", "error");
      return formErrors;
    }
    try {
      const participant = {
        email: values.Email,
        firstName: values.Förnamn,
        lastName: values.Efternamn,
        title: values.Titel,
      };
      const tempParticipants = [...project.participants, participant];
      if(!noDuplicateEmailsExist(tempParticipants)) {
        createNotification("Duplicerad email.", "error");
        return {"Email": "En annan deltagare har denna email-adress."};
      };
      await AddParticipant(
        project,
        participant
      );
      createNotification(
        `"${values.Förnamn} ${values.Efternamn}" har lagts till.`,
        "success"
      );
      setTimeout(() => {
        createNotification(
          "Navigera till Formulär i menyn för att skapa formulär.",
          "info",
          10000,
          (key) => (
            <div style={{margin: "5px", cursor: "pointer"}}
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              AVBÖJ
            </div>
          )
        );
      }, 500);

      document.getElementById("addParticipantModal").M_Modal.close();
      setSelectedParticipantId(project.participants.length);
      await fetchProjects(selectedCustomerId);
      form.reset();
      setNewParticipantId(projects.find(p=>p.id===selectedProjectId).participants.length - 1);
    } catch (error) {
      if(error.errors[0].message.toLowerCase() === "bad request"){
        createNotification(`${values.Email.split("@")[1].split(".")[1]} är inte en tillåten toppdomän för emailadresser`, "error");
        return {"Email": `${values.Email.split("@")[1].split(".")[1]} är inte en tillåten toppdomän för emailadresser`};
      } else {
        throw new Error(error.message); 
      }
    }
  };
  const submitParticipantsFromFile = async (values, form) => {
    try {
      setFileError("");
      const file = values.dropzone[0];
      const reader = new FileReader();
      let participantList = [];
      reader.onload = async (evt) => {
        // evt = on_file_select event
        /* Parse data */
        const byteStream = evt.target.result;
        const excelDocument = XLSX.read(byteStream, { type: "binary" });
        /* Get first worksheet */
        const workSheetName = excelDocument.SheetNames[0];
        const workSheet = excelDocument.Sheets[workSheetName];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(workSheet, {
          FS: ";",
          header: 1,
          blankRows: false,
        });
        /* Update state */
        let arrayOfData = data.split("\n");
        for (let i = 1; i < arrayOfData.length; i++) {
          const participantData = arrayOfData[i].split(";");
          let containsData = false;
          for (let j = 0; j < participantData.length; j++) {
            if (participantData[j] !== "") {
              containsData = true;
            }
          }

          if (!containsData) {
            continue;
          }

          const participant = {
            firstName: participantData[0],
            lastName: participantData[1],
            email: participantData[2],
            title: participantData[3],
          };
          
          participantList.push(participant);
        }
        if(!noDuplicateEmailsExist(participantList)) {
          setFileError("Olika deltagare får inte ha samma emailadress");
          return;
        };
        for(const participant in participantList) {
          //0-indexering till 1-indexering +1 för header i excelfilen : )
          const formErrors = validateExcelInput(participantList[participant], parseInt(participant) + 2);
          if(formErrors["dropzone"].length > 0) {
            setFileError(formErrors["dropzone"]);
            return;
          }
        };
        try {
          await AddParticipants(
            project,
            participantList
          );
          createNotification(
            "Deltagarna från Excel-filen har lagts till.",
            "success"
          );
          setTimeout(() => {
            createNotification(
              "Navigera till Formulär i menyn för att skapa formulär.",
              "info",
              30000,
              (key) => (
                <div style={{margin: "5px", cursor: "pointer"}}
                  onClick={() => {
                    closeSnackbar(key);
                  }}
                >
                  AVBÖJ
                </div>
              )
            );
          }, 500);
        } catch (error) {
          if(error.errors[0].message.includes("email")){
            setFileError("Otillåtna tecken i en emailadress (t.ex 'å, ä eller ö')");
            createNotification("Otillåtna tecken i en emailadress (t.ex 'å, ä eller ö')", "error");
            form.reset();
            return;
          } else if (error.errors[0].message.toLowerCase().includes("bad request")) {
            setFileError("Email med otillåten toppdomän, se IANA (https://www.iana.org/domains/root/db)");
            createNotification("Email med otillåten toppdomän.", "error");
            form.reset();
            return;
          }
        }
        document.getElementById("dropzone-modal").M_Modal.close();
        await fetchProjects(selectedCustomerId);
        form.reset();
        setSelectedParticipantId(0);
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      throw new Error("Could not submit participant. " + JSON.stringify(error));
    }
  };

  const createNotification = (text, variant, autoHideDuration, action) => {
    enqueueSnackbar(`${text}`, { variant, autoHideDuration, action });
  };

  return (
    <div className="Card-list">
      <p className="header">Deltagare</p>
      <div className="body">
        <div className="Items Card-min-width">
          {project?.participants?.map((participant, index) => (
            <ParticipantListItem
              createNotification={createNotification}
              participant={participant}
              index={index}
              key={`${participant.email}${index}`}
            />
          ))}
        </div>
      </div>
      {selectedProjectId !== -1 ? (
        <>
          <AddListItem
            icon={"add"}
            text={"Lägg till ny deltagare"}
            onClick={openAddParticipantModal}
          />
          <AddListItem
            icon={"add"}
            text={"Lägg till deltagare med excel"}
            onClick={()=>document.getElementById("dropzone-modal").M_Modal.open()}
          />
        </>
      ) : (
        <></>
      )}

      <DropZoneModal
        modalHTMLIdentifier={"dropzone-modal"}
        formInfo={{
          header: "Lägg till nya deltagare",
        }}
        fileError={fileError}
        setFileError={setFileError}
        onSubmit={submitParticipantsFromFile}
        submitButtonName={"Spara nya deltagare"}
        cancelButtonName={"Avbryt"}
      />
      <FinalFormModal
        //Must match data-targer of trigger
        modalHTMLIdentifier={"addParticipantModal"}
        //variable amount of input fields as array
        inputFields={[
          {
            header: "Email",
            placeHolder: "Skriv in email",
            type: "email",
            id: "addParticipantEmail",
            errorMessages: ["Inkorrekt email-format"]
            
          },
          {
            header: "Förnamn",
            placeHolder: "Skriv in förnamn",
            type: "text",
            id: "addParticipantName",
            errorMessages: ["Felaktigt förnamn"]
            
          },
          {
            header: "Efternamn",
            placeHolder: "Skriv in efternamn",
            type: "text",
            id: "addParticipantSurname",
            errorMessages: ["Felaktigt efternamn"]
            
          },
          {
            header: "Titel",
            placeHolder: "Skriv in titel",
            type: "text",
            id: "addParticipantTitle",
            errorMessages: ["Felaktig titel"]
            
          },
        ]}
        //must match name of headers in input field array
        initialValues={{"Email": "", "Förnamn": "", "Efternamn": "", "Titel": "", }}
        //u know wat onsubmit does lmao
        onSubmit={submitAddParticipantForm}
        submitButtonName={"Spara ny deltagare"}
        cancelButtonName={"Avbryt"}
        formInfo={{
          header: "Lägg till deltagare",
        }}
      />

      <FinalFormModal
        //Must match data-targer of trigger
        modalHTMLIdentifier={"updateParticipantModal"}
        //variable amount of input fields as array
        inputFields={[
          {
            header: "Ändra email",
            defaultValue: "",
            placeHolder: "Skriv in email",
            type: "email",
            id: "updateParticipantInfoEmail",
            errorMessages: ["Inkorrekt email-format"]
          },
          {
            header: "Ändra förnamn",
            defaultValue: "",
            placeHolder: "Skriv in förnamn",
            type: "text",
            id: "updateParticipantInfoFirstName",
            errorMessages: ["Felaktigt förnamn"]
          },
          {
            header: "Ändra efternamn",
            defaultValue: "",
            placeHolder: "Skriv in efternamn",
            type: "text",
            id: "updateParticipantInfoLastName",
            errorMessages: ["Felaktigt efternamn"]
          },
          {
            header: "Ändra titel",
            defaultValue: "",
            placeHolder: "Skriv in titel",
            type: "text",
            id: "updateParticipantInfoTitle",
            errorMessages: ["Felaktig titel"]
          }
        ]}
        //must match name of headers in input field array
        initialValues={
          {
            "Ändra email": project?.participants[selectedParticipantId]?.email,
            "Ändra förnamn": project?.participants[selectedParticipantId]?.firstName,
            "Ändra efternamn": project?.participants[selectedParticipantId]?.lastName,
            "Ändra titel": project?.participants[selectedParticipantId]?.title
          }
        }

        onSubmit={submitUpdateParticipantForm}

        submitButtonName={"Spara"}
        cancelButtonName={"Avbryt"}
        formInfo={{
          header: "Ändra deltagaruppgifter"
        }}
      />
    </div>
  );
};

export default ParticipantList;
