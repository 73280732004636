import React, {
  useContext,
  createRef,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import "./FormListItem.css";
import M from "materialize-css";
import { FormContext } from "../../../Utils/contexts/FormContext";
import { getFormScores } from "../../../graphql/queries";
import { deleteForm, setFormStatus } from "../../../graphql/mutations";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import { API, graphqlOperation } from "aws-amplify";
import DropDownMenu from "../../General/DropDownMenu/DropDownMenu";
import { OpenModalById } from "../../../Utils/functions/OpenModalById";
import Preloader from "../../General/Preloader/Preloader";
import FormScoreTable from "../FormScoreTable/FormScoreTable";
import { useSnackbar } from "notistack";

const FormListItem = (props) => {
  const {
    selectedFormId,
    setSelectedFormId,
    selectedCustomerId,
    selectedProjectId,
    fetchParticipants,
    setParticipants,
    participants,
    fetchForms,
    isFetchingParticipants,
  } = useContext(FormContext);
  const [formAnswers, setFormAnswers] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hasFormScore, setHasFormScore] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const ref = createRef();
  useEffect(() => {
    let elems = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });
    M.AutoInit();

    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, []);

  useLayoutEffect(() => {
    let elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, { enterDelay: 700 });
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    }
  }, [ref]);

  const getStatusTranslation = (participantStatus) => {
    switch (participantStatus) {
      case "OPEN":
        return "Öppet";
      case "CLOSED":
        return "Stängt";
      default:
        return "Okänd";
    }
  };

  let menuObjects = [
    {
      icon: "assessment",
      text: "Visa samlad poäng",

      customOnClick: async (e) => {
        e.stopPropagation();
        setSelectedFormId(props.form.id);
        OpenModalById(`getFormScores_${props.index}`);
        try {
          setIsLoading(true);
          const response = await API.graphql(
            graphqlOperation(getFormScores, {
              formId: props.form.id,
            })
          );
          const answers = response.data.getFormScores;
          setFormAnswers(JSON.parse(answers));
          setIsLoading(false);
          setHasFormScore(true);
        } catch (error) {
          setHasFormScore(false);
          setIsLoading(false);
        }
      },
    },
    {
      icon: "delete",
      text: "Ta bort",
      customOnClick: async (e) => {
        e.stopPropagation();
        if (props.form.id !== selectedFormId)
          await fetchParticipants(props.form.id);
        setSelectedFormId(props.form.id);
        OpenModalById(
          `removeFormModal_${selectedCustomerId}_${selectedProjectId}_${props.index}`
        );
      },
    },
    {
      icon: `${props.form.status === "OPEN" ? "lock" : "lock_open"}`,
      text: `${props.form.status === "OPEN" ? "Stäng" : "Öppna"} formulär`,

      customOnClick: async (e) => {
        e.stopPropagation();
        setSelectedFormId(props.form.id);
        OpenModalById(`toggleFormStatusModal${props.index}`);
      },
    },
  ];
  props.form.status.includes("OPEN") &&
    (menuObjects = [
      {
        icon: "send",
        text: "Skicka inbjudningar",
        customOnClick: async (e) => {
          e.stopPropagation();
          if (props.form.id !== selectedFormId)
            await fetchParticipants(props.form.id);
          setSelectedFormId(props.form.id);
          OpenModalById("sendInvitationEmailModal");
        },
      },
    ].concat(menuObjects));
  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, { variant });
  };

  return (
    <>
      <div
        className="Item-card"
        style={{ height: "100px" }}
        id={`form-list-${props.form.id}`}
      >
        <div
          className={props.form.id === selectedFormId ? "body-green" : "body"}
          onClick={() => {
            setParticipants([]);
            setSelectedFormId(props.form.id);
            fetchParticipants(props.form.id);
          }}
        >
          <div className="Body-container-column">
            <div className="Body-container-row">
              <div
                className={"tooltipped Format-text"}
                data-position="top"
                data-tooltip={`<div style="text-align: left;"><p>Namn: ${
                  props.form.name
                }</p><p>Språk: ${
                  props.form.configuration?.language === "SE"
                    ? "Svenska"
                    : "Engelska"
                }</p><p>Deadline: ${
                  props.form.configuration?.deadline
                }</p><p>Inbjudningsdatum: ${
                  props.form.configuration?.invitation
                    ? props.form.configuration?.invitation
                    : "Inget"
                }</p><p>Påminnelsedatum: ${
                  props.form.configuration?.reminder
                }</p></div>`}
                data-testid={props.form.name}
                ref={ref}
              >
                <p>{`${props.form.name}`}</p>
                <div>
                  <p className="Date-formating">
                    {props.form.createdAt.split("T")[0]}
                  </p>
                </div>
              </div>
              {!isFetchingParticipants && (
                <DropDownMenu
                  isFetching={isFetchingParticipants}
                  ulId={`formListItemDropDownMenu_${props.index}`}
                  menuObjects={menuObjects}
                />
              )}
            </div>
            <div className="Right-align">
              <p
                className="Status"
                style={
                  props.form.status === "OPEN"
                    ? { backgroundColor: "rgb(113, 176, 0)" }
                    : { backgroundColor: "rgb(219, 75, 26)" }
                }
              >
                {props.form.status === undefined
                  ? "NoStatus"
                  : getStatusTranslation(props.form.status)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <FinalFormModal
        //Must match data-targer of trigger
        modalHTMLIdentifier={`removeFormModal_${selectedCustomerId}_${selectedProjectId}_${props.index}`}
        //variable amount of input fields as array
        inputFields={[]}
        //must match name of headers in input field array
        initialValues={[]}
        //u know wat onsubmit does lmao
        onClick={(e) => e.stopPropagation()}
        onSubmit={async () => {
          await API.graphql(
            graphqlOperation(deleteForm, { id: props.form.id })
          );

          document
            .getElementById(
              `removeFormModal_${selectedCustomerId}_${selectedProjectId}_${props.index}`
            )
            .M_Modal.close();

          if (props.form.id === selectedFormId) {
            setSelectedFormId(-1);
            setParticipants([]);
          }
          createNotification(`"${props.form.name}" har raderats.`, "success");
          await fetchForms(selectedProjectId);
        }}
        submitButtonName={"Ja"}
        cancelButtonName={"Nej"}
        formInfo={{
          header: (
            <>
              {participants?.find(
                (participant) =>
                  !(
                    participant.status.includes("CREATED") ||
                    participant.status.includes("SENT") ||
                    participant.status.includes("OPENED")
                  )
              ) ? (
                <>
                  <div style={{ display: "flex", color: "rgb(219, 75, 26)" }}>
                    <i className="material-icons">warning</i>
                    <p
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingLeft: "1%",
                      }}
                    >
                      Minst en deltagare har påbörjat formuläret, att ta bort
                      det nu kommer invalidera deras svar.
                    </p>
                  </div>
                  <br />
                  <br />
                </>
              ) : (
                <></>
              )}
              <p>{`Vill du ta bort ${props.form.name}?`}</p>
              <br />
            </>
          ),
        }}
        errorMessage={errorMessage}
      />

      <FinalFormModal
        modalHTMLIdentifier={`toggleFormStatusModal${props.index}`}
        inputFields={[]}
        onSubmit={async () => {
          try {
            setIsLoading(true);
            await API.graphql(
              graphqlOperation(setFormStatus, {
                id: props.form.id,
                status: `${props.form.status === "OPEN" ? "CLOSED" : "OPEN"}`,
              })
            );
            document
              .getElementById(`toggleFormStatusModal${props.index}`)
              .M_Modal.close();
            createNotification(
              `"${props.form.name}" är nu ${
                props.form.status === "OPEN" ? "stängt" : "öppet"
              }.`,
              "success"
            );
            await Promise.all([
              fetchForms(selectedProjectId),
              fetchParticipants(props.form.id),
            ]);

            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
          }
        }}
        onCancel={async () => {
          await fetchParticipants(props.form.id);
          document
            .getElementById(`toggleFormStatusModal${props.index}`)
            .M_Modal.close();
        }}
        submitButtonName={"Ja"}
        cancelButtonName={"Nej"}
        formInfo={{
          header: `Vill du ${
            props.form.status === "OPEN" ? "stänga" : "öppna"
          } formuläret?`,
        }}
        errorMessage={errorMessage}
      />
      <FinalFormModal
        modalHTMLIdentifier={`getFormScores_${props.index}`}
        inputFields={[]}
        onSubmit={async () => {
          await fetchParticipants(props.form.id);
        }}
        onCancel={async () => {
          await fetchParticipants(props.form.id);
        }}
        submitButtonName={"hide"}
        cancelButtonName={"Stäng"}
        formInfo={{
          header: "Samlad medelpoäng",
        }}
        errorMessage={errorMessage}
        bodyText={
          isLoading ? (
            <Preloader size="small" />
          ) : hasFormScore ? (
            <FormScoreTable
              firstTitle={props.form.name}
              secondTitle={""}
              answerData={formAnswers}
            />
          ) : (
            <p>Ingen deltagare har skickat in sina svar.</p>
          )
        }
      />
    </>
  );
};

export default FormListItem;
