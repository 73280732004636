import { OpenModalById } from "../../Utils/functions/OpenModalById";
export const superMenuObjects = [
  {
    icon: "star_outline",
    text: "Ta bort som SuperUser",
    customOnClick: () => OpenModalById("DemoteUserModal")
  }
];

export const nonSuperMenuObjects = [
  {
    icon: "star",
    text: "Gör till SuperUser",
    customOnClick: () => OpenModalById("PromoteUserModal")
  },
  {
    icon: "delete",
    text: "Ta bort",
    customOnClick: () => OpenModalById("DeleteUserModal")
  },
];

export const mapUsersToListItems = (users, activeUser) =>
  users.map((user) => ({
    name: user?.username,
    nameIcon: user?.isSuperUser ? "star" : null,
    uniqueMenuObjects: user?.username === activeUser ? null :
      user?.isSuperUser
        ? superMenuObjects
        : nonSuperMenuObjects,
  }));

export const mapUsersNameToName= (users) =>
  users.map((user) => ({
    name: user?.username,
  }));

export const mapCustomersToListItems = (customers) =>
  customers.map((customer) => ({
    name: customer?.companyName,
    customerId: customer?.id
  }));

export const filterUsers = (users, userName) =>
  users.filter((user) => user.username !== userName);
