import { API, graphqlOperation } from "aws-amplify";
import { sendReminderEmail } from "../../graphql/mutations";

const SendReminderEmail = async (participantId) => {
  try {
    const tmp = (await API.graphql(graphqlOperation(sendReminderEmail, {
      participantId: participantId
    })));
    return tmp.data.sendReminderEmail;

  } catch (error) {
    throw new Error("InternalServerError");
  }
};

export default SendReminderEmail;
