import React from "react";
import { Form } from "react-final-form";
import "./ListFormModal.css";
import "../../../Utils/CSS/modals.css";
import ModalButton from "../ModalButton/ModalButton";
import List from "../../List/List";

const ListFormModal = (props) => (
  <div>
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      id={props.modalHTMLIdentifier}
      data-testid={props.modalHTMLIdentifier}
      className="modal backdrop-static"
    >
      {/* If you want Bottom Sheet Modal then add 
                        bottom-sheet class to the "modal" div
                        If you want Fixed Footer Modal then add
                        modal-fixed-footer to the "modal" div*/}
      <div
        // className="modal fade"
        id={props.modalHTMLIdentifier + "dialog"}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5
                className="modal-title"
                id={props.modalHTMLIdentifier + "modalHeader"}
              >
                {props.formInfo.header}
              </h5>
            </div>
            <List
              listName=""
              listItems={props.inputFields}
              onListItemClick={props.onListSelect}
              initialSelectedListItemName={props.initialValues}
              styling={props.styling}
            />
            <Form
              onSubmit={props.onSubmit}
              initialValues={props.initialValues}
              keepDirtyOnReinitialize={false}
              render={({ handleSubmit, submitting, form }) => (
                <form onSubmit={(values) => handleSubmit(values, form)}>
                  <div>{props.errorMessage}</div>
                  <div>{props.bodyText}</div>
                  <div className="modal-footer border-top-0 Footer-container">
                    <ModalButton
                      buttonName={props.cancelButtonName}
                      buttonType={"button"}
                      disabled={submitting}
                      onCancel={form.reset}
                      buttonTrigger="modal-close"
                    />
                    <ModalButton
                      buttonName={props.submitButtonName}
                      buttonType={"submit"}
                      disabled={submitting}
                      buttonTrigger=""
                    />
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ListFormModal;
