import React, { useEffect, useState } from "react";
import "./FormScoreTable.css";

const FormScoreTable = (props) => {
  const [hasAnswers, setHasAnswers] = useState("");
  const participantAnswers = props.answerData;

  useEffect(() => {
    setHasAnswers(
      Object.keys(participantAnswers).filter(
        (item) => participantAnswers[item].score !== 0
      )
    );
  }, []);

  return (
    <table style={{ borderStyle: "solid" }}>
      <tbody>
        <tr>
          <th style={{ borderRight: "5px solid", textAlign: "center" }}>
            {props.firstTitle} {props.secondTitle}
          </th>
          <th
            className="Table-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {hasAnswers.length !== 0 ? (
              <>
                <p>0</p>
                <p>50</p>
                <p>100</p>
              </>
            ) : (
              <p>Inga svar</p>
            )}
          </th>
        </tr>
        {Object.keys(participantAnswers).map((trait, i) => {
          return (
            <tr
              key={i}
              style={{
                alignItems: "center",
              }}
            >
              <td
                style={{
                  borderStyle: "solid",
                  borderRight: "5px solid",
                  width: "40%",
                  height: "60px",
                  position: "relative",
                }}
              >
                {trait}. {participantAnswers[trait].name[0].value}
              </td>
              <td
                style={{
                  width: "60%",
                  borderStyle: "solid",
                  padding: "0px",
                  position: "relative",
                }}
              >
                <div className="Vertical-line" />
                <div className="Centering-vertical">
                  <div
                    style={{
                      width: `${participantAnswers[trait].score}%`,
                      height: "50%",
                      backgroundColor: "rgb(141, 184, 54)",
                      color:
                        participantAnswers[trait].score <= 1
                          ? "black"
                          : "white",
                      textAlign: "center",
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        marginLeft:
                          participantAnswers[trait].score <= 1 && "15px",
                      }}
                    >
                      {participantAnswers[trait].score}
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default FormScoreTable;
