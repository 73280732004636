import React, { useEffect, useContext } from "react";
import { Form, Field } from "react-final-form";
import { QuestionContext } from "../../../Utils/contexts/QuestionContext";
import { ModalInput } from "../../General/ModalInput/ModalInput";
import ModalButton from "../../General/ModalButton/ModalButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SequenceItem from "../SequenceItem/SequenceItem";
import { LANGUAGE } from "../../../Utils/language";
import TestComponent from "./TestComponent";
import M from "materialize-css";

import "./TESTFORM.css";

const TESTFORM = (props) => {
  const {
    questions,
    selectedQuestion,
    traits,
    setSequence,
    sequence,
    setLanguage,
    language,
  } = useContext(QuestionContext);

  const scoreInput = (score, index) => {
    return {
      header: `Points: ${index + 1}`,
      id: `Points: ${index + 1}`,
      divName: "ScoreInput",
      initialValue: score,
      type: "text",
      labelName: "Text-left",
      style: {
        display: "flex",
        flexDirection: "column",
        marginRight: "10px",
        width: "100%",
      },
    };
  };

  const questionFormulationInput = {
    header: language === "EN" ? "Question" : "Fråga",
    id: language === "EN" ? "Question" : "Fråga",
    divName: "Question-formulation-input",
    type: "text",
    labelName: "Text-left",
    style: { display: "flex", flexDirection: "column", marginBottom: "10px" },
  };
  const init = {};
  {
    selectedQuestion !== undefined &&
      Object.entries(selectedQuestion?.scores).map(
        ([answer, score], index) => {
          init[`Points: ${index + 1}`] = score;
        }
      );
  }
  {
    selectedQuestion !== undefined &&
      selectedQuestion?.translations.map((t) => {
        const prefix = t.language.includes("SE")
          ? "Svarsalternativ:"
          : "Answer option:";
        Object.entries(t.answerOptions).map(([answer, formulation], index) => {
          init[`${prefix} ${index + 1}`] = formulation;
        });
      });
  }
  //magi rör ej
  if (selectedQuestion !== undefined) {
    selectedQuestion?.translations.map((t) => {
      t.language.includes("EN")
        ? (init["Question"] = t.formulation)
        : (init["Fråga"] = t.formulation);
    });
  }
  const tid = selectedQuestion?.trait?.id;
  init["TraitsUpdate"] = tid;
  init["Language"] = LANGUAGE.SWEDISH;

  const onDragEnd = ({ destination, source }) => {
    if (destination === null) return;
    if (!destination.droppableId.includes("orderList")) {
      return;
    }
    const newSequence = sequence.slice();
    const tmp = sequence[source.index];
    newSequence[source.index] = newSequence[destination.index];
    newSequence[destination.index] = tmp;
    setSequence(newSequence);
  };

  useEffect(() => {
    const languageDropdown = document.getElementById("LanguageDropdownUpdate");
    const traitDropDown = document.getElementById("TraitsUpdate");
    languageDropdown.value = (language);
    traitDropDown.value = tid;
    M.FormSelect.init(languageDropdown);
    M.FormSelect.init(traitDropDown);
  }, [sequence]);

  return (
    <Form
      id="questionUpdateForm"
      onSubmit={props.onSubmit}
      initialValues={init}
      keepDirtyOnReinitialize={false}
      render={({ handleSubmit, submitting, form }) => (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5
              className="modal-title"
              style={{ textAlign: "left" }}
              id={props.modalHTMLIdentifier + "modalHeader"}
            >
              {"Ändra fråga"}
            </h5>
            <div
            >
              <i className="material-icons modal-close Close-cross-button" onClick={() => {
                form.reset();
                props.onCancel && props.onCancel();
              }}>close</i>
            </div>
          </div>

          <form onSubmit={(values) => handleSubmit(values, form)}>
            <div className="Input-container">
              <div className="Input-column">
                <div className="Flex-column Q-Modal-input">
                  <label>Kategori</label>
                  <Field
                    id={"TraitsUpdate"}
                    name={"TraitsUpdate"}
                    aria-label="modal-input"
                    component={"select"}
                    className={"Drop-down-selector Language hideSelector"}
                    initialValue={
                      selectedQuestion?.questionTraitId
                    }
                  >
                    {traits.map((trait) => {
                      return (
                        <option value={trait.id} key={trait.id}>
                          {`${trait.symbol}: ${
                            trait.name.find(
                              (translation) => translation.language === language
                            ).value
                          }`}
                        </option>
                      );
                    })}
                  </Field>
                </div>
                <div className="Flex-column Modal-input2">
                  <label>Språk</label>
                  <select
                    id="LanguageDropdownUpdate"
                    className="Drop-down-selector Language hideSelector"
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option value={LANGUAGE.SWEDISH}>Svenska</option>
                    <option value={LANGUAGE.ENGLISH}>English</option>
                  </select>
                </div>
              </div>
              <Field
                name={questionFormulationInput.header}
              >
                {({ input, meta }) => (
                  <ModalInput
                    inputField={questionFormulationInput}
                    labelName="input-label"
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ width: "95%" }}></div>
                <p
                  style={{
                    fontSize: "0.8rem",
                    color: "#9e9e9e",
                    textAlign: "left",
                    minWidth: "5%",
                  }}
                >
                  Svarsordning
                </p>
              </div>
              <div className="AnswerOptionContainer">
                <div className="Scores">
                  {selectedQuestion !== undefined &&
                    Object.entries(selectedQuestion?.scores).map(
                      ([answer, score], index) => {
                        const inputFieldScore = scoreInput(score, index);
                        return (
                          <div className="Input-column" key={answer}>
                            <Field
                              name={inputFieldScore.header}
                              key={score}
                            >
                              {({ input, meta }) => (
                                <ModalInput
                                  input={input}
                                  meta={meta}
                                  labelName="input-label"
                                  inputField={inputFieldScore}
                                />
                              )}
                            </Field>
                          </div>
                        );
                      }
                    )}
                </div>
                <div className="Answers">
                  {selectedQuestion !== undefined && <TestComponent />}
                </div>
                <div className="Order">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                      droppableId="orderList"
                      type="question"
                      style={{ height: "100%" }}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className="Q-Report-form-dnd"
                          style={{
                            backgroundColor: snapshot.isDraggingOver
                              ? "grey"
                              : "rgb(217, 217, 217)",
                            overflow: "hidden",
                          }}
                          {...provided.droppableProps}
                        >
                          <div>
                            <div>
                              {sequence.map((sequencePosition, index) => {
                                return (
                                  <Draggable
                                    draggableId={`draggable-${sequencePosition}`}
                                    key={sequencePosition}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <SequenceItem
                                          sequencePosition={sequencePosition}
                                          key={index}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </div>
                            {provided.placeholder}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
            {props.inputFields.map((inputField, key) => (
              <Field
                name={inputField.header}
                key={key}
              >
                {({ input, meta }) => (
                  <ModalInput
                    labelName="input-label"
                    input={input}
                    inputField={inputField}
                    meta={meta}
                  />
                )}
              </Field>
            ))}
            <div>{props.bodyText}</div>
            <div className="modal-footer border-top-0 Footer-container">
              <ModalButton
                buttonName={props.cancelButtonName}
                buttonType={"button"}
                disabled={submitting}
                onCancel={form.reset}
                buttonTrigger="modal-close"
              />
              <ModalButton
                buttonName={props.submitButtonName}
                buttonType={"submit"}
                disabled={submitting}
                buttonTrigger=""
              />
            </div>
          </form>
        </>
      )}
    />
  );
};

export default TESTFORM;
