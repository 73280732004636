import React from "react";
import "../../Utils/CSS/views.css";
import CustomerComponentParent from "../../components/Customers/CustomerComponentParent/CustomerComponentParent";
import { CustomerProvider } from "../../Utils/contexts/CustomerContext";

const CustomerView = () => {

  return (
    <div className="Top-margin">
      <div className="Customer-list-container">
        <CustomerProvider>
          <CustomerComponentParent />
        </CustomerProvider>
      </div>
    </div>
  );
};

export default CustomerView;
