import React, { useEffect, useContext, useState } from "react";
import { ReportContext } from "../../../Utils/contexts/ReportContext";
import { Auth } from "aws-amplify";
import "./CustomerSelector.css";

import M from "materialize-css";
import ToggleListItem from "../../General/ToggleListItem/ToggleListItem";

const CustomerSelector = (props) => {
  const { setIsFetchingProjects, fetchProjects, customers, isLoaded, selectedCustomerId, setSelectedCustomerId, toggleShowOnlyMyCustomers,
    setSelectedProjectId, setDropZoneForms, setFormCopies, showOnlyMyCustomers, setCurrentUser } = useContext(ReportContext);

  const [isSuperUser, setIsSuperUser] = useState(false);
  useEffect(() => {
    M.AutoInit();
    initAutoCompleteList();
    getAuth();
    document.getElementById("autocomplete-input").focus();
  }, [isLoaded]);

  useEffect(() => {
    initAutoCompleteList();
  },[customers]);

  const initAutoCompleteList = () => {
    const ac = document.getElementById("autocomplete-input");
    M.Autocomplete.init(ac, {
      data: generateOptions(),
      minLength: 0,
      onAutocomplete: setInput
    });
  };

  const generateOptions = () =>{
    const options = {};
    customers.map( customer => {
      options[customer.companyName] = null;
    });
    return options;
  };

  const isFinishedWriting = (value) => {
    let match = false;
    let matchingString = "";
    customers.map( customer  => {
      if (customer.companyName.toUpperCase() === value.target.value.toUpperCase()) {
        match = true;
        matchingString = customer.companyName;
      }
    });
    if (match) {
      setInput(matchingString);
    } else if(selectedCustomerId !== -1){
      setSelectedCustomerId(-1);
      setSelectedProjectId(-1);
      setDropZoneForms([]);
      setFormCopies({});
    }
  };

  const setInput = async (value) =>{
    setIsFetchingProjects(true);
    const  selectedCustomer = customers.find(c => c["companyName"].includes(value));
    setSelectedCustomerId(selectedCustomer.id);
    await fetchProjects(selectedCustomer.id).then(setIsFetchingProjects(false));
  };


  const getAuth = async () => {
    const user = (await Auth.currentAuthenticatedUser());
    setCurrentUser(user?.signInUserSession.accessToken.payload.username);
    if (user?.signInUserSession.accessToken.payload["cognito:groups"].includes("SuperUser")){
      setIsSuperUser(true);
    };
  };

  return (
    <div className="customer-selector-container">
      <div className="customer-selector-col">
        <div className="input-field">
          <i className="material-icons prefix">search</i>
          <input type="text" id="autocomplete-input" className="autocomplete" autoComplete="off" onChange={isFinishedWriting}/>
          <label htmlFor="autocomplete-input">Välj kund</label>
        </div>
      </div>
      {isSuperUser && (<div className="customer-selector-col">
        <div className="align-checkbox Users-show-customers toggle">
          <ToggleListItem
            dataTestId={"show-customer-toggle"}
            onIcon={"remove_red_eye"}
            onText={"Visa bara vald användares kunder"}
            offIcon={"remove_red_eye"}
            offText={"Visa alla kunder"}
            toggleState={showOnlyMyCustomers}
            handleClick={toggleShowOnlyMyCustomers}
            position="initial"
          />
        </div>
      </div>)}
    </div>
  );
};

export default CustomerSelector;
