import React from "react";
import "./ModalButton.css";

const ModalButton = (props) => {
  return (
    <div className="Button-margin">
      <button
        className={`btn btn-success ${props.buttonTrigger}`}
        type={props.buttonType}
        style={{display: (props.buttonName === "hide") && "none", WebkitTextFillColor: "white", opacity: 1}}
        disabled={props.disabled}
        onClick={props.onCancel}
      >
        {props.buttonName}
      </button>
    </div>
  );
};

export default ModalButton;
