import { useState, createRef, useLayoutEffect } from "react";

const ParticipantInfoListItem = ({ description, text }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = createRef();

  useLayoutEffect(() => {
    var elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, {enterDelay: 700});  
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    }
  }, [ref]);
  
  return (
    <div className="Participant-info-card">
      <p className="title">{description}</p>
      <div className="Participant-info-card body">
        <p
          className={showTooltip ? "tooltipped" : ""}
          data-position="top"
          data-tooltip={text}
          ref={ref}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default ParticipantInfoListItem;
