import React, { useEffect, useContext } from "react";
import M from "materialize-css";
import { Form, Field } from "react-final-form";
import "./DropZoneModal.css";
import "../../../Utils/CSS/modals.css";
import Dropzone from "react-dropzone";
import ModalButton from "../ModalButton/ModalButton";
import { CustomerContext } from "../../../Utils/contexts/CustomerContext";

const DropZoneModal = (props) => {
  const { fetchTemplate } = useContext(CustomerContext);
  useEffect(() => {
    M.AutoInit();

    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, []);

  return (
    <div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        id={props.modalHTMLIdentifier}
        className="modal backdrop-static"
      >
        {/* If you want Bottom Sheet Modal then add 
                        bottom-sheet class to the "modal" div
                        If you want Fixed Footer Modal then add
                        modal-fixed-footer to the "modal" div*/}
        <div
          // className="modal fade"
          id={props.modalHTMLIdentifier + "dialog"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div
                className="modal-header border-bottom-0"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  margin: "0px",
                }}
              ></div>
              <Form
                onSubmit={props.onSubmit}
                initialValues={props.initialValues}
                keepDirtyOnReinitialize={false}
                mutators={{
                  setFile: (args, state, utils) => {
                    utils.changeValue(state, "dropzone", () => args);
                  },
                }}
                render={({ handleSubmit, form, submitting, pristine }) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5
                        className="modal-title"
                        id={props.modalHTMLIdentifier + "modalHeader"}
                      >
                        {props.formInfo.header}
                      </h5>
                      <div>
                        <i
                          className="material-icons modal-close Close-cross-button"
                          onClick={() => {
                            form.reset();
                            props.onCancel && props.onCancel();
                          }}
                        >
                          close
                        </i>
                      </div>
                    </div>
                    <div style={{ display: "flex", color: "rgb(219, 75, 26)" }}>
                      <i className="material-icons">warning</i>
                      <p
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "1%",
                        }}
                      >
                        Detta kommer att skriva över befintliga deltagare
                      </p>
                    </div>
                    <form onSubmit={handleSubmit} disabled={submitting}>
                      <Field name={"dropzone"} key="sdf" validate={() => {}}>
                        {({ input: { value, onChange, ...input }, meta }) => (
                          <div>
                            <Dropzone
                              onDrop={(target) => {
                                onChange(target);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    {...getRootProps()}
                                    className="Dropzone-box"
                                  >
                                    <input
                                      {...getInputProps()}
                                      {...input}
                                      accept=".xls, .xlsx"
                                      onChange={({ target }) => {
                                        props.setFileError(false);
                                        return onChange(target.files);
                                      }}
                                      type="file"
                                    />
                                    <i
                                      className="material-icons medium"
                                      style={{ color: "#bfbfbf" }}
                                    >
                                      file_upload
                                    </i>
                                    <p>
                                      Dra en excel-fil hit, eller klicka för att
                                      välja från utforskaren
                                    </p>
                                  </div>
                                  {value.length > 0 && (
                                    <div className="File-name-container">
                                      <i
                                        onClick={() =>
                                          form.change("dropzone", undefined)
                                        }
                                        className="material-icons Reset-button"
                                      >
                                        remove_circle_outline
                                      </i>
                                      <p>{value[0].name}</p>
                                      <i className="material-icons">
                                        insert_drive_file
                                      </i>
                                    </div>
                                  )}
                                  <pre>
                                    <p style={{ color: "#FF0000" }}>
                                      {props.fileError}
                                    </p>
                                  </pre>
                                </section>
                              )}
                            </Dropzone>

                            {meta.error && meta.touched && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <div className="modal-footer border-top-0 Footer-container">
                        <div className="Button-margin">
                          <button
                            className={"btn btn-success"}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "150px",
                            }}
                            type={"button"}
                            disabled={submitting}
                            onClick={fetchTemplate}
                          >
                            Ladda ner mall{" "}
                            <i
                              style={{ marginLeft: "5px" }}
                              className="material-icons"
                            >
                              file_download
                            </i>
                          </button>
                        </div>
                        <ModalButton
                          buttonName={props.cancelButtonName}
                          buttonType={"button"}
                          disabled={submitting}
                          onCancel={form.reset}
                          buttonTrigger="modal-close"
                        />
                        <ModalButton
                          buttonName={props.submitButtonName}
                          buttonType={"submit"}
                          disabled={pristine || submitting}
                          buttonTrigger=""
                        />
                      </div>
                    </form>
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropZoneModal;
