import React, { useContext, createRef, useLayoutEffect, useState } from "react";
import "./ProjectListItem.css";
import { FormContext } from "../../../Utils/contexts/FormContext";
import M from "materialize-css";
import { setFormStatus } from "../../../graphql/mutations";


const ProjectListItem = (props) => {
  const {
    selectedProjectId, setSelectedProjectId,
    setSelectedParticipantId,
    setSelectedFormId,
    fetchForms,
    isFetchingForms,
    isFetchingParticipants,
    setForms,
    setParticipants
  } = useContext(FormContext);

  const [showTooltip, setShowTooltip] = useState(false);
  const ref = createRef();

  useLayoutEffect(() => {
    let elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, {enterDelay: 700});
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    } 
  }, [ref]);

  return (
    <div
      className="Item-card"
      onClick={async (e) => {
        if(isFetchingForms || isFetchingParticipants) return;
        e.stopPropagation();
        setForms([]);
        setParticipants([]);
        setSelectedProjectId(props.project.id);
        setSelectedParticipantId(-1);
        setSelectedFormId(-1);
        // setIsFetchingForms(true);
        await fetchForms(props.project.id);
        // setIsFetchingForms(false);
      }}
    >

      <div data-testid={props.project.projectName} className={(props.project.id === selectedProjectId) ? "body-green" : "body"}>
        <div className="Body-container">
          <p className={showTooltip ? "tooltipped" : ""} data-position="top" data-tooltip={props.project.projectName} ref={ref}>{props.project.projectName}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectListItem;
