import React, { useState, useContext } from "react";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import { isValidEmail } from "../../../Utils/functions/inputFieldValidation";
import { UsersContext } from "../../../Utils/contexts/UsersContext";
import { createUser } from "../../../Utils/functions/api/usersApi";
import ApiError from "../../../Utils/error/ApiError";
import { useSnackbar } from "notistack";

const AddUserModal = ({ indentifyer = "AddUserModal" }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { users, setUsers, setNewUser, setSelectedUser, setCustomers } = useContext(UsersContext);

  const [isDuplicatedName, setIsDuplicatedName] = useState("");

  const closeModal = () => {
    document.getElementById(indentifyer).M_Modal.close();
  };

  const submitCreateUserForm = async (values, form) => {
    try {
      if (isValidEmail.validate({ email: values.Email }).error !== undefined) {
        createNotification("Inkorrekt emailadress.","error");
        return {Email: "Inkorrekt emailadress."};
      }
      const newUser = await createUser(values.Email);
      createNotification(`"${values.Email}" har skapats.`,"success");
      setUsers([...users, newUser.data.createUser]);
  
      setSelectedUser(newUser.data.createUser.username);
      setNewUser(newUser.data.createUser.username);
      setCustomers([]);
      closeModal();
      form.reset();
    } catch (err) {
      createNotification(`Kunde inte skapa användaren "${values.Email}".`,"error");
    }
  };

  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, {variant});
  };

  return (
    <FinalFormModal
      modalHTMLIdentifier={indentifyer}
      inputFields={[
        {
          header: "Email",
          defaultValue: "",
          placeHolder: "Skriv in användarens e-post",
          type: "text",
        },
      ]}
      initialValues={{
        Email: "",
      }}
      onSubmit={submitCreateUserForm}
      submitButtonName="Spara ny användare"
      cancelButtonName="Avbryt"
      formInfo={{
        header: "Lägg till användare",
      }}
      errorMessage={isDuplicatedName}
    />
  );
};

export default AddUserModal;
