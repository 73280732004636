import React from "react";
import "./FormAccess.css";
import adviserpartnerlogo from "../../../assets/adviser-partner-logo.png";
import { translation } from "../../ParticipantForm/ParticipantFormTranslations";
import LanguageSwitch from "../../ParticipantForm/LanguageSwitch/LanguageSwitch";

const FormAccess = (props) => {
  return (
    <>
      <LanguageSwitch />
      <div className="Main-container">
        <div
          className="Content-container"
          style={{
            width: props.isTabletOrMobile ? "100%" : "60%",
            height: props.isTabletOrMobile ? "100%" : "50%",
          }}
        >
          <div className="header">
            <img
              alt="adviser partner logo"
              className="logo"
              src={adviserpartnerlogo}
            />
          </div>
          <div className="Message-body">
            <div>
              <h4>Adviser Partner Sales Health Check</h4>
            </div>

            {props.isDone && (
              <p className="greetings-text">
                {translation.analysisCompleted[props.language]}
              </p>
            )}
            {props.message.map((m, key) => {
              return (
                <div className="message" key={key}>
                  <p style={{ fontSize: "1.2rem" }}>{m}</p>
                </div>
              );
            })}

            <div className="footer" style={{ fontSize: "1.2rem" }}>
              <p>{translation.supportFooter[props.language]}</p>
              <p>
                <a href={`mailto:${props.email}`}>{props.email}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormAccess;
