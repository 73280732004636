import React, { useEffect, useRef } from "react";
import M from "materialize-css";
import "../../Utils/materialize/materialize.min.css";
import "./GenericErrorPage.css";
import ApiError from "../../Utils/error/ApiError";

function GenericErrorPage({
  error,
  title = "Något gick fel",
  description = "Sidan kunde inte laddas",
}) {
  useEffect(() => {
    M.AutoInit();
  }, []);

  if (error instanceof ApiError) {
    title = "Något gick fel med API-lagret";
    description = "Sidan kunde inte laddas på grund av fel i servern";
  }

  return (
    <div className="generic-error-container">
      <h1>{title}</h1>
      <h4>{description}</h4>
      <ul className="collapsible">
        <li>
          <div className="collapsible-header">
            <i className="material-icons">add</i>Detaljer
          </div>
          <div className="collapsible-body">
            <p>
              {error.name}: {error.message}
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default GenericErrorPage;
