import { useContext, useEffect } from "react";
import { UsersContext } from "../../../Utils/contexts/UsersContext";
import { OpenModalById } from "../../../Utils/functions/OpenModalById";
import M from "materialize-css";
import { SuperUserContext } from "../../../Utils/contexts/SuperUserContext";

export const usersHook = (
  defaultUserMenuObjects = [],
  customerMenuObjects = [
    {
      icon: "edit",
      text: "Byt konsult",
      customOnClick: () => OpenModalById("MoveCustomerModal")
    },
  ]
) => {
  const {
    users,
    isLoaded,
    customers,
    selectedUser,
    setSelectedUser,
    fetchUsers,
    fetchUserCustomers,
    fetchAllCustomers,
    selectedCustomer,
    setSelectedCustomer,
    isFetchingCustomers,
    setIsFetchingCustomers,
    isFetchingUsers,
    setIsFetchingUsers,
    allCustomersMode,
    setAllCustomersMode
  } = useContext(UsersContext);

  const {
    currentUser
  } = useContext(SuperUserContext);


  useEffect(async () => {
    await fetchUsers();
    M.AutoInit();
    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, []);

  const onUserClick = (user) => {
    setSelectedUser(user.name);
    fetchUserCustomers(user.name);
    setAllCustomersMode(false);
  };

  const onCustomerClick = (customer) => {
    setSelectedCustomer(customer);
  };

  const switchAllCustomersMode = async () => {
    if(allCustomersMode){
      fetchUserCustomers(selectedUser);
      setAllCustomersMode(false);
    } else {
      setAllCustomersMode(true);
      fetchAllCustomers();
    }
  };

  return {
    users,
    onUserClick,
    menuObjects: defaultUserMenuObjects,
    customers,
    customerMenuObjects: customerMenuObjects,
    selectedUser,
    isLoaded,
    onCustomerClick,
    selectedCustomer,
    isFetchingCustomers,
    setIsFetchingCustomers,
    isFetchingUsers,
    setIsFetchingUsers,
    allCustomersMode,
    switchAllCustomersMode,
    currentUser
  };
};
