import React from "react";
import TraitComponentParent from "../../components/Traits/TraitComponentParent/TraitComponentParent";
// import "./TraitView.css";
import { QuestionProvider } from "../../Utils/contexts/QuestionContext";

const TraitView = () => {
  return (
    <div>
      <div className="Container-row">
        <div className="Container-column">
          <QuestionProvider>
            <TraitComponentParent />
          </QuestionProvider>
        </div>
      </div>
    </div>
  );
};

export default TraitView;
