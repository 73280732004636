import React, { useContext, useEffect } from "react";
import { ParticipantFormContext } from "../../../Utils/contexts/ParticipantFormContext";
import ParticipantFormQuestionaire from "../ParticipantFormQuestionaire/ParticipantFormQuestionaire";
import GDPRConsent from "../GDPRConsent/GDPRConsent";
import FormAccess from "../../Forms/FormAccess/FormAccess";
import { translation } from "../ParticipantFormTranslations";
import { useMediaQuery } from "react-responsive";
import ParticipantFormInformationPage from "../ParticipantFormInformationPage/ParticipantFormInformationPage";
// import ParticipantFormModal from "./ParticipantFormModal";
const ParticipantFormComponentParent = () => {

  const {
    acceptedGDPR,
    hasAcceptedGDPR,
    isLoading,
    fetchQuestions,
    closed,
    supportEmail,
    formLanguage,
    hasRead
  } = useContext(ParticipantFormContext);
  
  useEffect(() => {
    hasAcceptedGDPR();
  }, []);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    if (acceptedGDPR) {
      fetchQuestions();
    }
  }, [acceptedGDPR]);

  const componentToRender = () => {
    return isLoading ? <></> :
      closed ? <FormAccess isTabletOrMobile={isTabletOrMobile} message={translation.formAccess[formLanguage].closed} isDone={false} language={formLanguage} email={supportEmail}/> :
        (acceptedGDPR === null) ? <FormAccess isTabletOrMobile={isTabletOrMobile} message={translation.formAccess[formLanguage].denied} isDone={false} language={formLanguage} email={supportEmail}/> :
          !acceptedGDPR ? <GDPRConsent/> : 
            hasRead ? <ParticipantFormQuestionaire /> :
              <ParticipantFormInformationPage isTabletOrMobile={isTabletOrMobile}/>;
  };


  return (
    <> 
      {componentToRender()}
    </>
  );
};

export default ParticipantFormComponentParent;
