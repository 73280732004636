import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import { Auth, Hub } from "aws-amplify";
import React, { useEffect, useState, useContext } from "react";
import { Route } from "react-router";
import RouteClosed from "./RouteClosed";
import RouteConsultant from "./RouteConsultant";
import RouteSuperUser from "./RouteSuperUser";
import { SuperUserContext } from "./Utils/contexts/SuperUserContext";


const ProtectedApp = () => {
  const {
    loadUserData,
    routerState,
    setCurrentUser,
    setLoggedIn,
    setIsSuperUser,
    setRouterState
  } = useContext(SuperUserContext);

  const updateRoutingPermissions = (userGroups) => {
    if (userGroups.includes("SuperUser")) {
      setIsSuperUser(true);
      setRouterState("SuperUser");
    } else if (userGroups.includes("Consultant")) {
      setRouterState("Consultant");
    }
  };

  const handleAuthStateChange = (state) => {
    if (state === "signin") {
      setRouterState("Closed");
    }
  };

  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signIn":
        const { payload } = data;
        const userGroups =
          payload.data.signInUserSession.idToken.payload["cognito:groups"];
        setLoggedIn(true);
        setCurrentUser( payload.data["username"]);
        updateRoutingPermissions(userGroups);
        break;
      case "signOut":
        setLoggedIn(false);
        setRouterState("Closed");
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <AmplifyAuthContainer>
      <AmplifyAuthenticator handleAuthStateChange={handleAuthStateChange}>
        <AmplifySignIn slot="sign-in" hideSignUp={true}></AmplifySignIn>

        {routerState === "SuperUser" && (
          <Route path="/" component={RouteSuperUser} />
        )}
        {routerState === "Consultant" && (
          <Route path="/" component={RouteConsultant} />
        )}
        {routerState === "Closed" && <Route path="/" component={RouteClosed} />}
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  );
};

export default ProtectedApp;
