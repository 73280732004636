import React, { createRef, useContext, useEffect, useLayoutEffect, useState } from "react";
import M from "materialize-css";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import RemoveParticipant from "../../../Utils/functions/RemoveParticipant";
import { CustomerContext } from "../../../Utils/contexts/CustomerContext";
import DropDownMenu from "../../General/DropDownMenu/DropDownMenu";

const ParticipantListItem = ({participant, index, createNotification}) => {

  const {
    projects,
    selectedCustomerId,
    selectedProjectId,
    selectedParticipantId, 
    setSelectedParticipantId,
    fetchProjects
  } = useContext(CustomerContext);
  const project = projects.find(project => project.id === selectedProjectId);
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = createRef();
  useEffect(() => {
    let elems = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });
    M.AutoInit();

    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, []);

  useLayoutEffect(() => {
    var elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, {enterDelay: 700});  
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    }
  }, [ref]);



  const selectParticipant = () => {
    setSelectedParticipantId(index);
  };
  
  const submitRemoveParticipantForm = async () => {
    try {
      if(selectedParticipantId === index) setSelectedParticipantId(-1);
      document.getElementById(`removeParticipantModal${selectedProjectId}_${selectedCustomerId}_${index}`).M_Modal.close();
      await RemoveParticipant(project, index);
      createNotification(`"${participant.firstName} ${participant.lastName}" har raderats.`, "success");
      await fetchProjects(selectedCustomerId);
      if(selectedParticipantId > index) setSelectedParticipantId(selectedParticipantId-1);
    } catch (error) {
      throw new Error("Could not remove participant. " + JSON.stringify(error.message));
    }
  };

  const openUpdateParticipantForm = (e) => {
    e.stopPropagation();
    setSelectedParticipantId(index);
    document.getElementById("updateParticipantModal").M_Modal.open();
    document.getElementById("updateParticipantInfoEmail").focus();
  };

  const openRemoveParticipantForm = (e) => {
    e.stopPropagation();
    document.getElementById(`removeParticipantModal${selectedProjectId}_${selectedCustomerId}_${index}`).M_Modal.open();
  };

  const menuObjects = [
    {
      text:"Ändra",
      icon:"edit",
      customOnClick: openUpdateParticipantForm
    },
    {
      text:"Ta bort",
      icon:"delete",
      customOnClick: openRemoveParticipantForm
    }
  ];
  return (
    <div className="Item-card" id={`participant-list-${index}`} >
      <div className={(index === selectedParticipantId) ? "body-green" : "body"} onClick={selectParticipant}>

        <div className="Body-container">
          <p className={showTooltip ? "tooltipped" : ""} data-position="top" data-tooltip={`${participant.firstName} ${participant.lastName}`} ref={ref}>{`${participant.firstName} ${participant.lastName}`}</p>
          <DropDownMenu ulId={participant.email} menuObjects={menuObjects} />
        </div>
      </div>

      <FinalFormModal
        //Must match data-targer of trigger
        modalHTMLIdentifier={`removeParticipantModal${selectedProjectId}_${selectedCustomerId}_${index}`}
        //variable amount of input fields as array
        inputFields={[]}
        //must match name of headers in input field array
        initialValues={[]}
        //u know wat onsubmit does lmao
        onClick={(e) => e.stopPropagation()}
        onSubmit={submitRemoveParticipantForm}

        submitButtonName={"Ja"}
        cancelButtonName={"Nej"}
        formInfo={{
          header: "Vill du ta bort " + participant.firstName + " " + participant.lastName + "?"
        }}
      />


      

    </div >
  );
};

export default ParticipantListItem;
