import { Amplify, Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";
import "./SignOutButton.css";

const SignOutButton = () => {
  const [currentUser, setCurrentUser] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const refreshPage = () => {
    window.location.reload();
  };

  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signIn":
        const { payload } = data;
        setCurrentUser(payload.data.username);
        setIsLoaded(true);
        break;
      case "signOut":
        setCurrentUser("");
        setIsLoaded(false);
        break;
      default:
        break;
    }
  });

  const func = async () => {
    let user = "";
    try {
      user = (await Auth.currentAuthenticatedUser()).signInUserSession
        .accessToken.payload.username;
    } catch (error) {}

    setCurrentUser(user);
    if (user !== "") {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    func();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <button
            className="Sign-out-button"
            onClick={() => {
              Amplify.Auth.signOut();
              refreshPage();
            }}
          >
            Logga ut:
          </button>
          <span className="Cursor-default">{currentUser}</span>
        </>
      ) : (
        <div />
      )}
    </>
  );
};

export default SignOutButton;
