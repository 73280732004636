import React, { useContext } from "react";
import "../../../Utils/CSS/cards.css";
import { EconomyContext } from "../../../Utils/contexts/EconomyContext";
import Preloader from "../../General/Preloader/Preloader";
import DatePick from "../DatePick/DatePick";
import GetCostReport from "../../../Utils/functions/GetCostReport";
import { useSnackbar } from "notistack";

const EconomyComponentParent = () => {

  const {
    isLoading, setIsLoading
  } = useContext(EconomyContext);

  const { enqueueSnackbar } = useSnackbar();

  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, { variant });
  };

  const submit = async (start, end) => {
    setIsLoading(true);
    try{
      let reportUrl = await GetCostReport(start, end);
      download(reportUrl);
      setIsLoading(false);
    } catch (e){
      createNotification("Kunde inte skapa kostnadsrapport.","error");
      setIsLoading(false);
    }
  };

  const download = (url) => {
    var tempElement = document.createElement("a");
    tempElement.href = url;
    tempElement.setAttribute("download", "costReport");
    tempElement.click();
  };

  return (
    <div className="Main-body" style={{display: "flex", justifyContent: "center"}}>
      <div className="Item-list-container" style={{padding: 0, width: "50%"}}>
        {isLoading ? <Preloader size="big"/>:
          (
            <div className="Trait-title" style={{width: "100%", textAlign: "center"}}>
              <h1 className="Trait-title-text" style={{textAlign: "center"}}>Ladda ner en kostnadsrapport</h1>
              <DatePick submit={submit}/>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default EconomyComponentParent;
