import ParticipantFormComponentParent from "../../components/ParticipantForm/ParticipantFormComponentParent/ParticipantFormComponentParent";
import { ParticipantFormProvider } from "../../Utils/contexts/ParticipantFormContext";

const ParticipantFormView = () => {

  return (
    <ParticipantFormProvider>
      <ParticipantFormComponentParent />
    </ParticipantFormProvider>
  );
};

export default ParticipantFormView;
