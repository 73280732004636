import React, { createContext, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listQuestions, listTraits } from "../../graphql/queries";
import { changeOrderNumber, updateQuestion, createQuestion, deleteQuestion} from "../../graphql/mutations";
import { useSnackbar } from "notistack";

export const QuestionContext = createContext();

export const QuestionProvider = props => {
  const { enqueueSnackbar } = useSnackbar();
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [traits, setTraits] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isViewingAllQuestions, setIsViewingAllQuestions] = useState(true);
  const [language, setLanguage] = useState("SE");
  const [filter, setFilter] = useState("");
  const [sequence, setSequence] = useState([]);
  const [selectedTraitId, setSelectedTraitId] = useState("");

  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, { variant });
  };

  const fetchQuestions = async () => {
    const questionData = await API.graphql(
      graphqlOperation(listQuestions, { limit: 1000 })
    );
    const questions = questionData.data.listQuestions.items.sort((a, b) => {
      return  a.orderNumber - b.orderNumber;
    });
    setQuestions(questions);
  };

  const fetchTraits = async () => {
    const traitsData = (await API.graphql(graphqlOperation(listTraits))).data.listTraits.items;
    setTraits(traitsData.sort((a, b) => a.symbol.localeCompare(b.symbol)));
    return traitsData.sort((a, b) => a.symbol.localeCompare(b.symbol)); 
  };

  const updateQuestionRequest = async (orderNumber, id, trait, translations, scores, answerSequence) => {
    try {
      await API.graphql(graphqlOperation(updateQuestion, {id , trait, translations, scores, answerSequence}));  
      document.getElementById("questionsModal").M_Modal.close();
      await fetchQuestions();
      createNotification(`Fråga "${orderNumber}" har uppdaterats.`, "success");
    } catch (err) {
      createNotification(`Fråga "${orderNumber}" kunde inte uppdateras.`, "error");
    }
  };

  const createQuestionRequest = async (trait, translations, scores, answerSequence) => {
    try {
      await API.graphql(graphqlOperation(createQuestion, { trait, translations, scores, answerSequence}));  
      document.getElementById("createQuestionModal").M_Modal.close();
      await fetchQuestions();
      createNotification("Fråga har lagts till.", "success");
    } catch (err) {
      createNotification("Frågan kunde inte skapas.", "error");
    }
  };

  const deleteQuestionRequest = async (orderNumber, id) => {
    try {
      setSelectedQuestion();
      await API.graphql(graphqlOperation(deleteQuestion, { id }));  
      document.getElementById("DeleteQuestionModal").M_Modal.close();
      await fetchQuestions();
      createNotification(`Fråga "${orderNumber}" har tagits bort.`, "success");
    } catch (err) {
      createNotification(`Fråga "${orderNumber}" kunde inte tas bort.`, "error");
    }
  };
  const changeOrder = async (id, newOrderNumber) => {
    try {
      const response = await API.graphql(graphqlOperation(changeOrderNumber, {id, newOrderNumber}));
    } catch (err) {
    }
  };
  const value = {
    questions, setQuestions,
    traits, setTraits,
    sequence, setSequence,
    filter, setFilter, createQuestionRequest,
    selectedTraitId, setSelectedTraitId,
    language, setLanguage, updateQuestionRequest,
    selectedQuestion, setSelectedQuestion,
    fetchTraits, fetchQuestions, changeOrder,
    isLoaded, setIsLoaded, deleteQuestionRequest,
    isViewingAllQuestions, setIsViewingAllQuestions
  };

  return (
    <QuestionContext.Provider value={value}>
      {props.children}
    </QuestionContext.Provider>
  );
};
