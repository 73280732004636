import { Redirect, Route, Switch } from "react-router";
import {
  CUSTOMER_VIEW,
  FORM_VIEW,
  QUESTION_VIEW,
  REPORT_VIEW,
  TRAIT_VIEW,
  USER_VIEW,
  ECONOMY_VIEW,
  HELP_VIEW
} from "./constants/Routes";
import CustomerView from "./pages/CustomerView/CustomerView";
import FormView from "./pages/FormView/FormView";
import NotFound from "./pages/NotFound/NotFound";
import QuestionView from "./pages/QuestionView/QuestionView";
import ReportView from "./pages/ReportView/ReportView";
import TraitView from "./pages/TraitsView/TraitView";
import UserView from "./pages/UserView/UserView";
import EconomyView from "./pages/EconomyView/EconomyView";
import HelpView from "./pages/HelpView/HelpView";

export default function RouteSuperUser() {
  return (
    <Switch>
      <Route strict exact path={`/${QUESTION_VIEW}`} component={QuestionView} />
      <Redirect strict exact path={`/${QUESTION_VIEW}/`} to={`/${QUESTION_VIEW}`} component={QuestionView} />
      <Route strict exact path={`/${TRAIT_VIEW}`} component={TraitView} />
      <Redirect strict exact path={`/${TRAIT_VIEW}/`} to={`/${TRAIT_VIEW}`} component={TraitView} />
      <Route strict exact path={`/${USER_VIEW}`} component={UserView} />
      <Redirect strict exact from={`/${USER_VIEW}/`} to={`/${USER_VIEW}`} component={UserView} />
      <Route strict exact path={`/${REPORT_VIEW}`} component={ReportView} />
      <Route strict exact path={`/${FORM_VIEW}`} component={FormView} />
      <Route strict exact path={`/${CUSTOMER_VIEW}`} component={CustomerView} />
      <Redirect strict exact from={`/${REPORT_VIEW}/`} to={`/${REPORT_VIEW}`} component={ReportView} />
      <Redirect strict exact from={`/${FORM_VIEW}/`} to={`/${FORM_VIEW}`} component={FormView} />
      <Redirect strict exact from={`/${CUSTOMER_VIEW}/`} to={`/${CUSTOMER_VIEW}`} component={CustomerView}/>
      <Redirect exact strict from={"/"} to={`/${CUSTOMER_VIEW}`} component={CustomerView} />
      <Route strict exact path={`/${ECONOMY_VIEW}`} component={EconomyView} />

      <Route strict exact path={`/${HELP_VIEW}`} component={HelpView} />
      <Redirect strict exact from={`/${HELP_VIEW}/`} to={`/${HELP_VIEW}`} component={HelpView}/>
      <Route component={NotFound} />
    </Switch>
  );
}
