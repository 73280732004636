import React, { createContext, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getQuestionsInForm, isGDPRAccepted } from "../../graphql/queries";
import { submitForm, saveParticipantAnswer, acceptGDPRTerms } from "../../graphql/mutations";

export const ParticipantFormContext = createContext();
export const ParticipantFormProvider = props => {
  
  const slug = window.location.href.split("/").slice(-1)[0];
  const participantId = slug.split("_")[1];
  const formId = slug.split("_")[0];
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [forbidden, setForbidden] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [closed, setClosed] = useState(false);
  const [acceptedGDPR, setAcceptedGDPR] = useState();
  const [deniedGDPR, setDeniedGDPR] = useState(false);
  const [denied, setDenied] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const supportEmail = "analysis@adviser-partner.se";
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [questionsAnsweredList, setQuestionsAnsweredList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [prevPage, setPrevPage] = useState(1);
  const [pageState, setPageState] = useState([]);
  const [visitedPages, setVisitedPages] = useState([]);
  const [formLanguage, setFormLanguage] = useState("SE");
  const [hasRead, setHasRead] = useState(false);

  const fetchQuestions = async () => {
    try {
      setIsLoading(true);
      // const slug = window.location.href.split("/").slice(-1)[0];
      // setSlug(slug);

      const response = await API.graphql({
        query: getQuestionsInForm,
        variables: { slug: slug },
        authMode: "API_KEY",
      });
      const questions = response.data.getQuestionsInForm;

      setFormLanguage(questions[0].translations[0].language);

      calculatePageState(questions);
      const tempAnswers = {};
      for(const question of questions){
        tempAnswers[question.id] = question.selectedAnswer;
      }
      setAnswers(tempAnswers);
      setQuestions(questions);
      setIsLoading(false);
    } catch (err) {
      if (!err.errors) {
        setGenericError(true);
        setIsLoading(false);
        return;
      }
      switch (err.errors[0].message.toLowerCase()) {
        case "forbidden":
          setForbidden(true);
          break;
        case "access denied":
          setDenied(true);
          break;
        case "method not allowed":
          setClosed(true);
          break;
        case "bad request":
          setDenied(true);
          break;
        default:
          throw new Error(err.errors[0].message);
          break;
      }
      setIsLoading(false);
    }
  };

  const hasAcceptedGDPR = async () => {
    setIsLoading(true);
    try {
      const response = (await API.graphql({
        query: isGDPRAccepted,
        variables: {
          participantId
        },
        authMode: "API_KEY",
      })).data.isGDPRAccepted;
      setAcceptedGDPR(response);
    } catch (e) {
      if(e.errors[0].message.includes("Method Not Allowed")){
        setClosed(true);
      } else {
        setAcceptedGDPR(null);
      }
    }
    setIsLoading(false);
  };

  const saveAnswers = async (event) => {
    
    const [questionId, answer] = event.target.value.split("_");
    const copy = JSON.parse(JSON.stringify(answers));
    copy[questionId] = parseInt(answer);
    setAnswers(copy);
    await API.graphql({
      query: saveParticipantAnswer,
      variables: {
        questionId: questionId,
        answer: answer,
        participantId
      },
      authMode: "API_KEY",
    }).then(response => {
      if(!response.data.saveParticipantAnswer.includes("ok")){
        copy[questionId] = 0;
        setAnswers(copy);
      } 
    }).catch(error => {
      if (error.errors[0].message === "Method Not Allowed") {
        window.location.reload();
      }
      copy[questionId] = 0;
      setAnswers(copy);
    });
  };

  const submitFormService = async (values) => {
    try {
      const response = (
        await API.graphql({
          query: submitForm,
          variables: {
            slug,
          },
          authMode: "API_KEY",
        })
      ).data.submitForm;

      if (!response === "ok") {
      } else {
        setSubmitted(true);
        document.getElementById("confirmSubmitModal").M_Modal.close();
      }
    } catch (error) {
      if (error.errors[0].message === "Method Not Allowed") {
        window.location.reload();
      }
    }
  };

  const acceptTermsOnClick = async () => {
    try{
      await API.graphql({
        query: acceptGDPRTerms,
        variables: {
          participantId: participantId,
        },
        authMode: "API_KEY",
      });
      setAcceptedGDPR(true);
    }catch(err){
      if (err.errors[0].message.toLowerCase() === "method not allowed") {
        window.location.reload();
      }
    }
  };



  // Partially-answered-pagination , Answered-pagination, Pagination-button-selected, Pagination-button-unselected
  // document.getElementById(`pag_${clickedPage}`).className += " Pagination-button-selected";
  // let pageButton = document.getElementById(`pag_${prevPage}`);
  // pageButton.className = pageButton.className.replace(" Pagination-button-selected", "");


  const calculatePageState = (questions) => {
    let newPageState = [];
    let initalCurrentPage = currentPage;
    let className = "";
    for (let i = 0; i < Math.ceil(questions.length / 10); i++) {
      const from = i * 10;
      const to = i * 10 + 10;
      
      if (questions.slice(from, to).find((q) => q.selectedAnswer === 0) === undefined) {
        newPageState.push("Answered-pagination");
      } else if (questions.slice(from, to).find((q) => q.selectedAnswer !== 0) === undefined) {
        className = "";
        if (initalCurrentPage === 0) {
          initalCurrentPage = i+1;
          className += " Pagination-button-selected";
        }
        newPageState.push(className);
      } else {
        className = "Partially-answered-pagination";
        if (initalCurrentPage === 0) {
          initalCurrentPage = i+1;
          className += " Pagination-button-selected";
        }
        newPageState.push(className);
      }
    }

    let newVisitedPages = [];
    for (let i = newPageState.length-1; i>=0; i--) {
      if (newPageState[i] !== "") {
        newVisitedPages[i] = true;
        for (let j = i-1; j>=0; j--) {
          newVisitedPages[j] = true;
        }
        break;
      } else {
        newVisitedPages[i] = false;
      }
    }
    setVisitedPages(newVisitedPages);
    if(initalCurrentPage === 0) initalCurrentPage = newPageState.length;
    setCurrentPage(initalCurrentPage);
    setPrevPage(initalCurrentPage);
   
    setPageState(newPageState);
  };

  const value = {
    slug,
    questions, setQuestions,
    forbidden, setForbidden,
    submitted, setSubmitted,
    closed, setClosed,
    acceptedGDPR, setAcceptedGDPR,
    deniedGDPR, setDeniedGDPR,
    denied, setDenied,
    genericError, setGenericError,
    supportEmail,
    allQuestionsAnswered, setAllQuestionsAnswered,
    questionsAnsweredList, setQuestionsAnsweredList,
    isLoading, setIsLoading,
    currentPage, setCurrentPage,
    prevPage, setPrevPage,
    pageState, setPageState,
    acceptTermsOnClick, 
    submitFormService,
    saveAnswers,
    fetchQuestions,
    hasAcceptedGDPR,
    calculatePageState,
    visitedPages,
    setVisitedPages,
    answers, setAnswers,
    formLanguage, setFormLanguage,
    hasRead, setHasRead
  };
  return (
    <ParticipantFormContext.Provider value={value}>
      {props.children}
    </ParticipantFormContext.Provider>
  );
};