import React, { createContext, useState, useContext } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { SuperUserContext } from "./SuperUserContext";
import {
  getCustomerProjects,
  getProjectForms,
  getFormParticipants,
  listCustomers,
} from "../../graphql/queries";
import {
  getAllCustomers,
  getUserCustomers,
} from "../../Utils/functions/api/usersApi";

export const FormContext = createContext();
export const FormProvider = (props) => {
  const [customers, setCustomers] = useState([]);
  const [myCustomers, setMyCustomers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [forms, setForms] = useState([]);
  const { currentUser } = useContext(SuperUserContext);
  const [participants, setParticipants] = useState([]);

  const [showOnlyMyCustomers, setShowOnlyMyCustomers] = useState(true);

  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(-1);
  const [selectedProjectId, setSelectedProjectId] = useState(-1);
  const [selectedParticipantId, setSelectedParticipantId] = useState(-1);
  const [selectedFormId, setSelectedFormId] = useState(-1);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
  const [isFetchingForms, setIsFetchingForms] = useState(false);
  const [invitationSwitch, setInvitationSwitch] = useState(false);
  const [isFetchingProjects, setIsFetchingProjects] = useState(false);
  const [isFetchingParticipants, setIsFetchingParticipants] = useState(false);
  const [filter, setFilter] = useState("");

  const fetchCustomers = async () => {
    try {
      setIsFetchingCustomers(true);
      const customerData = await getAllCustomers();
      const customers = customerData.data.listCustomers.items;
      setCustomers(customers);
      setSelectedProjectId(-1);
      setProjects([]);
      setSelectedParticipantId(-1);
      setParticipants([]);
      setSelectedFormId(-1);
      setForms([]);
      setIsFetchingCustomers(false);
    } catch (err) {
      setIsFetchingCustomers(false);
    }
  };

  const fetchUserCustomers = async (userId) => {
    try {
      setIsFetchingCustomers(true);
      const customerData = await getUserCustomers(userId);
      const customers = customerData.data.listCustomers.items;
      setCustomers(customers);
      setSelectedProjectId(-1);
      setProjects([]);
      setSelectedParticipantId(-1);
      setParticipants([]);
      setSelectedFormId(-1);
      setForms([]);
      setIsFetchingCustomers(false);
    } catch (err) {
      setIsFetchingCustomers(false);
    }
  };

  const toggleShowOnlyMyCustomers = () => {
    if (showOnlyMyCustomers) {
      fetchCustomers();
      setShowOnlyMyCustomers(false);
    } else {
      fetchUserCustomers(currentUser);
      setShowOnlyMyCustomers(true);
    }
  };

  const fetchProjects = async (customerId) => {
    try {
      setIsFetchingProjects(true);
      const projectData = await API.graphql(
        graphqlOperation(getCustomerProjects, { customerId })
      );
      const projects = projectData.data.getCustomerProjects;
      setProjects(projects);
      setIsFetchingProjects(false);
    } catch (err) {
      setIsFetchingProjects(false);
    }
  };

  const fetchForms = async (projectId) => {
    try {
      setIsFetchingForms(true);
      const formData = await API.graphql(
        graphqlOperation(getProjectForms, { projectId })
      );
      const forms = formData.data.getProjectForms;
      setForms(forms);
      setIsFetchingForms(false);
    } catch (err) {
      setIsFetchingForms(false);
    }
  };

  const fetchParticipants = async (formId) => {
    try {
      setIsFetchingParticipants(true);
      const participantData = await API.graphql(
        graphqlOperation(getFormParticipants, { formId })
      );
      const participants = participantData.data.getFormParticipants;
      setParticipants(participants);
      setIsFetchingParticipants(false);
    } catch (err) {
      setIsFetchingParticipants(false);
    }
  };

  const value = {
    customers: customers,
    setCustomers: setCustomers,
    isFetchingForms,
    setIsFetchingForms,
    invitationSwitch,
    setInvitationSwitch,
    projects: projects,
    setProjects: setProjects,

    myCustomers: myCustomers,
    setMyCustomers: setMyCustomers,
    allCustomers: allCustomers,
    setAllCustomers: setAllCustomers,
    showOnlyMyCustomers: showOnlyMyCustomers,
    toggleShowOnlyMyCustomers: toggleShowOnlyMyCustomers,
    forms: forms,
    setForms: setForms,

    participants: participants,
    setParticipants: setParticipants,

    isLoaded: isLoaded,
    setIsLoaded: setIsLoaded,

    selectedCustomerId: selectedCustomerId,
    setSelectedCustomerId: setSelectedCustomerId,
    selectedProjectId: selectedProjectId,
    setSelectedProjectId: setSelectedProjectId,
    selectedParticipantId: selectedParticipantId,
    setSelectedParticipantId: setSelectedParticipantId,
    selectedFormId: selectedFormId,
    setSelectedFormId: setSelectedFormId,

    fetchProjects: fetchProjects,
    fetchForms: fetchForms,
    fetchParticipants: fetchParticipants,

    isFetchingProjects: isFetchingProjects,
    setIsFetchingProjects: setIsFetchingProjects,
    isFetchingParticipants: isFetchingParticipants,
    setIsFetchingParticipants: setIsFetchingParticipants,
    filter: filter,
    setFilter: setFilter,
    fetchUserCustomers,
    isFetchingCustomers,
    setIsFetchingCustomers,
  };
  return (
    <FormContext.Provider value={value}>{props.children}</FormContext.Provider>
  );
};
