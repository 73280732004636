import ToggleListItem from "../../General/ToggleListItem/ToggleListItem";
import React, { useEffect } from "react";
import "./FilterBanner.css";

const FilterBanner = ({
  handleFilterChange,
  showOnlyMyCustomers,
  toggleShowOnlyMyCustomers,
  isSuperUser,
}) => {
  return (
    <div className="FilterBanner">
      <div className="search">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="input-field">
            <i className="material-icons prefix">search</i>
            <input
              autoFocus
              autoComplete="off"
              type="text"
              id="customerFilter"
              name="customerFilter"
              onChange={handleFilterChange}
            />
            <label htmlFor="customerFilter">
              Filtrera på kundnamn{isSuperUser && "/konsult"}
            </label>
          </div>
        </form>
      </div>
      {isSuperUser && (
        <div className="switch-container">
          <ToggleListItem
            dataTestId={"Customers-show-customers toggle"}
            onIcon={"remove_red_eye"}
            onText={"Visa alla kunder"}
            offIcon={"remove_red_eye"}
            offText={"Visa bara mina kunder"}
            toggleState={showOnlyMyCustomers}
            handleClick={toggleShowOnlyMyCustomers}
            position="initial"
          />
        </div>
      )}
    </div>
  );
};

export default FilterBanner;
