import { useContext } from "react";

import { ParticipantFormContext } from "../../../Utils/contexts/ParticipantFormContext";

const ParticipantFormQuestionListItem = ({question, index, order}) => {

  const map = {
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
    FOURTH: 4,
    FIFTH: 5
  };

  const {
    saveAnswers,
    answers
  } = useContext(ParticipantFormContext);

  return (
    <>
      <div key={`${question.id}_${index}`}>
        <label>
          <input
            name={`${question.id}`}
            className="with-gap"
            type="radio"
            value={`${question.id}_${index}`}
            onChange={saveAnswers}
            checked={answers[question.id] === index}
            id={`${question.id}_${index}`}
          />
          <span style={{ color: "black" }}>
            {question.translations[0].answerOptions[order[0]]}                            
          </span>
        </label>
      </div>
    </>        
  );
};

export default ParticipantFormQuestionListItem;