import React, { createContext, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  getCustomerProjects,
  getProjectForms,
  getFormParticipants,
} from "../../graphql/queries";
import {
  getAllCustomers,
  getUserCustomers,
} from "../../Utils/functions/api/usersApi";

export const ReportContext = createContext();
export const ReportProvider = (props) => {
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [forms, setForms] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(-1);
  const [selectedProjectId, setSelectedProjectId] = useState(-1);
  const [dropZoneForms, setDropZoneForms] = useState([]);
  const [generatingForm, setGeneratingForm] = useState(false);
  const [language, setLanguage] = useState("SE");
  const [nameOfReport, setNameOfReport] = useState("");
  const [invalidName, setInvalidName] = useState(false);
  const [minimumFormsError, setMinimumFormsError] = useState(false);
  const [formCopies, setFormCopies] = useState({});
  const [isFetchingForms, setIsFetchingForms] = useState(false);
  const [isFetchingProjects, setIsFetchingProjects] = useState(false);
  const [showOnlyMyCustomers, setShowOnlyMyCustomers] = useState(true);
  const [myCustomers, setMyCustomers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [currentUser, setCurrentUser] = useState("");

  const fetchCustomers = async () => {
    const customerData = await getAllCustomers();
    const updatedCustomers = customerData.data.listCustomers.items;
    const newCustomers = updatedCustomers.sort((a, b) =>
      a.companyName.localeCompare(b.companyName, "en", { sensitivity: "base" })
    );
    setAllCustomers(newCustomers);
  };

  const fetchMyCustomers = async () => {
    const customerData = await getUserCustomers(currentUser);
    const customersList = customerData.data.listCustomers.items;
    const newCustomers = customersList.sort((a, b) =>
      a.companyName.localeCompare(b.companyName, "en", { sensitivity: "base" })
    );
    setCustomers(newCustomers);
    setMyCustomers(newCustomers);
  };

  const toggleShowOnlyMyCustomers = () => {
    if (showOnlyMyCustomers) {
      setCustomers(allCustomers);
      setShowOnlyMyCustomers(false);
    } else {
      setCustomers(myCustomers);
      setShowOnlyMyCustomers(true);
    }
  };

  const fetchProjects = async (customerId) => {
    const projectData = await API.graphql(
      graphqlOperation(getCustomerProjects, { customerId })
    );
    const updatedProjects = projectData.data.getCustomerProjects;
    setProjects(updatedProjects);
  };
  const fetchForms = async (projectId) => {
    const formData = await API.graphql(
      graphqlOperation(getProjectForms, { projectId })
    );
    const updatedFormData = formData.data.getProjectForms;

    const participantPromises = [];
    for (const form of updatedFormData) {
      participantPromises.push(
        API.graphql(graphqlOperation(getFormParticipants, { formId: form.id }))
      );
    }
    const answeredForms = [];
    (await Promise.all(participantPromises)).map((participants) => {
      for (const participant of participants.data.getFormParticipants) {
        if (
          ["SUBMITTED_AND_PARTIALLY_ANSWERED", "SUBMITTED"].includes(
            participant.status
          )
        ) {
          answeredForms.push(
            updatedFormData.find((f) => f.id === participant.formId)
          );
          break;
        }
      }
    });
    const newFormCopies = { ...formCopies };
    newFormCopies[projectId] = answeredForms;
    setFormCopies(newFormCopies);
  };

  const value = {
    customers,
    setCustomers,
    fetchCustomers,
    fetchMyCustomers,
    fetchForms,
    fetchProjects,
    formCopies,
    setFormCopies,
    projects,
    setProjects,
    forms,
    setForms,
    isLoaded,
    setIsLoaded,
    dropZoneForms,
    setDropZoneForms,
    generatingForm,
    setGeneratingForm,
    language,
    setLanguage,
    invalidName,
    setInvalidName,
    selectedCustomerId,
    setSelectedCustomerId,
    selectedProjectId,
    setSelectedProjectId,
    nameOfReport,
    setNameOfReport,
    minimumFormsError,
    setMinimumFormsError,
    isFetchingForms,
    setIsFetchingForms,
    currentUser,
    setCurrentUser,
    showOnlyMyCustomers,
    toggleShowOnlyMyCustomers,
    isFetchingProjects,
    setIsFetchingProjects,
  };
  return (
    <ReportContext.Provider value={value}>
      {props.children}
    </ReportContext.Provider>
  );
};
