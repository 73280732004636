import React, { useContext } from "react";
import { Form, Field } from "react-final-form";
import { QuestionContext } from "../../../Utils/contexts/QuestionContext";
import { ModalInput } from "../../General/ModalInput/ModalInput";
import ModalButton from "../../General/ModalButton/ModalButton";
import { validator } from "../../../Utils/functions/inputFieldValidation";

const AdminTraitForm = (props) => {
  const { traits, selectedTraitId } = useContext(QuestionContext);

  const traitFormulationSweInput = {
    header: "Kategorinamn",
    id: "addSwedishCategoryName",
    divName: "Trait-formulation-input",
    type: "text",
    labelName: "Text-left",
    style: { display: "flex", flexDirection: "column" },
  };

  const traitIdealSceneSweInput = {
    header: "Idealt scenario",
    id: "addSwedishIdealScene",
    divName: "Trait-idealScene-input",
    type: "text",
    labelName: "Text-left",
    style: { display: "flex", flexDirection: "column" },
  };
  const traitFormulationEnInput = {
    header: "Area name",
    id: "addEnglishCategoryName",
    divName: "Trait-formulation-input",
    type: "text",
    labelName: "Text-left",
    style: { display: "flex", flexDirection: "column" },
  };

  const traitIdealSceneEnInput = {
    header: "Ideal scene",
    id: "addEnglishIdealScene",
    divName: "Trait-idealScene-input",
    type: "text",
    labelName: "Text-left",
    style: { display: "flex", flexDirection: "column" },
  };

  const init = {};

  {
    selectedTraitId !== 0 &&
      traits
        .find((trait) => trait.id === selectedTraitId)
        ?.name.map((name) => {
          const traitName = name.language.includes("SE")
            ? "Kategorinamn"
            : "Area name";
          init[`${traitName}`] = name.value;
        });
  }
  {
    selectedTraitId !== 0 &&
      traits
        .find((trait) => trait.id === selectedTraitId)
        ?.idealScene.map((idealScene) => {
          const scene = idealScene.language.includes("SE")
            ? "Idealt scenario"
            : "Ideal scene";
          init[`${scene}`] = idealScene.value;
        });
  }

  return (
    <Form
      id="questionUpdateForm"
      onSubmit={props.onSubmit}
      initialValues={init}
      keepDirtyOnReinitialize={false}
      render={({ handleSubmit, submitting, form }) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h5
              className="modal-title"
              style={{ textAlign: "left" }}
              id={props.modalHTMLIdentifier + "modalHeader"}
            >
              {selectedTraitId === 0 ? "Lägg till kategori" : "Ändra kategori"}
            </h5>
            <div>
              <i
                className="material-icons modal-close Close-cross-button"
                onClick={() => {
                  form.reset();
                  props.onCancel && props.onCancel();
                }}
              >
                close
              </i>
            </div>
          </div>
          {selectedTraitId === 0 ? (
            <div style={{ display: "flex", color: "rgb(219, 75, 26)" }}>
              <i className="material-icons">warning</i>
              <p
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "1%",
                }}
              >
                När en kategori är tillagd går den enbart att ändra, det är inte
                möjligt att ta bort den.
              </p>
            </div>
          ) : (
            <></>
          )}
          <form onSubmit={(values) => handleSubmit(values, form)}>
            <div className="Input-container">
              <p
                style={{
                  margin: "2% 0 2% 0",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Svenska
              </p>
              <Field name={traitFormulationSweInput.header}>
                {({ input, meta }) => (
                  <ModalInput
                    inputField={traitFormulationSweInput}
                    labelName="input-label"
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <Field name={traitIdealSceneSweInput.header}>
                {({ input, meta }) => (
                  <ModalInput
                    inputField={traitIdealSceneSweInput}
                    labelName="input-label"
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <p
                style={{
                  margin: "2% 0 2% 0",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Engelska
              </p>
              <Field name={traitFormulationEnInput.header}>
                {({ input, meta }) => (
                  <ModalInput
                    inputField={traitFormulationEnInput}
                    labelName="input-label"
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <Field name={traitIdealSceneEnInput.header}>
                {({ input, meta }) => (
                  <ModalInput
                    inputField={traitIdealSceneEnInput}
                    labelName="input-label"
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
            </div>
            <div>{props.bodyText}</div>
            <div className="modal-footer border-top-0 Footer-container">
              <ModalButton
                buttonName={props.cancelButtonName}
                buttonType={"button"}
                disabled={submitting}
                onCancel={form.reset}
                buttonTrigger="modal-close"
              />
              <ModalButton
                buttonName={props.submitButtonName}
                buttonType={"submit"}
                disabled={submitting}
                buttonTrigger=""
              />
            </div>
          </form>
        </>
      )}
    />
  );
};

export default AdminTraitForm;
