import React, { useContext, useEffect } from "react";
import ParticipantInfoListItem from "../ParticipantInfoListItem/ParticipantInfoListItem";
import M from "materialize-css";
import { CustomerContext } from "../../../Utils/contexts/CustomerContext";

const ParticipantInfoList = () => {

  const {
    projects,
    selectedProjectId,
    selectedParticipantId,

  } = useContext(CustomerContext);
  const participant = projects.find(project => project.id === selectedProjectId)?.participants[selectedParticipantId];
  useEffect(() => {
    let elems = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });
  }, [projects]);

  return (
    <div className="Card-list">
      <p className="header">Deltagaruppgifter</p>
      <div className="body">
        <div className="Items Card-min-width">
          {participant ? <>
            <ParticipantInfoListItem
              description={"Förnamn"}
              text={participant.firstName}
            />
            <ParticipantInfoListItem
              description={"Efternamn"}
              text={participant.lastName}
            />
            <ParticipantInfoListItem
              description={"Email"}
              text={participant.email}
            />
            <ParticipantInfoListItem
              description={"Titel"}
              text={participant.title}
            /> </> : <></>}
        </div> 
      </div>
    </div>
  );
};

export default ParticipantInfoList;
