import React, { useState, useEffect, useContext } from "react";
import ListItem from "../ListItem/ListItem";
import "./List.css";
import AddListItem from "../General/AddListItem/AddListItem";
import Preloader from "../General/Preloader/Preloader";
import ToggleListItem from "../General/ToggleListItem/ToggleListItem";
import { UsersContext } from "../../Utils/contexts/UsersContext";

const List = ({
  styling,
  isFetching,
  listName,
  listItems,
  selectedUser,
  onListItemClick = (item) => { },
  menuObjects = null,
  listButtons = null,
  initialSelectedListItemName = null,
}) => {
  
  const [selectedListItem, setSelectedListItem] = useState(initialSelectedListItemName);
  useEffect(() => { setSelectedListItem(initialSelectedListItemName); }, [initialSelectedListItemName]);
  useEffect(() => { }, [isFetching]);
  const handleListItemClick = (index, listItem) => {
    onListItemClick(listItem);
    setSelectedListItem(listItem.name);
  };

  const {
    newUser
  } = useContext(UsersContext);

  useEffect(() => {
    document.getElementById(`user-item-${selectedUser}`)?.scrollIntoView();
    
  }, [newUser]);

  const openAddUserModal = () => {
    try{
      document.getElementById("AddUserModal").M_Modal.open();
      document.getElementById("Email").focus();
    }catch(e){

    }
  };



  return (
    <div className="Card-list" style={{height: "100%"}}>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <p className="header">{listName}</p>
        {listButtons &&
          listButtons.map((listButton, index) => (
            listButton.toggleProperties &&
                <ToggleListItem
                  dataTestId={listButton.toggleProperties.dataTestId}
                  onIcon={listButton.toggleProperties.onIcon}
                  onText={listButton.toggleProperties.onText}
                  offIcon={listButton.toggleProperties.offIcon}
                  offText={listButton.toggleProperties.offText}
                  toggleState={!listButton.toggleProperties.toggleState}
                  handleClick={listButton.toggleProperties.handleClick}
                  position="initial"
                  key={index}
                />
          ))}
      </div>
      

      <div className="body">
        <div className="Items User-min-width" style={styling}>
          {isFetching ? <Preloader /> : listItems.map((listItem, index) => (
            <ListItem
              key={`${listName}-item-${index}`}
              name={listItem.name}
              nameIcon={listItem.nameIcon}
              index={`${listName}-${index}`}
              isSelected={selectedUser !== undefined ? selectedUser === listItem.name : listItem.name === selectedListItem}
              onListItemClick={(index) => handleListItemClick(index, listItem)}
              menuObjects={menuObjects}
              uniqueMenuObjects={listItem.uniqueMenuObjects}
              listName={listName}
            />
          ))}
        </div>

        {listButtons &&
          listButtons.map((listButton, index) => (
            listButton.toggleProperties ?
              <div key={index}></div> :
              <AddListItem
                onClick={openAddUserModal}
                dataTestId={listButton.modalProperties.dataTestId}
                icon={listButton.icon}
                text={listButton.text}
                key={index}
              />
          ))}
      </div>
    </div>
  );
};

export default List;
