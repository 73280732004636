import React, { createContext, useState } from "react";

export const EconomyContext = createContext();
export const EconomyProvider = props => {
  const [isLoading, setIsLoading] = useState(false);

  const value = {
    isLoading, setIsLoading,
  };
  return (
    <EconomyContext.Provider value={value}>
      {props.children}
    </EconomyContext.Provider>
  );
};