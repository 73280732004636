import Amplify from "aws-amplify";
import React, { useState } from "react";
import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";
import awsExports from "./aws-exports";
import ParticipantFormView from "./pages/ParticipantFormView/ParticipantFormView";
import ProtectedApp from "./ProtectedApp";
import { SuperUserProvider } from "./Utils/contexts/SuperUserContext";

import SignOutButton from "./components/General/SignOutButton/SignOutButton";
import MenuBar from "./components/General/MenuBar/MenuBar";
Amplify.configure(awsExports);

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Redirect
          strict
          exact
          from="/participantform/"
          to="/participantform"
          component={ParticipantFormView}
        />
        <Route path="/participantform" component={ParticipantFormView} />
        <SuperUserProvider>
          <nav>
            <div className="Nav-container Min-width">
              <MenuBar />
              <div className="Sign-out">
                <SignOutButton />
              </div>
            </div>
          </nav>
          <Route path="/" component={ProtectedApp} />
        </SuperUserProvider>
      </Switch>
    </div>
  );
};
// export default withAuthenticator(App);
export default App;
