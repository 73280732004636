import { API, graphqlOperation } from "aws-amplify";
import { deleteProject } from "../../graphql/mutations";

const RemoveProject = async (projectId) => {
  try {
    await API.graphql(
      graphqlOperation(deleteProject, {
        id: projectId
      })
    );
  } catch (error) {
    throw new Error("InternalServerError");
  }
};

export default RemoveProject;

