import React, { useContext } from "react";
import "../../General/FinalFormModal/FinalFormModal.css";
import "../../Questions/FinalFormQuestionModal/FinalFormQuestionModal.css";
import AdminTraitForm from "./AdminTraitForm";
import { QuestionContext } from "../../../Utils/contexts/QuestionContext";

const FinalFormTraitModal = (props) => {
  const { selectedTraitId } = useContext(QuestionContext);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      id={props.modalHTMLIdentifier}
      data-testid={props.modalHTMLIdentifier}
      className="modal Q-modal backdrop-static"
    >
      <div
        // className="modal fade"
        id={props.modalHTMLIdentifier + "dialog"}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
            </div>
            <AdminTraitForm
              submitButtonName={props.submitButtonName}
              cancelButtonName={props.cancelButtonName}
              formInfo={props.formInfo}
              inputFields={props.inputFields}
              onSubmit={props.onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalFormTraitModal;
