import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import adviserPartnerLogo from "./assets/adviser-partner-logo.png";
import "./Utils/materialize/materialize.min.css";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { BrowserRouter } from "react-router-dom";
import GlobalErrorBoundary from "./GlobalErrorBoundary";
import { SnackbarProvider } from "notistack";

Amplify.configure(awsExports);
ReactDOM.render(
  <BrowserRouter>
    <div
      className="Min-width"
      style={
        window.location.pathname.split("/")[1] === "participantform"
          ? { display: "none" }
          : {}
      }
    >
      <div className="Container-header" id="Start">
        <div>
          <h1 className="Header-title">Sales Health Check</h1>
        </div>
      </div>
      <div className="Adviser-partner-logo">
        <img alt="logo" src={adviserPartnerLogo}></img>
      </div>
    </div>
    <GlobalErrorBoundary>
      <SnackbarProvider
        preventDuplicate={true}
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <App />
      </SnackbarProvider>
    </GlobalErrorBoundary>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
