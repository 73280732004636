import { API, graphqlOperation } from "aws-amplify";
import { updateProject } from "../../graphql/mutations";

const RemoveParticipant = async (project, selectedParticipantId) => {
  try {
    project.participants.splice(selectedParticipantId,1);
    await API.graphql(
      graphqlOperation(updateProject, {
        id: project.id,
        projectName: project.projectName,
        participants: project.participants
      })
    );
  } catch (error) {
    throw new Error("InternalServerError");
  }
};

export default RemoveParticipant;
