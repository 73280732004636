import React from "react";
import "./ToggleListItem.css";

const ToggleListItem = ({ dataTestId, toggleState, onIcon, offIcon, onText, offText, handleClick, position }) => {
  return (
    <div className="switch" style={{position: position}}>
      <label >
        {/* Visa mina kunder */}
        <input type="checkbox" checked={!toggleState} onClick={()=> {handleClick();}} onChange={()=>{}}/>
        <span className="lever"></span>
        Visa alla kunder
      </label>
    </div>
    // {/* <div className="button toggle hover-over-icon align-center" data-testid={dataTestId} onClick={handleClick}>
    //   <i className="material-icons">{toggleState ? onIcon : offIcon}</i><p>{toggleState ? onText : offText}</p>
    // </div> */}
  );
};

export default ToggleListItem;
