/* eslint-disable */
const moment = require("moment");
const Joi = require("joi");
export const validInputString = Joi.string().min(2).max(500).required().pattern(new RegExp('^[a-zA-Z0-9åäöÅÄÖ\é\è\É\È\à\á\Á\À\ó\ò\Ó\Ò\\r\\n\\!\\?\\.\\,\\@\\(\\)\\/\\:\\"\'\\s\\-]{2,500}$'));
const validInputEmail = Joi.string().required().email({tlds: {allow: false}});
export const validator = Joi.object({
  inputString: validInputString,
});
export const isValidEmail = Joi.object({
  email: validInputEmail,
});
export const participantValidator = Joi.object({
  email: validInputEmail,
  firstName: validInputString,
  lastName: validInputString,
  title: validInputString,
});

export const scoreValidator = Joi.number().integer().required().min(0).max(10);
export const dateValidator = Joi.date()
  .min(moment().format("YYYY-MM-DD"))
  .iso()
  .required();

export const pastDateValidator = Joi.date()
  .iso()
  .required();
  
export const listValidator = Joi.array().items(validator);
export const participantListValidator = Joi.array().items(participantValidator);
