import React, { createContext, useState } from "react";
import ApiError from "../../Utils/error/ApiError";
import {
  getUsers,
  getUserCustomers,
  getAllCustomers,
} from "../../Utils/functions/api/usersApi";

export const UsersContext = createContext();

export const UserProvider = (props) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [allCustomersMode, setAllCustomersMode] = useState(false);

  const fetchUserCustomers = async (userId) => {
    try {
      setIsFetchingCustomers(true);
      const customerData = await getUserCustomers(userId);

      setCustomers(customerData?.data?.listCustomers?.items);
      setIsFetchingCustomers(false);
    } catch (err) {
      setIsFetchingCustomers(false);
      if (err instanceof ApiError) {
        throw new Error("Api error");
      }
      throw new Error("Client related error");
    }
  };

  const fetchAllCustomers = async () => {
    try {
      setIsFetchingCustomers(true);
      const customerData = await getAllCustomers();

      setCustomers(customerData?.data?.listCustomers?.items);
      setIsFetchingCustomers(false);
    } catch (err) {
      setIsFetchingCustomers(false);
      if (err instanceof ApiError) {
        throw new Error("Api error");
      }
      throw new Error("Client related error");
    }
  };

  const fetchUsers = async () => {
    try {
      setIsFetchingUsers(true);
      const userData = await getUsers();
      setUsers(userData?.data?.getUsers);
      setIsLoaded(true);
      setIsFetchingUsers(false);
    } catch (err) {
      setIsFetchingUsers(false);
      if (err instanceof ApiError) {
        throw new Error("Api error");
      }
      throw new Error("Client related error");
    }
  };

  const value = {
    users,
    setUsers,
    isLoaded,
    fetchUsers,
    setIsLoaded,
    customers,
    fetchUserCustomers,
    fetchAllCustomers,
    setCustomers,
    selectedUser,
    setSelectedUser,
    newUser,
    setNewUser,
    selectedCustomer,
    setSelectedCustomer,
    isFetchingCustomers,
    setIsFetchingCustomers,
    isFetchingUsers,
    setIsFetchingUsers,
    allCustomersMode,
    setAllCustomersMode,
  };

  return (
    <UsersContext.Provider value={value}>
      {props.children}
    </UsersContext.Provider>
  );
};
