import React, { useContext } from "react";
import "../../Questions/QuestionItem/QuestionItem.css";
import DropDownMenu from "../../General/DropDownMenu/DropDownMenu";
import { QuestionContext } from "../../../Utils/contexts/QuestionContext";

const TraitItem = ({ trait, openModal }) => {
  const { setSelectedTraitId } = useContext(QuestionContext);
  
  const openTraitModal = () => {
    setSelectedTraitId(trait.id);
    openModal();
  };

  const menuObjects = [
    {
      text: "Visa detaljer",
      icon: "aspect_ratio",
      customOnClick: 
        openTraitModal,
    }
  ];

  const formulation = trait.name[0].value;
  return (
    <div
      className="Item-card-question"
      id={`trait-item-${trait.id}`}
    >
      <div className={"Q-body"}>
        <div>
          <p>
            {trait.symbol}. {formulation}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "5vh",
              padding: "0 5px 0 5px"
            }}
          >
            <DropDownMenu
              ulId={`TraitDropDownModal_${trait.id}`}
              menuObjects={menuObjects}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraitItem;
