import React, { createRef, useEffect, useLayoutEffect, useState, useContext } from "react";
import classNames from "classnames";
import "./ListItem.css";
import DropDownMenu from "../General/DropDownMenu/DropDownMenu";
import M from "../../Utils/materialize/materialize.min";
import { UsersContext } from "../../Utils/contexts/UsersContext";

const ListItem = ({
  name,
  nameIcon = null,
  isSelected,
  index,
  onListItemClick,
  menuObjects = null,
  uniqueMenuObjects = null,
  listName
}) => {
  useEffect(() => {
    if (menuObjects) {
      let elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });
    }
  }, []);

  const [showTooltip, setShowTooltip] = useState(false);
  const ref = createRef();

  useLayoutEffect(() => {
    let elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, { enterDelay: 700 });
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    }
  }, [ref]);

  const bodyClassName = classNames("ListItem-body", {
    "ListItem-selected": isSelected,
  });

  return (
    <div
      className="Item-card"
      data-testid={`list-item-${index}`}
      onClick={(e) => {
        e.stopPropagation();
        onListItemClick(index);
      }}
      id={(listName === "Användare") ? `user-item-${name}` : ""}
    >
      <div
        data-testid={`list-item-body-${index}`}
        className={isSelected ? "body-green" : "body"}
      >
        <div className="Body-container">
          <p
            className={showTooltip ? "tooltipped" : ""}
            data-position="top"
            data-tooltip={name}
            ref={ref}
            style={{display: "flex", alignItems: "center"}}
          >
            {nameIcon && <i className="material-icons name-icon">{nameIcon}</i>}
            {name}
          </p>

          {uniqueMenuObjects && (
            <DropDownMenu
              ulId={`listItemDropDownMenu_${index}`}
              menuObjects={uniqueMenuObjects}
            />
          )}
          {!uniqueMenuObjects && menuObjects && menuObjects.length !== 0 && (
            <DropDownMenu
              ulId={`listItemDropDownMenu_${index}`}
              menuObjects={menuObjects}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ListItem;
