import React from "react";
import QuestionComponentParent from "../../components/Questions/QuestionComponentPartent/QuestionComponentParent";
import "./QuestionView.css";
import { QuestionProvider } from "../../Utils/contexts/QuestionContext";
const QuestionView = () => {
  return (
    <div>
      <div className="Container-row">
        <div className="Container-column">
          <QuestionProvider>
            <QuestionComponentParent />
          </QuestionProvider>
        </div>
      </div>
    </div>
  );
};

export default QuestionView;
