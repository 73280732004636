import React, { useEffect } from "react";
import { Form, Field } from "react-final-form";
import "../../../Utils/CSS/modals.css";
import ModalButton from "../ModalButton/ModalButton";
import { ModalInput } from "../ModalInput/ModalInput";

const FinalFormModal = (props) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      id={props.modalHTMLIdentifier}
      data-testid={props.modalHTMLIdentifier}
      className="modal backdrop-static"
    >
      {/* If you want Bottom Sheet Modal then add 
                      bottom-sheet class to the "modal" div
                      If you want Fixed Footer Modal then add
                      modal-fixed-footer to the "modal" div*/}
      <div
        // className="modal fade"
        id={props.modalHTMLIdentifier + "dialog"}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header border-bottom-0"></div>
            <Form
              onSubmit={(values, form) => props.onSubmit(values, form)}
              initialValues={props.initialValues}
              keepDirtyOnReinitialize={false}
              render={({ handleSubmit, submitting, submitError, form }) => (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h5
                      className="modal-title"
                      id={props.modalHTMLIdentifier + "modalHeader"}
                    >
                      {props.formInfo.header}
                    </h5>
                    <div>
                      <i
                        className="material-icons modal-close Close-cross-button"
                        onClick={() => {
                          form.reset();
                          props.onCancel && props.onCancel();
                        }}
                      >
                        close
                      </i>
                    </div>
                  </div>

                  {props.formInfo.body &&
                    props.formInfo.body.map((body, key) => {
                      return (
                        <div
                          key={key}
                          style={{
                            margin: "2vh",
                            textAlign:
                              props.formInfo.alignLeft !== undefined
                                ? "left"
                                : "",
                          }}
                        >
                          {body}
                        </div>
                      );
                    })}

                  <form onSubmit={(event) => handleSubmit(event, form)}>
                    {props.inputFields.map((inputField, key) => (
                      <div key={key}>
                        <Field name={inputField.header}>
                          {({ input, meta }) => (
                            <ModalInput
                              labelName="input-label"
                              input={input}
                              inputField={inputField}
                              meta={meta}
                            />
                          )}
                        </Field>
                      </div>
                    ))}

                    <div style={{ color: "rgb(219, 75, 26)" }}>
                      {props.errorMessage}
                    </div>

                    <div>{props.bodyText}</div>
                    <div className="modal-footer border-top-0 Footer-container">
                      {props.cancelButtonName && (
                        <ModalButton
                          buttonName={props.cancelButtonName}
                          buttonType={"button"}
                          disabled={submitting}
                          buttonTrigger="modal-close"
                          onCancel={() => {
                            form.reset();
                            props.onCancel && props.onCancel();
                          }}
                        />
                      )}

                      {props.submitButtonName && (
                        <ModalButton
                          buttonName={props.submitButtonName}
                          buttonType={"submit"}
                          disabled={submitting}
                          buttonTrigger=""
                        />
                      )}
                    </div>
                  </form>
                </>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalFormModal;
