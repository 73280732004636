import React, { useContext } from "react";
import ProjectListItem from "../ProjectListItem/ProjectListItem";
import { FormContext } from "../../../Utils/contexts/FormContext";
import Preloader from "../../General/Preloader/Preloader";

const ProjectList = () => {

  const {
    projects,
    isFetchingProjects
  } = useContext(FormContext);
  return (
    <div className="Card-list">
      <p className="header">Projekt</p>
      <div className="body">
        <div className="Items Card-min-width">
          {isFetchingProjects ? <Preloader/> : projects.map((project, index) => (
            <ProjectListItem
              project={project}
              index={index}
              key={index}
            />
          ))}
        </div>
      </div>

    </div >
  );
};

export default ProjectList;