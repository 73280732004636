import React, { useContext, useEffect, useState } from "react";
import { ParticipantFormContext } from "../../../Utils/contexts/ParticipantFormContext";
import ModalButton from "../../General/ModalButton/ModalButton";
import "./GDPRConsent.css";
import { translation } from "../ParticipantFormTranslations";
import M from "materialize-css";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import { useMediaQuery } from "react-responsive";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";

const GDPRConsent = () => {
  const { 
    acceptTermsOnClick,
    formLanguage
  }  = useContext(ParticipantFormContext);

  const [checkboxStates, setCheckboxStates] = useState([false, false]);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    M.AutoInit();
    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, [translation]);
  
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      <LanguageSwitch />
      <div className="GDPR-container" style={{margin: isTabletOrMobile ? "5%" : "10% 30% 10% 30%"}}>
        <div className="GDPR" data-testid="gdpr">
          {/* <img alt="adviser partner logo" className="logo" src={adviserpartnerlogo} /> */}
          <h4>{translation.gdprPage[formLanguage].header}</h4>   

          <span className="body-text">


            <div style={{paddingTop: "2vh"}} key={`condition-${0}`} >
              <label>
                <input 
                  className="filled-in"
                  type="checkbox"
                  data-testid="gdpr-checkbox1" 
                  id={`${0}`} 
                  onChange={ (e) => {
                    const checkboxes = JSON.parse(JSON.stringify(checkboxStates));
                    checkboxes[e.target.id] = e.target.checked;
                    setCheckboxStates(checkboxes);
                    if (checkboxes.find(e => e === false) === undefined) {
                      setAccepted(true);
                    } else {
                      setAccepted(false);
                    }
                  }}
                />
                <span>{translation.gdprPage[formLanguage].conditions[0]}</span>
              </label>
            </div>
            <p style={{color: "blue", cursor: "pointer"}} 
              className="gdpr-link"
              onClick={() => {
                document
                  .getElementById(`viewGdprTermsModal-${0}`)
                  .M_Modal.open();
              }}
            >
              {translation.gdprPage[formLanguage].readMore}
            </p>

            <div style={{paddingTop: "2vh"}} key={`condition-${1}`} >
              <label>
                <input 
                  className="filled-in"
                  type="checkbox"
                  data-testid="gdpr-checkbox2"
                  id={`${1}`} 
                  onChange={ (e) => {
                    const checkboxes = JSON.parse(JSON.stringify(checkboxStates));
                    checkboxes[e.target.id] = e.target.checked;
                    setCheckboxStates(checkboxes);
                    if (checkboxes.find(e => e === false) === undefined) {
                      setAccepted(true);
                    } else {
                      setAccepted(false);
                    }
                  }}
                />
                <span>{translation.gdprPage[formLanguage].conditions[1]}</span>
              </label>
            </div>
            <p 
              style={{color: "blue", cursor: "pointer"}} 
              className="gdpr-link" 
              onClick={() => {
                document
                  .getElementById(`viewGdprTermsModal-${1}`)
                  .M_Modal.open();
              }}
            >
              {translation.gdprPage[formLanguage].readMore}
            </p>

            <ModalButton 
              buttonName={translation.gdprPage[formLanguage].button} 
              buttonType={"submit"} 
              onCancel={acceptTermsOnClick} 
              disabled={!accepted} 
              type="submit"
            />

          </span>
        </div>
        <div className={isTabletOrMobile ? "Modal-gdpr-mobile" : "Modal-gdpr"}>
          <FinalFormModal
            modalHTMLIdentifier={`viewGdprTermsModal-${0}`}
            inputFields={[]}
            initialValues={[]}
            onCancel={() => {
              document
                .getElementById(`viewGdprTermsModal-${0}`)
                .M_Modal.close();
            }}
            onClick={(e) => e.stopPropagation()}
            // onSubmit={() => {}}
            // submitButtonName={""}
            cancelButtonName={translation.viewGdprTermsModal_0[formLanguage].cancel}
            formInfo={{
              alignLeft: true,
              header: translation.viewGdprTermsModal_0[formLanguage].header,
              body: translation.viewGdprTermsModal_0[formLanguage].body,
            }}
          />
          <FinalFormModal
            modalHTMLIdentifier={`viewGdprTermsModal-${1}`}
            inputFields={[]}
            initialValues={[]}
            onCancel={() => {
              document
                .getElementById(`viewGdprTermsModal-${1}`)
                .M_Modal.close();
            }}
            onClick={(e) => e.stopPropagation()}
            // onSubmit={() => {}}
            // submitButtonName={""}
            cancelButtonName={translation.viewGdprTermsModal_1[formLanguage].cancel}
            formInfo={{
              alignLeft: true,
              header: translation.viewGdprTermsModal_1[formLanguage].header,
              body: translation.viewGdprTermsModal_1[formLanguage].body,
            }}
          />
        </div>
        
      </div>
    </>
  );
};

export default GDPRConsent;
