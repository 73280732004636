import { useContext, useState, useEffect } from "react";

import { ParticipantFormContext } from "../../../Utils/contexts/ParticipantFormContext";
import  ParticipantFormQuestionListItem  from "../ParticipantFormQuestionListItem/ParticipantFormQuestionListItem";


const ParticipantFormQuestionList = ({isTabletOrMobile}) => {
  const {
    questions,
    currentPage
  } = useContext(ParticipantFormContext);
  const map = {
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
    FOURTH: 4,
    FIFTH: 5
  };
  
  return (
    <>
      {
        questions.slice((currentPage - 1) * 10, currentPage * 10).map((question) => {
          return (
            <div
              style={{
                width: "100vw",
                display: "flex",
                justifyContent: "center",
              }}
              key={question.id}
            >
              <div style={{width: isTabletOrMobile ? "80vw" : "50vw", marginBottom: isTabletOrMobile ? "3%":"1%"}}>
                <p style={{ fontSize: "18px", fontWeight: "bold", cursor: "default" }}>
                  {question.translations[0].formulation}
                </p>
                {Object.entries(
                  Object.entries(question.answerSequence).sort(
                    ([answer1, order1], [answer2, order2]) => {
                      return map[order1] - map[order2];
                    }
                  )
                ).map(([index, order]) => {
                  const answerIndex = parseInt(index) + 1;
                  return (
                    <ParticipantFormQuestionListItem key={index} order={order} index={answerIndex} question={question} />
                  );
                })}
                  
              </div>
            </div>
          );
        })
      }
    </>
  );
};

export default ParticipantFormQuestionList;