import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  CUSTOMER_VIEW,
  FORM_VIEW,
  QUESTION_VIEW,
  REPORT_VIEW,
  USER_VIEW,
  ECONOMY_VIEW,
  TRAIT_VIEW,
  HELP_VIEW
} from "../../../constants/Routes";
import { SuperUserContext } from "../../../Utils/contexts/SuperUserContext";

const MenuBar = () => {
  const {
    isSuperUser,
    loggedIn
  } = useContext(SuperUserContext);

  const path = window.location.pathname;

  return (
    <>
      {loggedIn ? (
        <ul id="myUl">
          <li className={`menuItem${path?.includes(CUSTOMER_VIEW) ? " active" : ""}`} >
            <Link to={CUSTOMER_VIEW}>Kunder</Link>
          </li>
          <li className={`menuItem${path?.includes(FORM_VIEW) ? " active" : ""}`} >
            <Link to={FORM_VIEW}>Formulär</Link>
          </li>
          <li className={`menuItem${path?.includes(REPORT_VIEW) ? " active" : ""}`} >
            <Link to={REPORT_VIEW}>Rapporter</Link>
          </li>
          <li
            style={{ display: isSuperUser ? "" : "none" }}
            className={`menuItem${path?.includes(QUESTION_VIEW) ? " active" : ""}`}>
            <Link to={QUESTION_VIEW}>Frågor</Link>
          </li>
          <li
            style={{ display: isSuperUser ? "" : "none"  }}
            className={`menuItem${path?.includes(TRAIT_VIEW) ? " active" : ""}`}>
            <Link to={TRAIT_VIEW}>Kategorier</Link>
          </li>
          <li
            style={{ display: isSuperUser ? "" : "none" }}
            className={`menuItem${path?.includes(USER_VIEW) ? " active" : ""}`}>
            <Link to={USER_VIEW}>Användare</Link>
          </li>
          <li
            style={{ display: isSuperUser ? "" : "none" }}
            className={`menuItem${path?.includes(ECONOMY_VIEW) ? " active" : ""}`}>
            <Link to={ECONOMY_VIEW}>Kostnadsrapport</Link>
          </li>
          <li className={`menuItem${path?.includes(HELP_VIEW) ? " active" : ""}`}>
            <Link to={HELP_VIEW}>Hjälp</Link>
          </li>
        </ul>
      ) : (
        <ul id="myUl"></ul>
      )}
    </>
  );
};

export default MenuBar;
