export const translation = {
  nextButton: {
    SE: {
      submit: "Skicka in och avsluta formuläret",
      next: "Nästa"
    },
    EN: {
      submit: "Submit",
      next: "Next"
    }
  },
  prevButton: {
    SE: {
      previous: "Föregående",
    },
    EN: {
      previous: "Previous",
    }
  },
  analysisCompleted: {
    SE: "Du har nu avslutat analysen.",
    EN: "You have completed the analysis."
  },
  supportFooter:{
    SE: "Om du har frågor, vänligen maila:",
    EN: "If you have any questions, kindly email:"
  },
  modalNextPageQuestionsRemaining: {
    SE: {
      submit: "Fortsätt ändå",
      cancel: "Tillbaka",
      header: "Du har inte besvarat alla frågor",
      body: [
        "Du kan fortfarande gå tillbaka och ändra om det är något du inte är tillfredsställd med.",
      ]
    },
    EN: {
      submit: "Continue anyway",
      cancel: "Back",
      header: "There are unanswered questions on this page",
      body: [
        "You can still go back and change your answers later.",
      ]
    },
  },
  modalConfirmSubmit: {
    SE: {
      submitButtonComplete: "Slutför",
      submitButtonPartiallyComplete: "Slutför delvist besvarad",

      cancelButtonComplete: "Tillbaka",
      cancelButtonPartiallyComplete: "Tillbaka",

      headerComplete: "Formuläret är fullständigt besvarat",
      headerPartiallyComplete: "Du har inte besvarat alla frågor",

      bodyComplete: [
        "Du kan fortfarande gå tillbaka och ändra om det är något du inte är tillfredsställd med.",
        "Tryck slutför för att avsluta formuläret. Därefter kan du inte längre ändra dina svar.",
      ],
      bodyPartiallyComplete: [
        "Du kan gå tillbaka genom att trycka på de gulmarkerade kapitlen ovan eller trycka på tillbaka-knappen nedan.",
        "Du kan välja att slutföra med delvist besvarade delar.",
      ],
    },
    EN: {
      submitButtonComplete: "Submit",
      submitButtonPartiallyComplete: "Submit partially answered",

      cancelButtonComplete: "Back",
      cancelButtonPartiallyComplete: "Back",

      headerComplete: "The questionnaire is fully answered.",
      headerPartiallyComplete: "There are unanswered questions on this page",

      bodyComplete: [
        "You still have the option to go back and change your answers.",
        "Press submit to complete the questionnaire. Your answers cannot be changed after submitting the questionnaire.",
      ],
      bodyPartiallyComplete: [
        "You can go back to earlier chapters by pressing the yellow navigation buttons above or pressing the back button below.",
        "You may also choose to submit the questionnaire with partially answered chapters.",
      ],
    },
  },

  formAccess: {
    SE: {
      forbidden: ["Det här formuläret är redan avslutat.", "Tack för din medverkan."],
      denied: ["Det här formuläret existerar inte."],
      submitted: ["Tack för att du besvarade formuläret."],
      closed: ["Det här formuläret är stängt."],
      genericError: ["Något gick fel vid hämtningen av formuläret."],
    },
    EN: {
      forbidden: ["This questionnaire has already been submitted.", "Thank you for participating."],
      denied: ["The questionnaire does not exist."],
      submitted: ["Thank you for participating."],
      closed: ["This questionnaire is closed."],
      genericError: ["Something went wrong while fetching the questionnaire."],
    }
  },
  gdprPage: {
    SE: {
      header: "Avtal om behandling av personuppgifter",
      conditions: [
        "Jag har läst och förstått mina rättigheter gällande lagring av mina personuppgifter.", 
        "Jag ger mitt samtycke till personuppgiftsansvarig (företag som tillhandhåller systemet) att behandla och lagra mina personuppgifter."
      ],
      body: "",
      button: "Godkänn",
      readMore: "Läs mer..."
    },
    EN: {
      header: "Agreement on the processing of personal data",
      conditions: [
        "I have read and understood my rights regarding the storage of my personal data.",
        "I give my consent to the person responsible for personal data (companies that provide the system) to process and store my personal data."
      ],
      body: "",
      button: "Accept",
      readMore: "Read more..."
    }
  },

  viewGdprTermsModal_0: {
    SE : {
      cancel: "Stäng",
      header: "",
      body: [
        <h5 key={"first header"} style={{fontWeight: "bold"}}>Den registrerade, (dvs. den som beviljade samtycket) enligt förordningen, informeras om att: </h5>,
        "a.     har rätt och inte skyldigheten att lämna sina personuppgifter till Adviser Partner Europe AB som den registeransvarige för hans eller hennes personuppgifter",

        "b.    tillhandahållande av personuppgifter är inte ett villkor för att ingå ett avtal, tillhandahållande av personuppgifter är emellertid en förutsättning för användning av Adviser Partner Europe AB tjänst",

        "c.     de identifierande uppgifterna och kontaktuppgifterna för kontrollenheten är Adviser Partner Europe AB, organisationsnummer 556219–8944, med sitt säte på Strandvägen 1, 114 51 Stockholm, Sverige", 

        "d.   Adviser Partner Europe AB avser inte att överföra den registrerades personuppgifter",

        "e.    lagringsperioden för personuppgifter är begränsad till fem år från datumet för det senaste samtycket",

        "f.     den registrerade har rätt att begära från Adviser Partner Europe AB som en registeransvarig, via sin klient eller dotterbolag till Adviser Partner Europe AB, tillgång till personuppgifter som rör hans eller hennes person och till information om Adviser Partner Europe AB behandlar den personliga uppgifter om honom, syftet, kategorin och tiden för behandling av personuppgifter och andra",

        "g.     har rättatt när som helst dra tillbaka detta samtycke",

        "h.    den registrerade har rätt att få Adviser Partner Europe AB, via sin klient eller Adviser Partner Europe AB dotterbolag, utan onödigt dröjsmål att korrigera felaktiga personuppgifter som rör den. När det gäller syftet med behandlingen av personuppgifter har den registrerade rätt att komplettera ofullständiga personuppgifter",

        "i.    har rätt att låta Adviser Partner Europe AB, via dess klient eller dotterbolag till Adviser Partner Europe AB, radera utan onödigt dröjsmål de personuppgifter som är relaterade till den, i synnerhet, men inte exklusivt, om personuppgifter inte längre är nödvändiga för ändamål för vilka de förvärvades eller på annat sätt har behandlats, eller om de återkallats från sitt samtycke",

        "j.     har rätt att få Adviser Partner Europe AB, via sin klient eller dotterbolag till Adviser Partner Europe AB, begränsa behandlingen av personuppgifter särskilt, men inte uteslutande, om den registrerade har invänt mot riktigheten av personuppgifterna under den period som gör det möjligt för Adviser Partner Europe AB att verifiera riktigheten av personuppgifterna",

        "k.    har rätt att informeras från Adviser Partner Europe AB, via dess klient eller dotterbolag till Adviser Partner Europe AB, om reparation av personuppgifter, radering av personuppgifter eller begränsning av behandlingen av personuppgifter såvida det inte visas att vara omöjlig eller kräver inte överdriven ansträngning",

        "l.      har rätt att erhålla personuppgifter om den och som de har tillhandahållit Adviser Partner Europe AB, via dess klient eller Adviser Partner Europe AB dotterbolag, i ett strukturerat, vanligt använt och maskinläsbart format och har rätt att överföra sådana personuppgifter till en annan registeransvarig om detta är tekniskt möjligt",

        "m.    har rätt att invända mot behandling av sina personuppgifter av Adviser Partner Europe AB, via dess klient eller dotterbolag till rådgivare Partner Europe AB, på grund av brist på legitimt intresse för behandlingen av personuppgifter eller bristen på ett juridiskt krav. Tills dess misslyckande med detta, får Adviser Partner Europe AB inte behandla personuppgifter",

        "n.    personuppgifter som behandlas under sådant samtycke är också föremål för automatiserad behandling",

        "o.    har rätt att inleda förfaranden så att en nationell dataskyddsmyndighet kan fastställa om fysiska personers rättigheter har åsidosatts vid behandlingen av deras personuppgifter eller att förordningen eller nationella dataskyddsregler har åsidosatts. Designmallen kan publiceras på den nationella myndigheten för skydd av personuppgifter",

        "p.   om han eller hon inte har förmågan att agera i sin helhet, kan hans rättigheter utövas av en juridisk representant",

        "q.    om den berörda personen inte bor, kan hans rättigheter enligt lagen om skydd av personuppgifter utövas av en nära person",

        "r.     att Adviser Partner Europe AB, via dess klient eller dotterbolag till Adviser Partner Europe AB, inte får verkställa eller förlåta den registrerades samtycke genom att hota att vägra ett avtalsförhållande, service, varor eller skyldigheter enligt lagen",

        "s.    för alla begäranden från den registrerade, om de lämnades in elektroniskt, kommer Adviser Partner Europe AB, via dess klient eller Adviser Partner Europe AB dotterbolag, att lämna informationen i elektronisk form om den berörda personen inte begärde att informationen skulle lämnas på ett annat sätt. Om en registrerad begär en annan form, är svaret på Adviser Partner Europe AB på företagets eget beslut, åtminstone för att svaret ska levereras",

        "t.    för all den registrerades begäran, ansökan utan kostnad om inget annat anges. Om den berörda personens begäran är uppenbart ogrundad eller olämplig, särskilt för dess återkommande karaktär, kan Adviser Partner Europe AB - kräva en lämplig avgift för att ta hänsyn till administrativa kostnader eller vägra att agera på ansökan",
        <h5 key={"secondary header"} style={{fontWeight: "bold"}}>Den registrerade (dvs. den som beviljade samtycket) har rätt att begära från Adviser Partner Europe AB, via dess klient eller Adviser Partner Europe AB dotterbolag, i form av skriftlig begäran: </h5>,
        // "Den registrerade (dvs. den som beviljade samtycket) har rätt att begära från Adviser Partner Europe AB, via dess klient eller Adviser Partner Europe AB dotterbolag, i form av skriftlig begäran:",

        "a.   i en generellt förståelig form, information om behandling av personuppgifter i informationssystemet i omfattningen av: processorns identifieringsuppgifter; en vägledning om frivillighet eller skyldighet att tillhandahålla nödvändig personlig information; tredje parter, om det förutses eller är uppenbart att personuppgifter kommer att göras tillgängliga för dem; formen för utlämnande om personuppgifter lämnas ut; mottagarområdet, om det förutses eller är uppenbart att personuppgifter kommer att göras tillgängliga för dem; tredje länder, om det är förutsett eller uppenbart att överföringen av personuppgifter kommer att ske i dessa länder",

        "b.      i en generellt förståelig form korrekt information om källan från vilken den skaffade sina personuppgifter för behandling."
      ],
    },
    EN: {
      cancel: "Cancel",
      header: "",
      body: [
        <h5 key={"first header"} style={{fontWeight: "bold"}}>Data subject (i.e. the person who granted the consent) pursuant to Regulation is informed, that: </h5>,
        "a.	has the right and not the duty to provide its personal data to Adviser Partner Europe AB as the controller of his or her personal data",
        "b.	the provision of personal data is not a condition for the conclusion of an agreement, although the services of Adviser Partner Europe AB can´t be used without the provision of personal data",
        "c.	the identifying data and contact details of the controller are Adviser Partner Europe AB, with its seat at Strandvägen 1, 114 51 Stockholm, Sweden, organization number: 556219-8944",
        "d.	Adviser Partner Europe AB does not intend to transfer the personal data of the data subject",
        "e.	the period of storage of personal data is limited to five years from the date of the last consent",
        "f.	the data subject has the right to request from Adviser Partner Europe AB as an controller, via it`s client or Adviser Partner Europe AB subsidiary, access to personal data relating to his or her person and to information whether Adviser Partner Europe AB processes the personal data relating to him, the purpose, category and time of processing of personal data and others",
        "g.	has the right to withdraw this consent at any time",
        "h.	data subject has the right to have Adviser Partner Europe AB, via it`s client or Adviser Partner Europe AB subsidiary, correct without undue delay the incorrect personal data relating to it. With regard to the purpose of the processing of personal data, the data subject is entitled to supplement incomplete personal data",
        "i.	has the right to have the Adviser Partner Europe AB, via it`s client or Adviser Partner Europe AB subsidiary, erase without undue delay the personal data relating to it, in particular, but not exclusively, if personal data are no longer necessary for the purpose for which they were acquired or otherwise processed, or if withdrawn of its consent",
        "j.	has the right to have the Adviser Partner Europe AB ,via it`s client or Adviser Partner Europe AB subsidiary, restrict the processing of personal data in particular, but not exclusively, if the data subject has objected to the correctness of the personal data during the period enabling Adviser Partner Europe AB to verify the accuracy of the personal data",
        "k.	has the right to be informed from Adviser Partner Europe AB, via it`s client or Adviser Partner Europe AB subsidiary, about the repair of personal data, the erase of personal data or the limitation of the processing of personal data unless it is shown to be impossible or does not require excessive effort",
        "l.	has the right to obtain personal data relating to it and which it has provided to Adviser Partner Europe AB, via it`s client or Adviser Partner Europe AB subsidiary, in a structured, commonly used and machine-readable format and has the right to transfer such personal data to another controller if this is technically possible",
        "m.	has the right to object to the processing of its personal data by Adviser Partner Europe AB, via it`s client or Adviser Partner Europe AB subsidiary, due to a lack of legitimate interest in the processing of personal data or the lack of a legal claim. Until their failure to do so, Adviser Partner Europe AB may not process personal data",
        "n.	personal data processed under such consent are also subject to automated processing",
        "o.	has the right to initiate proceedings so that a national data protection authority can ascertain whether the rights of natural persons have been infringed in the processing of their personal data or that the Regulation or national data protection rules have been infringed. The design template may be published on the National Authority for Personal Data Protection",
        "p.	if he or she does not have the capacity to act in full, his rights may be exercised by a legal representative",
        "q.	if the person concerned does not live, his rights under the law on the protection of personal data may be exercised by a close person",
        "r.	that Adviser Partner Europe AB, via it`s client or Adviser Partner Europe AB subsidiary, may not enforce or condone the consent of the data subject by threatening to refuse a contractual relationship, service, goods or obligations under the law",
        "s.	for all requests by the data subject, if they were submitted electronically, Adviser Partner Europe AB, via it`s client or Adviser Partner Europe AB subsidiary, will provide the information in electronic form if the person concerned did not request the information to be provided in another way. In the case that a different form is requested by a data subject, the form of Adviser Partner Europe AB response is on company own decision, at least for the reply to be delivered",
        "t.	for all the request of the data subject, apply without charge unless otherwise stated. If the request of the person concerned is manifestly unfounded or inappropriate in particular for its recurring nature, Adviser Partner Europe AB - may require an appropriate fee to take account of administrative costs or refuse to act on application",
         
        <h5 key={"first header"} style={{fontWeight: "bold"}}>Data subject (i.e. the person who granted the consent) has the right to request from Adviser Partner Europe AB, via it`s client or Adviser Partner Europe AB subsidiary, in the form of written request:</h5>,
        "a.	in a generally understandable form, information on the processing of personal data in the information system in the extent of: the processor's identification data; a guidance of voluntariness or obligation to provide the required personal data; third parties, if it is foreseen or is obvious that personal data will be made available to them; the form of disclosure if personal data are disclosed; the range of recipients, if it is foreseen or is obvious that personal data will be made available to them; third countries, if it is foreseen or apparent that the transfer of personal data will take place in these countries",
        "b.	in a generally understandable form, accurate information on the source from which it obtained its personal data for processing."        
        
      ],
    }
  },

  viewGdprTermsModal_1: {
    SE : {
      cancel: "Stäng",
      header: "",
      body: [
        <span key="viewGdprTermsModal_1_se">Våra kunders integritet är viktig för oss. För att skydda registrerade personuppgifter åt Adviser Partners intressenter är vi måna om att lagra och behandla denna information i enlighet med GDPR (General Data Protection Regulation). Gå in på <a href='https://adviser-partner.se/uppdaterad-integritetspolicy' target='blank' className='Gdpr-modal-body-text-link'>https://adviser-partner.se/uppdaterad-integritetspolicy</a> för att läsa vår integritetspolicy.</span>
      ],
    },
    EN: {
      cancel: "Cancel",
      header: "",
      body: [
        <span key="viewGdprTermsModal_1_en">We respect our clients integrity. To protect registered personal data for Adviser Partners stakeholders we treat all personal data and other information in accordance with GDPR (General Data Protection Regulation). Go to <a href='https://adviser-partner.se/uppdaterad-integritetspolicy' target='blank' className='Gdpr-modal-body-text-link'>https://adviser-partner.se/uppdaterad-integritetspolicy</a> to read our full integrity policy.</span>
      ],
    }
  },

  informationPage: {
    SE: {
      header: "Välkommen till Adviser Partner Sales Health Check.",
      body: [
        "Sales Health Check är en analys och inte ett test. Detta innebär att du kan svara precis som du tycker och vi ber dig att titta på säljavdelningens beteenden och hur den fungerar. Den som utvärderar dina resultat bedömer i första hand inte svar på enskilda frågor utan bedömningar görs baserat på de totala resultat som framkommer från er som grupp. Om du av någon anledning inte vill svara på en enskild fråga kan du hoppa över den och gå vidare till nästa fråga och om du bestämmer dig för att hoppa över fler frågor kan du göra det genom att klicka på knappen 'Nästa' längst ner till höger.", 
        
        "Sales Health Check tar ca 45-60 minuter att göra och dina svar blir automatiskt sparade för att du skall slippa göra om analysen ifall din internetanslutning av någon anledning skulle avbrytas eller om du skulle vilja fortsätta analysen från en annan dator eller mobil under pågående test. Du kan alltid återvända till den del av frågeformuläret där du var genom att starta om eller genom att använda samma länk som du fått utskickat.",

        "Vi uppskattar att du tar dig tid att ge oss din värdefulla åsikt gällandes ert företag och framförallt er säljavdelning."
         
      ],
      button: "Forsätt",
    },
    EN: {
      header: "Welcome to Adviser Partner Sales Health Check.",
      body: [
        "Sales Health Check is an analysis and not a test. This means that you can answer exactly as you think and we ask you to look at the sales department's behavior and how it works. The person who evaluates your results does not primarily assess answers on individual questions, but assessments are made based on the total results that emerge from you as a group. If you for some reason do not want to answer an individual question, you can skip it and move on to the next question and if you decide to skip more questions you can do so by clicking the button 'Next' on the bottom right. ",
        
        "Sales Health Check takes about 45-60 minutes to do and your answers are automatically saved so that you do not have to repeat the analysis if your internet connection should be interrupted for any reason or if you would like to continue the analysis from another computer or mobile during the ongoing test. You can always return to the part of the questionnaire where you were by restarting or by using the same link that you were sent. ",

        "We appreciate you taking the time to give us your valuable opinion regarding your company and especially your sales department."
      
      ],
      button: "Proceed",
    }
  },


};
