class ApiError extends Error {
  constructor(description = "Error when making API call", error) {
    super(error.errors);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    // this.error = error;
    this.name = "ApiError";
    this.description = description;
    this.message = description;
    this.date = new Date();
    this.data = error.data;
    this.errors = error.errors;
  }
}

export default ApiError;
