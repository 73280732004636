import { API, graphqlOperation } from "aws-amplify";
import { createCustomer2 } from "../../graphql/mutations";

const CreateCustomer = async (companyName) => {
 
  return (await API.graphql(
    graphqlOperation(createCustomer2, {
      companyName: companyName
    })
  )).data.createCustomer2;
};

export default CreateCustomer;