/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getExcelTemplate = /* GraphQL */ `
query GetExcelTemplate {
  getExcelTemplate
}
`;
export const getFormParticipants = /* GraphQL */ `
  query GetFormParticipants($formId: ID!) {
    getFormParticipants(formId: $formId) {
      id
      formId
      email
      firstName
      lastName
      title
      status
      selectedAnswers {
        question
        answer
      }
      remindersSent
      createdAt
      updatedAt
    }
  }
`;
export const getProjectForms = /* GraphQL */ `
  query GetProjectForms($projectId: ID!) {
    getProjectForms(projectId: $projectId) {
      id
      name
      configuration {
        traitIds
        language
        deadline
        reminder
        invitation
      }
      projectId
      status
      createdAt
    }
  }
`;
export const generateReport = /* GraphQL */ `
query GenerateReport($reportName: String!, $language: Language!, $formIds: [ID!]!, $makeAggregatedReport: Boolean!) {
  generateReport(reportName: $reportName, language: $language, formIds: $formIds, makeAggregatedReport: $makeAggregatedReport)
}
`;

export const getCostReport = /* GraphQL */ `
query getCostReport($fromDate: AWSDate!, $toDate: AWSDate!) {
  getCostReport(fromDate: $fromDate, toDate: $toDate)
}
`;

export const sendInvitationEmail = /* GraphQL */ `
  query SendInvitationEmail($formId: ID!) {
    sendInvitationEmail(formId: $formId) 
  }
`;
export const getCustomerProjects = /* GraphQL */ `
  query GetCustomerProjects($customerId: ID!) {
    getCustomerProjects(customerId: $customerId) {
      id
      projectName
      customerId
      participants {
        email
        firstName
        lastName
        title
      }
      createdAt
      updatedAt
    }
  }
`;
export const getFormScores = /* GraphQL */ `
  query GetFormScores($formId: ID!) {
    getFormScores(formId: $formId)
  }
`;
export const getParticipantScores = /* GraphQL */ `
  query GetParticipantScores($formId: ID!, $participantId: ID!) {
    getParticipantScores(formId: $formId, participantId: $participantId)
  }
`;

export const getUsers = /* GraphQL */ `
  query GetUsers {
    getUsers {
      username
      isSuperUser
    }
  }
`;
export const getOrderOfQuestions = /* GraphQL */ `
  query GetOrderOfQuestions($id: ID!) {
    getOrderOfQuestions(id: $id) {
      id
      order
      createdAt
      updatedAt
    }
  }
`;
export const listOrderOfQuestionss = /* GraphQL */ `
  query ListOrderOfQuestionss(
    $filter: ModelOrderOfQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderOfQuestionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTrait = /* GraphQL */ `
  query GetTrait($id: ID!) {
    getTrait(id: $id) {
      id
      symbol
      idealScene {
        language
        value
      }
      name {
        language
        value
      }
      questions {
        items {
          id
          trait {
            id
            symbol
            idealScene {
              language
              value
            }
            name {
              language
              value
            }
            questions {
              nextToken
            }
            createdAt
            updatedAt
          }
          translations {
            language
            formulation
            answerOptions {
              answer1
              answer2
              answer3
              answer4
              answer5
            }
          }
          scores {
            answer1
            answer2
            answer3
            answer4
            answer5
          }
          answerSequence {
            answer1
            answer2
            answer3
            answer4
            answer5
          }
          orderNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTraits = /* GraphQL */ `
  query ListTraits(
    $filter: ModelTraitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTraits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        symbol
        idealScene {
          language
          value
        }
        name {
          language
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      trait {
        id
        symbol
        idealScene {
          language
          value
        }
        name {
          language
          value
        }
        questions {
          items {
            id
            trait {
              id
              symbol
              createdAt
              updatedAt
            }
            translations {
              language
              formulation
            }
            scores {
              answer1
              answer2
              answer3
              answer4
              answer5
            }
            answerSequence {
              answer1
              answer2
              answer3
              answer4
              answer5
            }
            orderNumber
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      translations {
        language
        formulation
        answerOptions {
          answer1
          answer2
          answer3
          answer4
          answer5
        }
      }
      scores {
        answer1
        answer2
        answer3
        answer4
        answer5
      }
      answerSequence {
        answer1
        answer2
        answer3
        answer4
        answer5
      }
      orderNumber
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        trait {
          id
          symbol
          idealScene {
            language
            value
          }
          name {
            language
            value
          }
          questions {
            items {
              id
              orderNumber
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        translations {
          language
          formulation
          answerOptions {
            answer1
            answer2
            answer3
            answer4
            answer5
          }
        }
        scores {
          answer1
          answer2
          answer3
          answer4
          answer5
        }
        answerSequence {
          answer1
          answer2
          answer3
          answer4
          answer5
        }
        orderNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      consultant
      companyName
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        consultant
        companyName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionsInForm = /* GraphQL */ `
  query GetQuestionsInForm($slug: String!) {
    getQuestionsInForm(slug: $slug) {
      id
      selectedAnswer
      translations {
        language
        formulation
        answerOptions {
          answer1
          answer2
          answer3
          answer4
          answer5
        }
      }
      answerSequence {
        answer1
        answer2
        answer3
        answer4
        answer5
      }
    }
  }
`;

export const isGDPRAccepted = /* GraphQL */`
  query IsGDPRAccepted($participantId: ID!){
    isGDPRAccepted(participantId: $participantId)
  }
`;

export const getParticipantAnswers = /* GraphQL */`
  query GetParticipantAnswers($participantId: ID!){
    getParticipantAnswers(participantId: $participantId)
  }
`;