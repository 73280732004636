import { API, graphqlOperation } from "aws-amplify";
import { updateProject } from "../../graphql/mutations";
import { participantListValidator } from "./inputFieldValidation.js";

const UpdateParticipantList = async (project, participantList) => {
  const participantListValidation = participantListValidator.validate(participantList);
  if(!(participantListValidation.error === undefined)){
    throw new Error("Bad request");
  }

  const input = {
    id: project.id,
    projectName: project.projectName,
    participants: participantList
  };

  const updatedProject = await API.graphql(
    graphqlOperation(updateProject,input)
  );
  return updatedProject;
  
};

export default UpdateParticipantList;
