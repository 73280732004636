import React, { useContext, useEffect } from "react";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import "./ParticipantFormQuestionaire.css";
import FormAccess from "../../Forms/FormAccess/FormAccess";
import { usePagination } from "@material-ui/lab/Pagination";
import { ParticipantFormContext } from "../../../Utils/contexts/ParticipantFormContext";
import { useMediaQuery } from "react-responsive";
import ParticipantFormHeader from "../ParticipantFormHeader/ParticipantFormHeader";
import ParticipantFormQuestionList from "../ParticipantFormQuestionList/ParticipantFormQuestionList";
import ModalButton from "../../General/ModalButton/ModalButton";
import { translation } from "../ParticipantFormTranslations";

const ParticipantFormQuestionaire = () => {

  const { 
    questions,
    forbidden, 
    submitted,
    closed, 
    deniedGDPR, 
    denied, 
    genericError,
    supportEmail,
    allQuestionsAnswered, setAllQuestionsAnswered,
    currentPage, setCurrentPage,
    prevPage, setPrevPage,
    pageState, setPageState, 
    submitFormService,
    visitedPages,
    setVisitedPages,
    answers,
    formLanguage
  }  = useContext(ParticipantFormContext);

  useEffect(() => {
    M.AutoInit();

    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
    window.scrollTo(0,0);
  }, [questions]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  
  const markPageVisited = (clickedPage) => {
    let newVisitedPages = JSON.parse(JSON.stringify(visitedPages));
    newVisitedPages[currentPage - 1] = true;
    if(clickedPage !== undefined) newVisitedPages[clickedPage - 1] = true;
    setVisitedPages(newVisitedPages);
    setPrevPage(currentPage);
  };
  const gotoPage = (clickedPage) => {
    if(clickedPage === currentPage) return;
    if (clickedPage !== 1) {
      if(!visitedPages[clickedPage-2]){
        return;
      }
    }
    pageState[clickedPage - 1] += " Pagination-button-selected";
    pageState[currentPage - 1].replace("Pagination-button-selected", "");
    const start = (currentPage - 1) * 10;
    const end = currentPage * 10;
    const relevantQuestions = questions.slice(start, end);
    const answeredQuestion = relevantQuestions.find((q) => answers[q.id] !== 0);
    const unAnsweredQuestion = relevantQuestions.find((q) => answers[q.id] === 0);
    const allQuestionsAreAnswered = unAnsweredQuestion === undefined;
    const noQuestionsAnswered = answeredQuestion === undefined;
    if ( allQuestionsAreAnswered ) {
      pageState[currentPage - 1] = "Answered-pagination";
      markPageVisited(clickedPage);
      document.getElementById("top-of-page").scrollIntoView({ behavior: "smooth" });
    } else {
      if (noQuestionsAnswered) {
        pageState[currentPage - 1] = "";
        if(clickedPage < currentPage){
          markPageVisited();
          document.getElementById("top-of-page").scrollIntoView({ behavior: "smooth" });
        } else {
          document
            .getElementById("nextPageWithQuestionsRemainingModal")
            .M_Modal.open();
        }
      } 
      else {
        pageState[currentPage - 1] = "Partially-answered-pagination";
        if(clickedPage < currentPage){
          markPageVisited();
          document.getElementById("top-of-page").scrollIntoView({ behavior: "smooth" });
        } else {
          document
            .getElementById("nextPageWithQuestionsRemainingModal")
            .M_Modal.open();
        }
      }
      setPrevPage(currentPage);
    }
    setCurrentPage(clickedPage);
    return;
    
  };
 

  const { items } = usePagination({
    count: questions ? Math.ceil(questions.length / 10) : 0,
    siblingCount: 20,
    hidePrevButton: true,
    hideNextButton: true,
    variant: "outlined",
    color: "primary",
    size: "small",
    defaultPage: 1,
    onChange: (e, value) => {
      gotoPage(value);
    },
  });

  const handleOpenSubmitModal = () => {
    const allQuestionsAreAnswered = Object.keys(answers).find(key => answers[key] === 0) === undefined;
    if ( allQuestionsAreAnswered ) {
      pageState[currentPage - 1] = "Answered-pagination Pagination-button-selected";
      setAllQuestionsAnswered(true);
    }
    document.getElementById("confirmSubmitModal").M_Modal.open();
  };

  return (
    <>
      {!(forbidden || deniedGDPR || denied || submitted || closed || genericError) &&
        <>
          <div className="Question-container Not-selectable" style={{overflowX: "hidden"}}>
            <div id="top-of-page" style={{minHeight: "100px"}}/>
            <ParticipantFormHeader items={items} isTabletOrMobile={isTabletOrMobile}/>
            {questions && currentPage && 
              !submitted &&
              <ParticipantFormQuestionList isTabletOrMobile={isTabletOrMobile}/>
            }
          </div>


        
          <div style={{display: "flex", justifyContent: "space-around"}}> 
            <ModalButton 
              buttonName={(currentPage === 1) ? "hide" : translation.prevButton[formLanguage].previous}
              type={"button"}
              onCancel={() => {gotoPage(currentPage-1);}}
            />  
          
            <ModalButton 
              buttonName={currentPage === pageState.length ? translation.nextButton[formLanguage].submit : translation.nextButton[formLanguage].next}
              buttonTrigger={currentPage === pageState.length && "border-modal-button" }
              type={"button"}
              style={{display: ""}}
              onCancel={() => {(currentPage === pageState.length) ? handleOpenSubmitModal() : gotoPage(currentPage+1);}}
            />
          </div>
        </>
      }

      <FinalFormModal
        modalHTMLIdentifier={"nextPageWithQuestionsRemainingModal"}
        inputFields={[]}
        initialValues={[]}
        onCancel={() => {
          const copyOfPageState = JSON.parse(JSON.stringify(pageState));
          copyOfPageState[currentPage - 1] = copyOfPageState[currentPage - 1].replace(" Pagination-button-selected", "");
          copyOfPageState[currentPage - 1] = copyOfPageState[currentPage - 1].replace("Pagination-button-selected", "");
          copyOfPageState[prevPage - 1] += " Pagination-button-selected";
          setPageState(copyOfPageState);
          setCurrentPage(prevPage);
        }}
        onClick={(e) => e.stopPropagation()}
        onSubmit={() => {
          markPageVisited();
          document
            .getElementById("nextPageWithQuestionsRemainingModal")
            .M_Modal.close();
          document.getElementById("top-of-page").scrollIntoView({ behavior: "smooth" });
        }}
        submitButtonName={translation.modalNextPageQuestionsRemaining[formLanguage].submit}
        cancelButtonName={translation.modalNextPageQuestionsRemaining[formLanguage].cancel}
        formInfo={{
          header: translation.modalNextPageQuestionsRemaining[formLanguage].header,
          body: translation.modalNextPageQuestionsRemaining[formLanguage].body,
        }}
      />

      <FinalFormModal
        modalHTMLIdentifier={"confirmSubmitModal"}
        inputFields={[]}
        initialValues={[]}
        onClick={(e) => e.stopPropagation()}
        onSubmit={submitFormService}
        submitButtonName={
          allQuestionsAnswered 
            ? translation.modalConfirmSubmit[formLanguage].submitButtonComplete 
            : translation.modalConfirmSubmit[formLanguage].submitButtonPartiallyComplete
        }
        cancelButtonName={
          allQuestionsAnswered 
            ? translation.modalConfirmSubmit[formLanguage].cancelButtonComplete 
            : translation.modalConfirmSubmit[formLanguage].cancelButtonPartiallyComplete
        }
        formInfo={{
          header: allQuestionsAnswered
            ? translation.modalConfirmSubmit[formLanguage].headerComplete
            : translation.modalConfirmSubmit[formLanguage].headerPartiallyComplete,
          body: allQuestionsAnswered
            ? translation.modalConfirmSubmit[formLanguage].bodyComplete
            : translation.modalConfirmSubmit[formLanguage].bodyPartiallyComplete,
          alignLeft: "POTATO"
        }}
      />
      
      {deniedGDPR && (
        <div className="header">
          {/* <img alt="adviser partner logo" className="logo" src={adviserpartnerlogo} /> */}
          <h5>Tack för att du tog dig tiden att gå in på länken och meddela oss att du inte vill delta.</h5>
        </div>
      )}
      {forbidden && (
        <FormAccess
          message={translation.formAccess[formLanguage].forbidden}
          language={formLanguage}
          isDone={false}
          email={supportEmail}
          isTabletOrMobile={isTabletOrMobile}
        />
      )}
      {denied && (
        <FormAccess
          message={translation.formAccess[formLanguage].denied}
          language={formLanguage}
          isDone={false}
          email={supportEmail}
          isTabletOrMobile={isTabletOrMobile}
        />
      )}
      {submitted && (
        <FormAccess
          message={translation.formAccess[formLanguage].submitted}
          language={formLanguage}
          isDone={true}
          email={supportEmail}
          isTabletOrMobile={isTabletOrMobile}
        />
      )}
      {closed && (
        <FormAccess
          message={translation.formAccess[formLanguage].closed}
          isDone={false}
          language={formLanguage}
          email={supportEmail}
          isTabletOrMobile={isTabletOrMobile}
        />
      )}
      {genericError && (
        <FormAccess
          message={translation.formAccess[formLanguage].genericError}
          isDone={false}
          email={supportEmail}
          language={formLanguage}
          isTabletOrMobile={isTabletOrMobile}
        />
      )}
    </>
  );
};

export default ParticipantFormQuestionaire;
