import React, { createContext, useState } from "react";
import { Auth } from "aws-amplify";
export const SuperUserContext = createContext();
export const SuperUserProvider = (props) => {
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const [routerState, setRouterState] = useState("undefined");
  const [loggedIn, setLoggedIn] = useState(false);
  
  const loadUserData = async () => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      setLoggedIn(true);
    } catch(err){
      if(err === "The user is not authenticated") {
        setLoggedIn(false);
        return;
      } else {
        throw new Error(err);
      }
    }

    setCurrentUser(user?.signInUserSession.accessToken.payload.username);
    const groups = user?.signInUserSession.accessToken.payload["cognito:groups"];
    if (groups?.includes("SuperUser")) {
      setIsSuperUser(true);
      setRouterState("SuperUser");
    } else if (groups?.includes("Consultant")){
      setRouterState("Consultant");
    }
  };


  const value = {
    loadUserData,
    currentUser, setCurrentUser,
    isSuperUser, setIsSuperUser,
    routerState, setRouterState,
    loggedIn, setLoggedIn
  };
  return (
    <SuperUserContext.Provider value={value}>
      {props.children}
    </SuperUserContext.Provider>
  );
};
