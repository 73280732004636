import React from "react";
import { UserProvider } from "../../Utils/contexts/UsersContext";
import Users from "../../components/Users";
import "./UserView.css";

const UserView = () => {
  return (
    <>
      {/* <div className="Top-margin"> */}
      {/* <div className="Customer-list-container"> */}
      <UserProvider>
        <Users />
      </UserProvider>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default UserView;
