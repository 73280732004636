import React, { useContext, useEffect, useState } from "react";
import ProjectListItem from "../ProjectListItem/ProjectListItem";
import M from "materialize-css";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import { validator } from "../../../Utils/functions/inputFieldValidation";
import CreateProject from "../../../Utils/functions/CreateProject";
import { CustomerContext } from "../../../Utils/contexts/CustomerContext";
import AddListItem from "../../General/AddListItem/AddListItem";
import { useSnackbar } from "notistack";
import Preloader from "../../General/Preloader/Preloader";


const ProjectList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    projects,
    fetchProjects,
    selectedCustomerId, 
    setSelectedProjectId,
    isFetchingProjects,
  } = useContext(CustomerContext);
  useEffect(() => {
    let elems = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });

    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, [projects]);

  const [newProjectId, setNewProjectId] = useState("");
  useEffect(() => {
    document.getElementById(`project-list-${newProjectId}`)?.scrollIntoView();
  }, [newProjectId]);
  const openAddProjectModal = () => {
    document.getElementById("addProjectModal").M_Modal.open();
    document.getElementById("addProjectName").focus();
  };

  const submitAddProjectForm = async (values, form) => {
    try {
      if(validator.validate({inputString: values.Projektnamn}).error !== undefined){
        createNotification("Felaktigt projektnamn", "error");
        return { "Projektnamn": "Felaktigt projektnamn" };
      };
      setSelectedProjectId(-1);
      const newProject = await CreateProject(selectedCustomerId, values.Projektnamn);
      document.getElementById("addProjectModal").M_Modal.close();
      createNotification(`"${values.Projektnamn}" har lagts till.`, "success");
      await fetchProjects(selectedCustomerId);
      form.reset();
      setSelectedProjectId(newProject.id);
      setNewProjectId(newProject.id);
    } catch (error) {
      // throw new Error("Internal server error.");
    }
  };

  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, {variant});
  };
  
  
  return (
    <div className="Card-list">
      <p className="header">Projekt</p>
      <div className="body">
        <div className="Items Card-min-width">
          {isFetchingProjects ? <Preloader/> : projects.sort((a, b) => a.projectName.localeCompare(b.projectName, "en", { sensitivity: "base" })).map((project) => (
            <ProjectListItem
              createNotification={createNotification}
              project={project}
              key={project.id}
            />
          ))}
        </div>
      </div>
            
      {selectedCustomerId !== -1 ? <AddListItem icon={"add"} text={"Lägg till nytt projekt"} onClick={openAddProjectModal} /> : <></>}

      <FinalFormModal
        //Must match data-targer of trigger
        modalHTMLIdentifier={"addProjectModal"}
        //variable amount of input fields as array
        inputFields={[
          {
            header: "Projektnamn",
            defaultValue: "",
            id: "addProjectName",
            placeHolder: "Skriv in projektnamn",
            type: "text",
            errorMessages: ["Felaktigt projektnamn"]
          }
        ]}
        //must match name of headers in input field array
        initialValues={[]}
        //u know wat onsubmit does lmao
        onSubmit={submitAddProjectForm}

        submitButtonName={"Spara nytt projekt"}
        cancelButtonName={"Avbryt"}
        formInfo={{
          header: "Skapa nytt projekt"
        }}
        inputfieldToFocus={"Projektnamn"}
      />
    </div >
  );
};

export default ProjectList;