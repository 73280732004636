import { API, graphqlOperation } from "aws-amplify";
import { createProject } from "../../graphql/mutations";
import { validator} from "./inputFieldValidation.js";

const CreateProject = async (customerId, projectName) => {

  const projectNameValidation = validator.validate({inputString: projectName});
  if(!(projectNameValidation.error === undefined)){
    throw new Error("Bad request");
  }
    
  try {
    const input = {
      customerId: customerId,
      projectName: projectName,
      // {email: "a", firstName: "asd", lastName: "asd", title: "sdd"}
    };

    const createdProject = await API.graphql(
      graphqlOperation(createProject,input)
    );
    return createdProject.data.createProject;
  } catch (error) {
    throw new Error("InternalServerError");
  }
};

export default CreateProject;