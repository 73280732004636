import { API, graphqlOperation } from "aws-amplify";
import { participantValidator } from "./inputFieldValidation.js";
import { updateProject } from "../../graphql/mutations";


const AddParticipants = async (project, participants) => {

  try {
    for(let i = 0; i < participants.length; i++) {
      const participantValidation = participantValidator.validate(participants[i]);
      if(!(participantValidation.error === undefined)){
        throw new Error("Bad request");
      }   
    }
  } catch (error) {
    throw error;
  }

  const updatedProject = await API.graphql(
    graphqlOperation(updateProject, {
      id: project.id,
      projectName: project.projectName,
      participants: participants
    })
  );
  return updatedProject;
  
};

export default AddParticipants;
