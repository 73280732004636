import React, { useEffect, useContext } from "react";
import { Form, Field } from "react-final-form";
import "../../General/FinalFormModal/FinalFormModal.css";
import { QuestionContext } from "../../../Utils/contexts/QuestionContext";
import { ModalInput } from "../../General/ModalInput/ModalInput";
import "./FinalFormQuestionModal.css"; 

const TestComponent = () => {
  const { questions, selectedQuestion,  language} = useContext(
    QuestionContext
  );
  
  const answerOptionInput = (index, formulation, language) => {
    let header = "";

    switch(language){
      case "EN":
        header = `Answer option: ${index + 1}`;
        break;
      default:
        header = `Svarsalternativ: ${index + 1}`;
    } 
    return {
      header,
      id: header,
      divName: "AnswerOptionInput",
      type: "text",
      labelName: "Text-left",
      placeHolder: formulation,
      style: {display: "flex", flexDirection: "column", width: "98%"},
    };
  };
  
  useEffect(() => {
  }, [language, selectedQuestion]);
  
  
  return (
    <>
      <div>
        {language.includes("SE") &&
        Object.entries(selectedQuestion?.translations.find(translation => translation.language.includes(language)).answerOptions).map(([answer, formulation], index) => {      
          
          const inputField = answerOptionInput(index, formulation, language);
          return (
            <div className="Input-column" key={answer}>   
              <Field  initialValue={selectedQuestion?.translations.find(translation => translation.language.includes(language)).answerOptions[answer]}  name={inputField.header} key={answer}>
                {({ input, meta }) => (
                  <ModalInput 
                    labelName="input-label" 
                    input={input} 
                    inputField={inputField} 
                    meta={meta}/>
                )}
              </Field>
            </div>
          );
        })}
      </div>
      <div> 
        {language.includes("EN") && 
        Object.entries(selectedQuestion?.translations.find(translation => translation.language.includes(language)).answerOptions).map(([answer, formulation], index) => {
          
          const inputField = answerOptionInput(index, formulation, language);
          return (
            <div className="Input-column" key={answer}>   
              <Field initialValue={selectedQuestion?.translations.find(translation => translation.language.includes(language)).answerOptions[answer]} name={inputField.header} key={answer} >
                {({ input, meta }) => (
                  <ModalInput 
                    labelName="input-label" 
                    input={input} 
                    inputField={inputField} 
                    meta={meta}/>
                )}
              </Field>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TestComponent;