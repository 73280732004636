import { API,graphqlOperation } from "aws-amplify";
import { createForm } from "../../graphql/mutations";

const CreateForm = async (formName, configuration, projectId) => {
  try {
    const createdForm = await API.graphql(
      graphqlOperation(createForm, {
        name: formName,
        configuration: configuration,
        projectId: projectId
      })
    );
    return createdForm.data.createForm;
  } catch (error) {
    throw new Error("Could not create form.");
  }
};

export default CreateForm;
