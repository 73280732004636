export const TRANSLATIONS = {
  PROJECT: {
    SWEDISH: "Projekt",
    ENGLISH: "Project"
  },
  FORM: {
    SWEDISH: "Formulär",
    ENGLISH: "?"
  },
  DROPZONELIST: {
    SWEDISH: "Dra formulär hit",
    ENGLISH: "Drop form here"
  },
  GENERATE_REPORT: {
    SWEDISH: "Generera rapport",
    ENGLISH: "Generate report"
  },
  GENERATING_REPORT: {
    SWEDISH: "GENERERAR RAPPORT",
    ENGLISH: "GENERATING REPORT"
  },
  CREATE_REPORT_HEADER:{
    SWEDISH: "Skapa rapport",
    ENGLISH: "Create report"
  },
  CHOOSE_CUSTOMER: {
    SWEDISH: "Kund",
    ENGLISH: "Customer"
  },
  LANGUAGE: {
    SWEDISH: "Språk",
    ENGLISH: "Language"
  },
  SWEDISH: {
    SWEDISH: "Svenska",
    ENGLISH: "Swedish"
  },
  ENGLISH: {
    SWEDISH: "Engelska",
    ENGLISH: "English"
  },
  NAME_OF_REPORT: {
    SWEDISH: "Rapportnamn",
    ENGLISH: "Name of report"
  },
  BAD_INPUT: {
    SWEDISH: "Felaktig input",
    ENGLISH: "Bad input"
  }

};
