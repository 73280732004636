import { Redirect, Route, Switch } from "react-router";
import {
  CUSTOMER_VIEW,
  FORM_VIEW,
  REPORT_VIEW,
  HELP_VIEW
} from "./constants/Routes";
import CustomerView from "./pages/CustomerView/CustomerView";
import FormView from "./pages/FormView/FormView";
import HelpView from "./pages/HelpView/HelpView";
import NotFound from "./pages/NotFound/NotFound";
import ReportView from "./pages/ReportView/ReportView";

export default function RouteConsultant() {
  return (
    <Switch>
      <Route strict exact path={`/${REPORT_VIEW}`} component={ReportView} />
      <Route strict exact path={`/${FORM_VIEW}`} component={FormView} />
      <Route strict exact path={`/${CUSTOMER_VIEW}`} component={CustomerView} />
      <Route strict exact path={`/${HELP_VIEW}`} component={HelpView} />
      <Redirect strict exact from={`/${REPORT_VIEW}/`} to={`/${REPORT_VIEW}`} component={ReportView} />
      <Redirect strict exact from={`/${FORM_VIEW}/`} to={`/${FORM_VIEW}`} component={FormView} />
      <Redirect strict exact from={`/${HELP_VIEW}/`} to={`/${HELP_VIEW}`} component={HelpView}/>
      <Redirect strict exact from={`/${CUSTOMER_VIEW}/`} to={`/${CUSTOMER_VIEW}`} component={CustomerView}/>
      <Redirect exact strict from={"/"} to={`/${CUSTOMER_VIEW}`} component={CustomerView} />
      <Route component={NotFound} />
    </Switch>
  );
}
