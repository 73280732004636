import React from "react";
import "./NotFound.css";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";

const NotFound = () => {

  const { enqueueSnackbar, closeSnackBar } = useSnackbar();

  const handleClick = () => {
    enqueueSnackbar("I love snacks.");
  };

  const handleClickVariant = (variant) => () => {
    // variant could be success, error, warning, info, or default
    // enqueueSnackbar("This is a success message!", { variant, closeSnackBar });

    const key = enqueueSnackbar("This is a success message!", {variant});

  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };


  return (
    <div>
      {/* <React.Fragment>
        <Button onClick={handleClick}>Show</Button>
        <Button onClick={handleClickVariant("success")}>Show success</Button>
      </React.Fragment> */}
      <p>404</p>
    </div>
  );
};

export default NotFound; //a
