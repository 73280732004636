import "./ReportView.css";
import ReportComponentParent from "../../components/Reports/ReportComponentParent/ReportComponentParent";
import { ReportProvider } from "../../Utils/contexts/ReportContext";

const ReportView = () => {
  return (
    <div className="Report-view-container">
      <ReportProvider>
        <ReportComponentParent />
      </ReportProvider>
    </div>
  );
};

export default ReportView;