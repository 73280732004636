import React, { useEffect, useState, useContext } from "react";
import FormListItem from "../FormListItem/FormListItem";
import FinalFormFormModal from "../../General/FinalFormFormModal/FinalFormFormModal";
import M from "materialize-css";
import { listTraits } from "../../../graphql/queries";
import CreateForm from "../../../Utils/functions/CreateForm";
import { API, graphqlOperation } from "aws-amplify";
import { FormContext } from "../../../Utils/contexts/FormContext";
import Preloader from "../../General/Preloader/Preloader";
import { useSnackbar } from "notistack";
import { validator } from "../../../Utils/functions/inputFieldValidation";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import { sendInvitationEmail } from "../../../graphql/queries";

const FormList = () => {
  const {
    customers,
    projects,
    invitationSwitch,
    setInvitationSwitch,
    forms,
    setParticipants,
    isFetchingForms,
    setSelectedFormId,
    selectedFormId,
    setIsFetchingForms,
    fetchForms,
    fetchParticipants,
    participants,
    selectedCustomerId,
    selectedProjectId,
  } = useContext(FormContext);
  const [traits, setTraits] = useState([]);
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [isInvalidDate, setIsInvalidDate] = useState(false);
  const [isInvalidTrait, setIsInvalidTrait] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isCreatingForm, setIsCreatingForm] = useState(false);
  const [initialLanguage, setInitialLanguage] = useState(false);
  const [language, setLanguage] = useState("Svenska");

  const fetchTraits = async () => {
    try {
      const traitData = await API.graphql(graphqlOperation(listTraits));
      const trait = traitData.data.listTraits.items;
      return trait;
    } catch (err) {}
  };

  useEffect(() => {
    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);

    const fetcher = () =>
      Promise.all([fetchTraits()]).then((data) => {
        setTraits(data[0].sort((a, b) => a.symbol.localeCompare(b.symbol)));
        setIsLoaded(true);
      });
    fetcher();
  }, [selectedProjectId]);

  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, { variant });
  };
  const openCreateQuestionModal = () => {
    setInitialLanguage(!initialLanguage);
    document.getElementById("CreateFormModal").M_Modal.open();
    document.getElementById("addFormName").focus();
  };

  return (
    <div className="Card-list">
      <p className="header">Formulär</p>
      <div className="body">
        <div className="Items Card-min-width">
          {isFetchingForms ? (
            <Preloader />
          ) : (
            forms.map((form, index) => (
              <FormListItem
                form={form} //ett formulär
                index={index}
                key={index}
              />
            ))
          )}
        </div>
      </div>

      {projects.find((project) => project.id === selectedProjectId) !==
        undefined && (
        <>
          {!isFetchingForms && isLoaded && (
            <p
              className="button"
              onClick={openCreateQuestionModal}
              data-testid={"oitatus"}
            >
              <i className="material-icons">add</i>Lägg till nytt formulär
            </p>
          )}

          <FinalFormFormModal
            modalHTMLIdentifier={"CreateFormModal"}
            customerName={
              customers.length > 0 &&
              customers.find((customer) => customer.id === selectedCustomerId)
                .companyName
            }
            projectName={
              projects.find((project) => project.id === selectedProjectId)
                .projectName
            }
            submitButtonName={"Skapa"}
            cancelButtonName={"Avbryt"}
            invitationSwitch={invitationSwitch}
            setInvitationSwitch={setInvitationSwitch}
            isLoaded={isLoaded}
            isFetchingForms={isFetchingForms}
            setIsFetchingForms={setIsFetchingForms}
            isInvalidName={isInvalidName}
            setIsInvalidName={setIsInvalidName}
            isInvalidDate={isInvalidDate}
            setIsInvalidDate={setIsInvalidDate}
            isInvalidTrait={isInvalidTrait}
            setLanguage={setLanguage}
            language={language}
            isCreatingForm={isCreatingForm}
            initialLanguage={initialLanguage}
            onSubmit={async (values, form) => {
              try {
                setIsInvalidName(false);
                const formNameValidation = validator.validate({
                  inputString: values.Name,
                });
                if (!(formNameValidation.error === undefined)) {
                  setIsInvalidName(true);
                  createNotification("Felaktigt formulärnamn.", "error");
                  return;
                }

                setIsInvalidDate(false);
                setIsInvalidTrait(false);
                const aggregatedErrorMsg = {};
                if (values.Deadline <= values.Reminder) {
                  aggregatedErrorMsg["Reminder"] =
                    "Deadline måste vara efter påminnelse";
                }

                if (values.Deadline <= values.Invitation && invitationSwitch) {
                  aggregatedErrorMsg["Invitation"] =
                    "Deadline måste vara efter inbjudning";
                }

                if (
                  values.Deadline <= values.Reminder ||
                  values.Deadline <= values.Invitation
                ) {
                  setIsInvalidDate(true);
                  createNotification("Ogiltigt datum.", "error");
                  document.getElementById("CreateFormModal").scrollIntoView();
                  return aggregatedErrorMsg;
                }

                let hasAnyValidTrait = false;
                let chosenTraits = [];
                for (const trait of traits) {
                  if (values[trait.symbol] !== undefined) {
                    chosenTraits.push(trait.id);
                    hasAnyValidTrait = true;
                  }
                }

                if (!hasAnyValidTrait) {
                  setIsInvalidTrait(true);
                  createNotification("Välj minst en kategori.", "error");
                  document.getElementById().scrollIntoView("CreateFormModal");
                  return;
                }

                let languageInput = "";
                if (language === "Svenska") {
                  languageInput = "SE";
                } else {
                  languageInput = "EN";
                }

                const configuration = {
                  language: languageInput,
                  traitIds: chosenTraits,
                  deadline: values.Deadline,
                  reminder: values.Reminder,
                  invitation: invitationSwitch ? values.Invitation : null,
                };
                const name = values.Name;
                const projectId = projects.find(
                  (project) => project.id === selectedProjectId
                ).id;
                setIsCreatingForm(true);
                const newFormId = await CreateForm(
                  name,
                  configuration,
                  projectId
                );

                createNotification(`"${name}" har skapats.`, "success");
                setIsCreatingForm(false);
                document.getElementById("CreateFormModal").M_Modal.close();
                setParticipants([]);
                await fetchForms(selectedProjectId);
                setSelectedFormId(newFormId);
                document
                  .getElementById(`form-list-${newFormId}`)
                  .scrollIntoView();
                await fetchParticipants(newFormId);
                form.reset();
              } catch {
                setIsCreatingForm(false);
              }
            }}
            checkboxes={traits}
          />
          <FinalFormModal
            //Must match data-targer of trigger
            modalHTMLIdentifier={"sendInvitationEmailModal"}
            //variable amount of input fields as array
            inputFields={[]}
            //must match name of headers in input field array
            initialValues={[]}
            //u know wat onsubmit does lmao
            onClick={(e) => e.stopPropagation()}
            onSubmit={async () => {
              const form = forms.find((form) => form.id === selectedFormId);
              try {
                const response = (
                  await API.graphql(
                    graphqlOperation(sendInvitationEmail, {
                      formId: selectedFormId,
                    })
                  )
                ).data.sendInvitationEmail;
                response === "ok"
                  ? createNotification(
                      `Inbjudan skickades till deltagare i "${form?.name}".`,
                      "success"
                    )
                  : createNotification(
                      `Alla deltagare i "${form?.name} är redan inbjudna".`,
                      "error"
                    );
                fetchParticipants(selectedFormId);

                document
                  .getElementById("sendInvitationEmailModal")
                  .M_Modal.close();
              } catch (error) {
                setErrorMessage("Kunde inte skicka, försök igen senare");
                createNotification(
                  "Kunde inte skicka, försök igen senare.",
                  "error"
                );
              }
            }}
            submitButtonName={"Skicka"}
            cancelButtonName={"Avbryt"}
            formInfo={{
              header: (
                <>
                  <div style={{ display: "flex", color: "rgb(219, 75, 26)" }}>
                    <i className="material-icons">warning</i>
                    <p
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingLeft: "1%",
                      }}
                    >
                      Du kan inte ändra deltagare i ett formulär, dubbelkolla
                      att all information stämmer innan du skickar inbjudningar.
                    </p>
                  </div>
                  <br />
                  <br />
                  <p style={{ fontWeight: "bold" }}>
                    {"Dessa deltagare kommer att få en inbjudan:"}
                  </p>
                  <br />
                  {participants?.map((participant, index) => {
                    return (
                      <div key={index}>
                        <p>{`${index + 1}:  ${participant.email}`}</p>
                        <br />
                      </div>
                    );
                  })}
                </>
              ),
            }}
          />
        </>
      )}
    </div>
  );
};

export default FormList;
