import { API, graphqlOperation } from "aws-amplify";
import { updateCustomer2 } from "../../graphql/mutations";
import { validator } from "./inputFieldValidation.js";

const UpdateCustomerName = async (customerId, newCustomerName) => {
  const customerNameValidation = validator.validate({ inputString: newCustomerName });
  if (!(customerNameValidation.error === undefined)) {
    throw new Error("Bad request");
  }

  try {
    const input = {
      id: customerId,
      companyName: newCustomerName
    };

    const updatedCustomer = await API.graphql(
      graphqlOperation(updateCustomer2, input)
    );
    return updatedCustomer;
  } catch (error) {
    throw new Error(error.errors[0].message);
  }
};

export default UpdateCustomerName;
