import React, {useEffect} from "react";
import { Form, Field } from "react-final-form";
import "./FinalFormFormModal.css";
import "../../../Utils/CSS/modals.css";
import { dateValidator } from "../../../Utils/functions/inputFieldValidation";
import ModalButton from "../ModalButton/ModalButton";
import { ModalInput } from "../ModalInput/ModalInput";
import { useSnackbar } from "notistack";
import Preloader from "../Preloader/Preloader";
import M from "materialize-css";

const FinalFormFormModal = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const formNameInput = {
    header: "Formulärsnamn",
    id: "addFormName",
    placeHolder: "Fyll i formulärsnamn",
    type: "text",
    divName: "Modal-input",
    labelName: "Text-left",
  };

  const formDeadlineInput = {
    header: "Deadline",
    id: "addFormDeadline",
    placeholder: "YYYY-MM-DD",
    type: "date",
    divName: "Modal-input",
    minDate: new Date().toJSON().split("T")[0],
  };
  const formInvitationInput = {
    header: "Automatisk Inbjudan",
    id: "addFormInvitation",
    divName: "Modal-input",
    type: "date",
    placeHolder: "YYYY-MM-DD",
    onDateChange: {
      name: "Deadline",
      difference: 7,
    },
    minDate: new Date().toJSON().split("T")[0],
    disabled: !props.invitationSwitch,
  };
  const formReminderInput = {
    header: "Automatisk Påminnelse",
    id: "addFormReminder",
    divName: "Modal-input",
    type: "date",
    placeHolder: "YYYY-MM-DD",
    onDateChange: {
      name: "Deadline",
      difference: 2,
    },
    minDate: new Date().toJSON().split("T")[0],
  };

  const createNotification = (text, variant, autoHideDuration) => {
    enqueueSnackbar(`${text}`, { variant, autoHideDuration });
  };

  const init = {
    Name: props.projectName,
  };

  props.checkboxes.map((checkbox) => {
    init[checkbox.symbol] = "isChecked";
  });

  const date = new Date();
  date.setDate(date.getDate() + 7);
  init["Deadline"] = date.toISOString().split("T")[0];
  date.setDate(date.getDate() - 2);
  init["Reminder"] = date.toISOString().split("T")[0];
  date.setDate(date.getDate() - 4);
  init["Invitation"] = date.toISOString().split("T")[0];
  
  useEffect(() => {
    const languageDropdown = document.getElementById("LanguageDropdown");
    languageDropdown.value = ("Svenska");
    props.setLanguage("Svenska");
    M.FormSelect.init(languageDropdown);
  }, [props.initialLanguage]);

  return (
    <div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        id={props.modalHTMLIdentifier}
        className="Min-width-final-form-form modal backdrop-static big"
      >
        <div
          
          id={props.modalHTMLIdentifier}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              {!props.isCreatingForm ? (
                <Form
                  onSubmit={props.onSubmit}
                  initialValues={init}
                  keepDirtyOnReinitialize={false}
                  render={({
                    handleSubmit,
                    form,
                    submitting,
                    submitErrors,
                    pristine,
                    values,
                  }) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5
                          className="Text-left"
                          id={"props.modalHTMLIdentifiermodalHeader"}
                        >
                          {!props.isCreatingForm
                            ? "Skapa formulär"
                            : "Skapar formulär"}
                        </h5>
                        <div>
                          <i
                            className="material-icons modal-close Close-cross-button"
                            onClick={() => {
                              form.reset();
                              props.onCancel && props.onCancel();
                            }}
                          >
                            close
                          </i>
                        </div>
                      </div>
                      <form onSubmit={(values) => handleSubmit(values, form)}>
                        <div className="Input-container Text-left Spacing-top">
                          <div className="Input-column">
                            <div className="Text-left" style={{width: "45%"}}>
                              <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                <label>
                                  Kund:{" "}
                                  <span className="Text-black">
                                    {props.customerName}
                                  </span>
                                </label>
                              </div>
                              <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                <label>
                                  Projekt:{" "}
                                  <span className="Text-black">
                                    {props.projectName}
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="Flex-column Modal-input2">
                              <label>Språk</label>
                              <select
                                id="LanguageDropdown"
                                className="Drop-down-selector Language hideSelector"
                                onChange={(e) => props.setLanguage(e.target.value)}
                              >
                                <option value={"Svenska"}>Svenska</option>
                                <option value={"English"}>English</option>
                              </select>
                            </div>
                          </div>
                          <div className="Input-column">
                            <Field
                              name={"Name"}
                              initialValue={props.projectName}
                            >
                              {({ input, meta }) => (
                                <ModalInput
                                  inputField={formNameInput}
                                  input={input}
                                  meta={meta}
                                />
                              )}
                            </Field>
                            <Field
                              name={"Deadline"}
                              validate={(value) => {
                                const dateValidation = dateValidator.validate(
                                  value
                                );
                                if (!(dateValidation.error === undefined)) {
                                  return (
                                    <p
                                      style={{
                                        color: "#FF0000",
                                      }}
                                    >
                                      Fyll i ett framtida datum
                                    </p>
                                  );
                                }

                                return undefined;
                              }}
                            >
                              {({ input, meta }) => (
                                <ModalInput
                                  inputField={formDeadlineInput}
                                  input={input}
                                  meta={meta}
                                />
                              )}
                            </Field>
                          </div>
                          {props.isInvalidName && (
                            <p
                              style={{
                                color: "#FF0000",
                              }}
                            >
                              Felaktigt formulärsnamn
                            </p>
                          )}
                          <div className="Input-column Spacing-top">
                            <div className="switch">
                              <label>
                                <input
                                  type="checkbox"
                                  defaultChecked={props.invitationSwitch}
                                  onChange={(e) => {
                                    props.setInvitationSwitch(e.target.checked);
                                  }}
                                />
                                <span className="lever"></span>
                              </label>
                            </div>
                            <Field
                              name={"Invitation"}
                              parse={(value) =>
                                props.invitationSwitch ? value : null
                              }
                              validate={(value) => {
                                if (!props.invitationSwitch) {
                                  return undefined;
                                }
                                const dateValidation = dateValidator.validate(
                                  value
                                );
                                if (!(dateValidation.error === undefined)) {
                                  return (
                                    <p
                                      style={{
                                        color: "#FF0000",
                                      }}
                                    >
                                      Fyll i ett framtida datum
                                    </p>
                                  );
                                }

                                return undefined;
                              }}
                            >
                              {({ input, meta }) => (
                                <ModalInput
                                  inputField={formInvitationInput}
                                  input={input}
                                  meta={meta}
                                />
                              )}
                            </Field>
                            <Field
                              name={"Reminder"}
                              validate={(value) => {
                                const dateValidation = dateValidator.validate(
                                  value
                                );
                                if (!(dateValidation.error === undefined)) {
                                  return (
                                    <p
                                      style={{
                                        color: "#FF0000",
                                      }}
                                    >
                                      Fyll i ett framtida datum
                                    </p>
                                  );
                                }

                                return undefined;
                              }}
                            >
                              {({ input, meta }) => (
                                <ModalInput
                                  inputField={formReminderInput}
                                  input={input}
                                  meta={meta}
                                />
                              )}
                            </Field>
                          </div>
                          {/* {props.isInvalidInvitationOrDeadline || props.isInvalidReminderOrDeadline && (
                            <div className="Input-column Spacing-top">
                              {props.isInvalidInvitationOrDeadline && (
                                <p style={{ color: "#FF0000" }}>
                                  Deadline måste vara efter inbjudan.
                                </p>
                              )}
                              {props.isInvalidReminderOrDeadline && (
                                <p style={{ color: "#FF0000" }}>
                                Deadline måste vara efter påminnelse.
                                </p>
                              )}
                            </div>
                          )} */}
                          <div className="Spacing-top">
                            <label>Områden/Kategorier</label>
                          </div>
                          <div className="Trait-container-form">
                            <ul>
                              {props.isLoaded &&
                                props.checkboxes.map((checkbox, key) => (
                                  <li key={key}>
                                    <label className="item" key={key}>
                                      <Field
                                        className="filled-in"
                                        defaultValue="isChecked"
                                        name={checkbox.symbol}
                                        key={checkbox.id + "" + key}
                                        component="input"
                                        type="checkbox"
                                        format={(value) =>
                                          value === "isChecked"
                                        }
                                        parse={(value) =>
                                          value ? "isChecked" : undefined
                                        }
                                      />{" "}
                                      <span key={key}>
                                        {`${checkbox.symbol}. ${checkbox.name[0].value}`}
                                      </span>
                                    </label>
                                  </li>
                                ))}
                            </ul>
                          </div>
                          {props.isInvalidTrait && (
                            <div className="Input-column Spacing-top">
                              <p style={{ color: "#FF0000" }}>
                                Välj minst en kategori.
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="modal-footer border-top-0 Footer-container">
                          <ModalButton
                            buttonName={props.cancelButtonName}
                            buttonType={"button"}
                            disabled={submitting}
                            onCancel={() => {
                              props.setIsInvalidName(false);
                              form.reset();
                            }}
                            buttonTrigger="modal-close"
                          />
                          <ModalButton
                            buttonName={props.submitButtonName}
                            buttonType={"submit"}
                            disabled={submitting}
                            buttonTrigger=""
                          />
                        </div>
                      </form>
                    </>
                  )}
                />
              ) : (
                <Preloader size="small" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalFormFormModal;
