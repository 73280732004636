import React, { createContext, useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  getCustomerProjects,
  listCustomers,
  getExcelTemplate,
} from "../../graphql/queries";
import {
  getAllCustomers,
  getUserCustomers,
} from "../../Utils/functions/api/usersApi";
import { deleteCustomer2 } from "../../graphql/mutations";
import { SuperUserContext } from "./SuperUserContext";
export const CustomerContext = createContext();
export const CustomerProvider = (props) => {
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(-1);
  const [selectedProjectId, setSelectedProjectId] = useState(-1);
  const [selectedParticipantId, setSelectedParticipantId] = useState(-1);
  const [isFetchingProjects, setIsFetchingProjects] = useState(false);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
  const [filter, setFilter] = useState("");
  const [showOnlyMyCustomers, setShowOnlyMyCustomers] = useState(true);
  const { currentUser } = useContext(SuperUserContext);

  const fetchCustomers = async () => {
    try {
      setIsFetchingCustomers(true);
      const customerData = await getAllCustomers();
      const customers = customerData.data.listCustomers.items;
      setCustomers(customers);
      setSelectedProjectId(-1);
      setProjects([]);
      setSelectedParticipantId(-1);
      setIsFetchingCustomers(false);
    } catch (err) {
      setIsFetchingCustomers(false);
    }
  };

  const fetchUserCustomers = async (userId) => {
    try {
      setIsFetchingCustomers(true);
      const customerData = await getUserCustomers(userId);
      const customers = customerData.data.listCustomers.items;
      setCustomers(customers);
      setSelectedProjectId(-1);
      setProjects([]);
      setSelectedParticipantId(-1);
      setIsFetchingCustomers(false);
    } catch (err) {
      setIsFetchingCustomers(false);
    }
  };

  const fetchTemplate = async () => {
    const response = await API.graphql(graphqlOperation(getExcelTemplate));
    const url = response.data.getExcelTemplate;
    var tempElement = document.createElement("a");
    tempElement.href = url;
    tempElement.setAttribute("download", "DeltagarMall");
    tempElement.click();
  };

  const temporaryDelete = async (id) => {
    const response = await API.graphql(
      graphqlOperation(deleteCustomer2, {
        id: id,
      })
    );
    document.getElementById("removeCustomerModal").M_Modal.close();
    if (response.data.deleteCustomer2.includes("ok")) {
      if (selectedCustomerId === id) {
        setSelectedCustomerId(-1);
      }
      setSelectedProjectId(-1);
      setProjects([]);
      setSelectedParticipantId(-1);
    }
  };

  const fetchProjects = async (customerId) => {
    try {
      setIsFetchingProjects(true);
      const projectData = await API.graphql(
        graphqlOperation(getCustomerProjects, { customerId })
      );
      const updatedProjects = projectData.data.getCustomerProjects;
      setProjects(updatedProjects);
      setIsFetchingProjects(false);
    } catch (err) {
      setIsFetchingProjects(false);
    }
  };

  const toggleShowOnlyMyCustomers = () => {
    setSelectedCustomerId(-1);
    setSelectedProjectId(-1);
    setSelectedParticipantId(-1);
    if (showOnlyMyCustomers) {
      fetchCustomers();
      setShowOnlyMyCustomers(false);
    } else {
      fetchUserCustomers(currentUser);
      setShowOnlyMyCustomers(true);
    }
  };

  const value = {
    customers,
    setCustomers,
    projects,
    setProjects,
    fetchTemplate,
    fetchProjects,
    temporaryDelete,
    fetchCustomers,
    fetchUserCustomers,
    filter,
    setFilter,
    isLoaded,
    setIsLoaded,
    selectedCustomerId,
    setSelectedCustomerId,
    selectedProjectId,
    setSelectedProjectId,
    selectedParticipantId,
    setSelectedParticipantId,
    isFetchingCustomers,
    setIsFetchingCustomers,
    isFetchingProjects,
    setIsFetchingProjects,
    showOnlyMyCustomers,
    toggleShowOnlyMyCustomers,
  };
  return (
    <CustomerContext.Provider value={value}>
      {props.children}
    </CustomerContext.Provider>
  );
};
