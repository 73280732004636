import React, { useContext, useEffect } from "react";
import "./SequenceItem.css";
import { Draggable, Droppable } from "react-beautiful-dnd";


const SequenceItem = ({ sequencePosition }) => {
  useEffect(() => {}, []);
  return (
    <div className="Item-card-question">
      <div
        className={"body"}
      >
        <div className="Q-Body-container">
          <p>
            {sequencePosition}
          </p>
          <i className="material-icons Drag-handler">drag_handle</i>
        </div>
      </div>
    </div>
  );
};

export default SequenceItem;
