import { API, graphqlOperation } from "aws-amplify";
import ApiError from "../../error/ApiError";
import {
  makeUserSuperUser as promoteUserQuery,
  removeUserFromSuperUser as demoteUserQuery,
  createUser as createUserQuery,
  deleteUser as deleteUserQuery,
} from "../../../graphql/mutations";
import {
  getUsers as getUsersQuery,
  listCustomers,
} from "../../../graphql/queries";

export const getUsers = async () => {
  try {
    const userData = await API.graphql(graphqlOperation(getUsersQuery));
    return userData;
  } catch (err) {
    throw new ApiError("Error when getting users", err);
  }
};

export const createUser = async (email) => {
  try {
    const createdUser = await API.graphql(
      graphqlOperation(createUserQuery, { email })
    );
    return createdUser;
  } catch (err) {
    throw new ApiError("Error when creating user", err);
  }
};

export const deleteUser = async (username) => {
  try {
    const deletedUser = await API.graphql(
      graphqlOperation(deleteUserQuery, { username })
    );
    return deletedUser;
  } catch (err) {
    throw new ApiError("Error when deleting user", err);
  }
};

export const promoteUser = async (username) => {
  try {
    const promotedUser = await API.graphql(
      graphqlOperation(promoteUserQuery, { username })
    );
    return promotedUser;
  } catch (err) {
    throw new ApiError("Error when promoting user to super-user", err);
  }
};

export const demoteUser = async (username) => {
  try {
    const demotedUser = await API.graphql(
      graphqlOperation(demoteUserQuery, { username })
    );
    return demotedUser;
  } catch (err) {
    throw new ApiError("Error when demoting super-user to user", err);
  }
};

export const getUserCustomers = async (userId) => {
  try {
    const customerData = await API.graphql(
      graphqlOperation(listCustomers, {
        filter: { consultant: { eq: userId } },
      })
    );
    let nextToken = customerData.data.listCustomers.nextToken;
    while (nextToken) {
      const additionalData = await API.graphql(
        graphqlOperation(listCustomers, {
          filter: { consultant: { eq: userId } },
          nextToken: nextToken,
        })
      );
      customerData.data.listCustomers.items = [
        ...customerData.data.listCustomers.items,
        ...additionalData.data.listCustomers.items,
      ];
      nextToken = additionalData.data.listCustomers.nextToken;
    }
    return customerData;
  } catch (err) {
    throw new ApiError("Error when getting user customers", err);
  }
};

export const getAllCustomers = async () => {
  try {
    const customerData = await API.graphql(graphqlOperation(listCustomers));
    let nextToken = customerData.data.listCustomers.nextToken;
    while (nextToken) {
      const additionalData = await API.graphql(
        graphqlOperation(listCustomers, { nextToken: nextToken })
      );
      customerData.data.listCustomers.items = [
        ...customerData.data.listCustomers.items,
        ...additionalData.data.listCustomers.items,
      ];
      nextToken = additionalData.data.listCustomers.nextToken;
    }
    return customerData;
  } catch (err) {
    throw new ApiError("Error when getting all customers", err);
  }
};
