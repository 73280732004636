import { useContext, useEffect, useState } from "react";
import CustomerListItem from "../CustomerListItem/CustomerListItem";
import M from "materialize-css";
import { FORM_ERROR } from "final-form";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import { validator } from "../../../Utils/functions/inputFieldValidation";
import CreateCustomer from "../../../Utils/functions/CreateCustomer";
import { CustomerContext } from "../../../Utils/contexts/CustomerContext";
import AddListItem from "../../General/AddListItem/AddListItem";
import { useSnackbar } from "notistack";
import Preloader from "../../General/Preloader/Preloader";
import UpdateCustomerName from "../../../Utils/functions/UpdateCustomerName";
import { SuperUserContext } from "../../../Utils/contexts/SuperUserContext";


const CustomerList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { 
    temporaryDelete, 
    fetchProjects, 
    customers,
    fetchCustomers, 
    fetchUserCustomers, 
    selectedCustomerId,  
    setSelectedCustomerId, 
    isFetchingCustomers, 
    filter, 
    showOnlyMyCustomers} = useContext(
    CustomerContext
  );
  const {
    currentUser } = useContext(
    SuperUserContext
  );
  const [newCustomerId, setNewCustomerId] = useState("");
  useEffect(() => {
    let elems = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });

    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, [customers]);

  useEffect(() => {
    document.getElementById(`customer-list-${newCustomerId}`)?.scrollIntoView();
  }, [newCustomerId]);
  const customer = customers.find(c => c.id === selectedCustomerId);
  const submitCreateCustomerForm = async (values, form) => {
    try {
      const customerNameValidation = validator.validate({inputString: values.Kundnamn});
      if(customerNameValidation.error !== undefined){
        createNotification("Felaktigt kundnamn.","error");
        return { "Kundnamn": "Felaktigt kundnamn" };
      };
      const newCustomer = await CreateCustomer(values.Kundnamn);
      document.getElementById("AddCustomerModal").M_Modal.close();
      if (showOnlyMyCustomers) {
        await fetchUserCustomers(currentUser);
      } else {
        await fetchCustomers();
      }
      form.reset();
      setSelectedCustomerId(newCustomer.id);
      setNewCustomerId(newCustomer.id);
      createNotification(`"${values.Kundnamn}" har lagts till.`, "success");
    } catch (error) {
      if (error.errors[0].message === "Conflict") {
        createNotification("Kunden existerar redan.","error");
        return { "Kundnamn": "Kunden existerar redan." };
      }
    }
  };
  const submitDeleteCustomerModal = async () => {
    await temporaryDelete(customer.id);
    createNotification(`"${customer.companyName}" har raderats.`, "success");
    
    if (showOnlyMyCustomers) {
      await fetchUserCustomers(currentUser);
    } else {
      await fetchCustomers();
    }
    if(customer.id !== selectedCustomerId) fetchProjects(selectedCustomerId);
  };

  const submitUpdateCustomerModal = async (values, form) => {
    try {
      if (validator.validate({ inputString: values["Ändra Kundnamn"] }).error !== undefined) {
        createNotification("Felaktigt kundnamn.","error");
        return {"Ändra Kundnamn": "Felaktigt kundnamn"};
      }
      await UpdateCustomerName(customer.id, values["Ändra Kundnamn"]);
      createNotification(`"${customer.companyName}" har ändrats till "${values["Ändra Kundnamn"]}".`, "success");
      document.getElementById("UpdateCustomerNameModal").M_Modal.close();
      if (showOnlyMyCustomers) {
        await fetchUserCustomers(currentUser);
      } else {
        await fetchCustomers();
      }
      form.reset();
      fetchProjects(customer.id);
    } catch (error) {
      if (error.message === "Conflict") {
        createNotification("Kunden existerar redan.","error");
        return { "Ändra Kundnamn": "Kunden existerar redan." };
      }
    }
  };
  

  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, { variant });
  };

  const openAddCustomerModal = () => {
    try{
      document.getElementById("AddCustomerModal").M_Modal.open();
      document.getElementById("Kundnamn").focus();
    }catch(e){

    }
  };

  return (
    <div className="Card-list">
      <p className="header">Kund</p>
      <div className="body">
        <div className="Items Card-min-width">
          {isFetchingCustomers ? <Preloader/> : customers &&
            customers.filter(c => c.companyName.toLowerCase().includes(filter.toLowerCase()) || c.consultant.toLowerCase().includes(filter.toLowerCase())).sort((a, b) => a.companyName.localeCompare(b.companyName, "en", { sensitivity: "base" })).map((cust) => (
              <CustomerListItem customer={cust} key={cust.id}/>
            ))}
        </div>
        <AddListItem
          icon={"add"}
          text={"Lägg till ny kund"}
          onClick={openAddCustomerModal}
        />
      </div>
      <FinalFormModal
        //Must match data-targer of trigger
        modalHTMLIdentifier={"AddCustomerModal"}
        //variable amount of input fields as array
        inputFields={[
          {
            header: "Kundnamn",
            id: "Kundnamn",
            defaultValue: "",
            placeHolder: "Skriv in kundnamn",
            type: "text",
            errorMessages: ["Felaktigt kundnamn", "Kunden existerar redan."]  
          },
        ]}
        //must match name of headers in input field array
        initialValues={{
          Kundnamn: "",
        }}
        onSubmit={submitCreateCustomerForm}
        submitButtonName={"Spara ny kund"}
        cancelButtonName={"Avbryt"}
        formInfo={{
          header: "Lägg till kund",
        }}
        inputfieldToFocus={"Kundnamn"}
      />
      <FinalFormModal
        //Must match data-targer of trigger
        modalHTMLIdentifier={"UpdateCustomerNameModal"}
        //variable amount of input fields as array
        inputFields={[
          {
            header: "Ändra Kundnamn",
            id: "Ändra Kundnamn",
            defaultValue: "",
            placeHolder: "Skriv in kundnamn",
            type: "text",
            errorMessages: ["Felaktig input", "Kunden existerar redan."],
          },
        ]}
        //must match name of headers in input field array
        initialValues={{
          "Ändra Kundnamn": customer?.companyName,
        }}
        //u know wat onsubmit does lmao
        onSubmit={submitUpdateCustomerModal}
        onCancel={async () => {
          await fetchProjects(customer.id);
          document.getElementById("UpdateCustomerNameModal").M_Modal.close();
        }}
        submitButtonName={"Spara"}
        cancelButtonName={"Avbryt"}
        formInfo={{
          header: `Byt namn på ${customer?.companyName}`
        }}
      />
      <FinalFormModal
        //Must match data-targer of trigger
        modalHTMLIdentifier={"removeCustomerModal"}
        //variable amount of input fields as array
        inputFields={[]}
        //must match name of headers in input field array
        initialValues={[]}
        //u know wat onsubmit does lmao
        onClick={(e) => e.stopPropagation()}
        onSubmit={submitDeleteCustomerModal}

        submitButtonName={"Ja"}
        cancelButtonName={"Nej"}
        formInfo={{
          header: `Vill du ta bort ${customer?.companyName}?`
        }}
      />
      {/* classnames, ett lib som är användbart. */}
      
    </div>
  );
};

export default CustomerList;
