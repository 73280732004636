import React from "react";
import GenericErrorPage from "./pages/GenericErrorPage/GenericErrorPage";
import { withRouter } from "react-router";

export default withRouter(class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
    const { history } = this.props;

    history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <GenericErrorPage error={this.state.error} />;
    }

    return this.props.children;
  }
});
