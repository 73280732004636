import React, { useEffect, useContext } from "react";
import QuestionList from "../QuestionList/QuestionList";
import "../../../Utils/CSS/cards.css";
import { QuestionContext } from "../../../Utils/contexts/QuestionContext";
import "./QuestionComponentParent.css";
import Preloader from "../../General/Preloader/Preloader";

const QuestionComponentParent = () => {

  const {
    fetchQuestions, fetchTraits,
    isLoaded, setIsLoaded,
  } = useContext(QuestionContext);


  useEffect(() => {
    setIsLoaded(false);
    const fetcher = async () => {
      await Promise.all([fetchQuestions(), fetchTraits()]);
      setIsLoaded(true);
    };
    fetcher();
  }, []);

  return (

    <div>
      <div className="Q-Item-list-container Q-item-list-min-width">
        <div>
          {(!isLoaded) ? <Preloader size="large"/> : <QuestionList/>}
        </div>
      </div>
    </div>
  );
};

export default QuestionComponentParent;
