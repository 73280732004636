import React, { useContext } from "react";
import { ParticipantFormContext } from "../../../Utils/contexts/ParticipantFormContext";
import { translation } from "../ParticipantFormTranslations";
import ModalButton from "../../General/ModalButton/ModalButton";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";

const ParticipantFormInformationPage = (isTabletOrMobile) => {
  const { setHasRead, formLanguage } = useContext(ParticipantFormContext);

  return (
    <>
      <LanguageSwitch />
      <div
        className="GDPR-container"
        style={{ margin: isTabletOrMobile.isTabletOrMobile ? "5%" : "10% 30% 10% 30%" }}
      >
    
        <div className="GDPR">
          <h4>{translation.informationPage[formLanguage].header}</h4>
          <div style={{textAlign: "left"}}>
            <p>{translation.informationPage[formLanguage].body[0]}</p><br/>
            <p>{translation.informationPage[formLanguage].body[1]}</p><br/>
            <p>{translation.informationPage[formLanguage].body[2]}</p>
          </div>
          <span className="body-text" style={{display: "flex", justifyContent: "flex-end", marginTop:"5%"}}>
            <ModalButton
              buttonName={translation.informationPage[formLanguage].button}
              buttonType={"button"}
              onCancel={()=>setHasRead(true)}
              type="button"
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default ParticipantFormInformationPage;
