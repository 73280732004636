import React from "react";
import M from "materialize-css";
import "../../General/FinalFormModal/FinalFormModal.css";
import "./FinalFormQuestionModal.css"; 
import CreateQuestionForm from "./CreateQuestionForm";

const FinalFormCreateQuestionModal = (props) => {

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      id={props.modalHTMLIdentifier}
      data-testid={props.modalHTMLIdentifier}
      className="modal Q-modal backdrop-static"
    >
      <div
        // className="modal fade"
        id={props.modalHTMLIdentifier + "dialog"}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
            </div>
            <CreateQuestionForm submitButtonName={props.submitButtonName} cancelButtonName={props.cancelButtonName} formInfo={props.formInfo} inputFields={props.inputFields} onSubmit={props.onSubmit}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalFormCreateQuestionModal;
