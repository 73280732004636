import React, { useContext } from "react";
import FormInfoListItem from "../FormInfoListItem/FormInfoListItem";
import { FormContext } from "../../../Utils/contexts/FormContext";
import Preloader from "../../General/Preloader/Preloader";

const FormInfoList = () => {
  const { 
    participants,
    isFetchingParticipants } = useContext(FormContext);

  return (
    <div className="Card-list">
      <p className="header">Status</p>
      <div className="body">
        <div className="Items Card-min-width">

          {isFetchingParticipants ? <Preloader/> : participants.map((participant) => {
            return(
              <FormInfoListItem
                participant={participant}
                key={participant?.id}
              />);
          })}

        </div>
      </div>
    </div>
  );
};

export default FormInfoList;
