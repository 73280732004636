import React, { useEffect, useContext, useState } from "react";
import CustomerList from "../CustomerList/CustomerList";
import ProjectList from "../ProjectList/ProjectList";
import ParticipantList from "../ParticipantList/ParticipantList";
import ParticipantInfoList from "../ParticipantInfoList/ParticipantInfoList";
import "../../../Utils/CSS/cards.css";
import { CustomerContext } from "../../../Utils/contexts/CustomerContext";
import FilterBanner from "../../General/FilterBanner/FilterBanner";
import { SuperUserContext } from "../../../Utils/contexts/SuperUserContext";

const CustomerComponentParent = () => {

  const {
    fetchCustomers,
    fetchUserCustomers,
    isLoaded,
    showOnlyMyCustomers,
    toggleShowOnlyMyCustomers,
    setIsLoaded,
    setSelectedParticipantId,
    setSelectedProjectId,
    setProjects,
    setSelectedCustomerId,
    setFilter
  } = useContext(CustomerContext);
  const {
    isSuperUser,
    currentUser,
  } = useContext(SuperUserContext);
  const handleFilterChange = (e) => {
    setSelectedParticipantId(-1);
    setSelectedProjectId(-1);
    setProjects([]);
    setSelectedCustomerId(-1);
    setFilter(e.target.value);
  };

  useEffect(() => {
    const fetcher = async () =>
      Promise.all([fetchUserCustomers(currentUser)]).then(() => setIsLoaded(true));
    fetcher();
  }, [currentUser]);

  return (
    <div className="Main-body">
      <div className="Item-list-container">
        {isLoaded && (
          <div>
            <FilterBanner
              handleFilterChange={handleFilterChange}
              showOnlyMyCustomers={showOnlyMyCustomers}
              toggleShowOnlyMyCustomers={toggleShowOnlyMyCustomers}
              isSuperUser={isSuperUser}
            />
            <div className="Item-list-container">
              <CustomerList fetchCustomers={fetchCustomers} />
              <ProjectList />
              <ParticipantList />
              <ParticipantInfoList />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerComponentParent;
