import "./FormListItem.css";
import { useLayoutEffect, useState, createRef } from "react";
import M from "materialize-css";

const FormListItem = (props) => {
  const { form } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = createRef();

  useLayoutEffect(() => {
    let elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, { enterDelay: 700 });
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    }
  }, [ref]);

  return (
    <div className="Item-card">
      <div
        className="F-body"
        style={{ backgroundColor: props.isDragging ? "#98C742" : "white" }}
      >
        <div className="Body-container Flex">
          <div className="Item-card-form-info">
            <p
              className={showTooltip ? "tooltipped" : ""}
              data-position="top"
              data-tooltip={form.name}
              ref={ref}
            >
              {form.name}
            </p>
            <p className="date">{form.configuration.deadline}</p>
          </div>
          <i className="material-icons Drag-handler">drag_handle</i>
        </div>
      </div>
    </div>
  );
};

export default FormListItem;
