import React from "react";
import "./DropDownMenu.css";

const DropDownMenu = ({ ulId, menuObjects, isFetching }) => {
  return (
    <div data-testid="dropdown-menu">
      <i
        className="material-icons small tree-circles dropdown-trigger"
        onClick={(e) => {
          if(isFetching) return;
          e.stopPropagation();
        }}
        data-target={ulId}
        id="visible-button"
      >
        more_vert
      </i>
      <ul
        id={ulId}
        data-testid={ulId}
        className="dropdown-content"
        style={{ width: "150px" }}
        tabIndex="-1"
      >
        {menuObjects.map((menuObject, index) => (
          <div key={index}>
            <li>
              <p onClick={menuObject.customOnClick}>
                <i className="material-icons tiny">{menuObject.icon}</i>{" "}
                {menuObject.text}
              </p>
            </li>
            {index !== menuObjects.length - 1 && (
              <li className="divider" tabIndex="-1"></li>
            )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default DropDownMenu;
