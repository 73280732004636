import React, { useContext } from "react";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import { UsersContext } from "../../../Utils/contexts/UsersContext";
import { promoteUser } from "../../../Utils/functions/api/usersApi";
import ApiError from "../../../Utils/error/ApiError";
import { useSnackbar } from "notistack";

const PromoteUserModal = ({ user, indentifyer = "PromoteUserModal" }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { users, setUsers } = useContext(UsersContext);

  const closeModal = () => {
    document.getElementById(indentifyer).M_Modal.close();
  };

  const submitPromoteUserForm = async (user) => {
    try {
      const res = await promoteUser(user);
      createNotification(`"${user}" har befordrats till SuperUser.`,"success");
      let newUsers = JSON.parse(JSON.stringify(users));
      newUsers.find((x) => x.username === user).isSuperUser = true;
      setUsers(newUsers);
      closeModal();
    } catch (err) {
      createNotification(`Kunde inte befordra "${user}".`,"error");
      closeModal();
    }
  };

  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, {variant});
  };

  return (
    <FinalFormModal
      modalHTMLIdentifier={indentifyer}
      inputFields={[]}
      initialValues={[]}
      onClick={(e) => e.stopPropagation()}
      onSubmit={() => submitPromoteUserForm(user)}
      submitButtonName={"Ja"}
      cancelButtonName={"Nej"}
      formInfo={{
        header: `Vill du göra användaren ${user} till SuperUser?`,
      }}
      errorMessage=""
    />
  );
};

export default PromoteUserModal;
