import React, { useEffect, useContext, useState } from "react";
import QuestionItem from "../QuestionItem/QuestionItem";
import Preloader from "../../General/Preloader/Preloader";
import TraitTitle from "../TraitTitle/TraitTitle";
import "./QuestionList.css";
import M from "materialize-css";
import { QuestionContext } from "../../../Utils/contexts/QuestionContext";
import QuestionItemDropDown from "../QuestionItemDropDown/QuestionItemDropDown";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { validator, scoreValidator } from "../../../Utils/functions/inputFieldValidation";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import FinalFormQuestionModal from "../FinalFormQuestionModal/FinalFormQuestionModal";
import FinalFormCreateQuestionModal from "../FinalFormQuestionModal/FinalFormCreateQuestionModal";
import { LANGUAGE } from "../../../Utils/language";

const QuestionList = () => {
  const {
    questions,
    selectedQuestion,
    traits,
    language,
    setLanguage,
    filter,
    setFilter,
    fetchQuestions,
    changeOrder,
    setSequence,
    createQuestionRequest,
    isLoaded,
    setIsViewingAllQuestions,
    updateQuestionRequest,
    deleteQuestionRequest,
    sequence
  } = useContext(QuestionContext);

  const [newOrderNumber, setNewOrderNumber] = useState(0);
  const [oldOrderNumber, setOldOrderNumber] = useState(0);
  const [draggedQuestion, setDraggedQuestion] = useState("");

  const [newQuestionId, setNewQuestionId] = useState("");
  useEffect(() => {
    document.getElementById(`question-item-${newQuestionId}`)?.scrollIntoView();
  }, [newQuestionId]);

  useEffect(() => {
    M.AutoInit();
    let elems = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });
    
    let elem = document.querySelectorAll(".modal");
    const options = {
      onOpenStart:() => {setLanguage(LANGUAGE.SWEDISH);},
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, [questions, filter]);

  useEffect(() => {
    M.FormSelect.init(document.getElementById("TraitPickerDropDownSelectorInQuestionView"));
  }, [language]);
  const handleTraitChange = (e) => {
    setFilter(e.target.value);
    const valueString = e.target.value;
    if(valueString.length > 0){
      setIsViewingAllQuestions(false);
    } else if (valueString.length === 0){
      setIsViewingAllQuestions(true);
    }
  };
  const onDragEnd = ({ destination, source }) => {
    if(destination === null) return;
    if (!destination.droppableId.includes("questionList")) {
      return;
    }
    if(destination.index === source.index) return;
    setNewOrderNumber(destination.index);
    setOldOrderNumber(source.index);
    setDraggedQuestion(questions[source.index - 1].id);
    document
      .getElementById("ChangeOrderNumberConfirmationModal")
      .M_Modal.open();
  };

  const submitChangeOrderNumber = async () => {
    await changeOrder(draggedQuestion, newOrderNumber);
    await fetchQuestions();
    document
      .getElementById("ChangeOrderNumberConfirmationModal")
      .M_Modal.close();
  };
  const openCreateQuestionModal = () => {
    setSequence([1,2,3,4,5]);
    document
      .getElementById("createQuestionModal")
      .M_Modal.open();
    if(language === "SE"){
      document.getElementById("Fråga ").focus();
    } else if (language === "EN") {
      document.getElementById("Question ").focus();
    }
  };
  const validateString = (value) => {
    const validation = validator.validate({ inputString: value });
    if (!(validation.error === undefined)) {
      return false;
    }
  
    return true;
  };

  const validateScore = (value) => {
    const validation = scoreValidator.validate(value);
    if (!(validation.error === undefined)) {
      return false;
    } 
    return true;
  };
  const submitCreateQuestion = async (values, form) => {
    const traitId = values.Traits;
    const answerSequence = {};
    const keySwe =  `${"Svarsalternativ"} 5`;
    const keyEng =  `${"Answer option"} 5`;
    const result = {};
    sequence.map((e, index) => {
      const order = index + 1;
      switch(e){
        case 1:
          answerSequence[`answer${order}`] = "FIRST";
          break;
        case 2: 
          answerSequence[`answer${order}`] = "SECOND";
          break;
        case 3:
          answerSequence[`answer${order}`] = "THIRD";
          break;
        case 4: 
          answerSequence[`answer${order}`] = "FOURTH";
          break;
        case 5:
          answerSequence[`answer${order}`] = "FIFTH";
          break;
        default:
          result[key] = "Inkorrekt svarsordning";
          return result;   
      }
    });
    const scores = {
      answer1: values["Points 1"],
      answer2: values["Points 2"],
      answer3: values["Points 3"],
      answer4: values["Points 4"],
      answer5: values["Points 5"],
    };
    const scoreList = Object.keys(scores);
    for(const score in scoreList) {
      if(!validateScore(scores[scoreList[score]])){
        result[keySwe] = `Poäng ${parseInt(score) + 1} måste vara 0-10`;
        result[keyEng] = `Point ${parseInt(score) + 1} has to be 0-10`;
        return result;   
      }
    };

    if(!validateString(values["Fråga "])){
      result[keySwe] = "Fel i svenska frågeformuleringen";
      result[keyEng] = "Error in swedish question formulation";
      return result;   
    }
    if(!validateString(values["Question "])){
      result[keySwe] = "Fel i engelska frågeformuleringen";
      result[keyEng] = "Error in english question formulation";
      return result;   
    }
    for(let i = 1; i < 6; i++){
      const answerSwe =  `${"Svarsalternativ"} ${i}`;
      const answerEng =  `${"Answer option"} ${i}`;
      if(!validateString(values[answerSwe])){
        result[keySwe] = `Fel i svenska svarsalternativ ${i}`;
        result[keyEng] = `Error in swedish answer option ${i}`;
        return result;   
      }
      if(!validateString(values[answerEng])){
        result[keySwe] = `Fel i engelska svarsalternativ nummer ${i}`;
        result[keyEng] = `Error in english answer option ${i}`;
        return result;   
      }
    };
    const translations = [{
      language: "SE",
      formulation: values["Fråga "],
      answerOptions: {
        answer1: values["Svarsalternativ 1"],
        answer2: values["Svarsalternativ 2"],
        answer3: values["Svarsalternativ 3"],
        answer4: values["Svarsalternativ 4"],
        answer5: values["Svarsalternativ 5"],
      }
    },{
      language: "EN",
      formulation: values["Question "],
      answerOptions: {
        answer1: values["Answer option 1"],
        answer2: values["Answer option 2"],
        answer3: values["Answer option 3"],
        answer4: values["Answer option 4"],
        answer5: values["Answer option 5"],
      }
    }];
    await createQuestionRequest(traitId, translations, scores, answerSequence);
    form.reset();
    setLanguage(LANGUAGE.SWEDISH);
    setNewQuestionId(questions.length-1);
  };  

  const submitDeleteQuestion = async () => {
    await deleteQuestionRequest(selectedQuestion?.orderNumber, selectedQuestion?.id);
    document.getElementById("DeleteQuestionModal").M_Modal.close();
  };
  const submitUpdateQuestion = async (values, form) => {
    const questionId = selectedQuestion?.id;
    const traitId = values.TraitsUpdate;
    const keySwe =  `${"Svarsalternativ"}: 5`;
    const keyEng =  `${"Answer option"}: 5`;
    const result = {};
    const answerSequence = {};
    sequence.map((e, index) => {
      const order = index + 1;
      switch(e){
        case 1:
          answerSequence[`answer${order}`] = "FIRST";
          break;
        case 2: 
          answerSequence[`answer${order}`] = "SECOND";
          break;
        case 3:
          answerSequence[`answer${order}`] = "THIRD";
          break;
        case 4: 
          answerSequence[`answer${order}`] = "FOURTH";
          break;
        case 5:
          answerSequence[`answer${order}`] = "FIFTH";
          break;
        default: 
          throw new Error("Invalid order sequence");    
      }
    });
    const scores = {
      answer1: values["Points: 1"],
      answer2: values["Points: 2"],
      answer3: values["Points: 3"],
      answer4: values["Points: 4"],
      answer5: values["Points: 5"],
    };
    const scoreList = Object.keys(scores);
    for(const score in scoreList) {
      if(!validateScore(scores[scoreList[score]])){
        result[keySwe] = `Poäng ${parseInt(score) + 1} måste vara 0-10`;
        result[keyEng] = `Point ${parseInt(score) + 1} has to be 0-10`;
        return result;   
      }
    };

    if(!validateString(values["Fråga"])){
      result[keySwe] = "Fel i svenska frågeformuleringen";
      result[keyEng] = "Error in swedish question formulation";
      return result;   
    }
    if(!validateString(values["Question"])){
      result[keySwe] = "Fel i engelska frågeformuleringen";
      result[keyEng] = "Error in english question formulation";
      return result;   
    }
    for(let i = 1; i < 6; i++){
      const answerSwe =  `${"Svarsalternativ:"} ${i}`;
      const answerEng =  `${"Answer option:"} ${i}`;
      if(!validateString(values[answerSwe])){
        result[keySwe] = `Fel i svenska svarsalternativ ${i}`;
        result[keyEng] = `Error in swedish answer option ${i}`;
        return result;   
      }
      if(!validateString(values[answerEng])){
        result[keySwe] = `Fel i engelska svarsalternativ nummer ${i}`;
        result[keyEng] = `Error in english answer option ${i}`;
        return result;   
      }
    };
    const translations = [{
      language: "SE",
      formulation: values["Fråga"],
      answerOptions: {
        answer1: values["Svarsalternativ: 1"],
        answer2: values["Svarsalternativ: 2"],
        answer3: values["Svarsalternativ: 3"],
        answer4: values["Svarsalternativ: 4"],
        answer5: values["Svarsalternativ: 5"],
      }
    },{
      language: "EN",
      formulation: values["Question"],
      answerOptions: {
        answer1: values["Answer option: 1"],
        answer2: values["Answer option: 2"],
        answer3: values["Answer option: 3"],
        answer4: values["Answer option: 4"],
        answer5: values["Answer option: 5"],
      }
    }];
    await updateQuestionRequest(selectedQuestion?.orderNumber, questionId, traitId, translations, scores, answerSequence);
    form.reset();
  };  
  return (
    <div className="Questions-container">
      <div className="Card-list">
        <TraitTitle title="Frågor" />
        <div style={{width: "60%"}} className="input-field">
          <select
            id="TraitPickerDropDownSelectorInQuestionView"
            className="Drop-down-selector hideSelector Trait"
            onChange={handleTraitChange}
          >
            <option key={"alla"} value={""}>
              {" "}
              Alla frågor{" "}
            </option>
            {traits.map((trait, index) => (
              <option key={index} value={trait.symbol}>
                {trait.symbol}:{" "}
                {
                  trait.name.find((translation) =>
                    translation.language.includes(language)
                  ).value
                }
              </option>
            ))}
          </select>
          <label>Visa</label>
        </div>
        <div className="body">
          <div className="Items-questions Items-questions-min-width">
            {isLoaded ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="questionList" type="question">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="Report-form-dnd"
                      style={{
                        backgroundColor: snapshot.isDraggingOver
                          ? "grey"
                          : "#f6f6f6",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                      {...provided.droppableProps}
                    >
                      <div>
                        <div>
                          {questions
                            .filter((question) =>
                              question.trait.symbol.includes(filter)
                            )
                            .map((question, index) => {
                              return (
                                <Draggable
                                  draggableId={`draggable-${question.id}`}
                                  key={question.id}
                                  index={question.orderNumber}
                                  isDragDisabled={filter==="" ? false: true}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <QuestionItem
                                        question={question}
                                        key={index}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                        </div>
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Preloader size="big" />
            )}
          </div>
          <p
            className="button"
            onClick={openCreateQuestionModal}
            data-testid={"oitatus"}
          >
            <i className="material-icons">add</i>Lägg till ny fråga
          </p>
        </div>
      </div>
      <FinalFormModal
        modalHTMLIdentifier={"ChangeOrderNumberConfirmationModal"}
        inputFields={[]}
        onSubmit={submitChangeOrderNumber}
        submitButtonName={"Ja"}
        cancelButtonName={"Nej"}
        formInfo={{
          header: `Är du säker på att du vill flytta "${questions[oldOrderNumber- 1]?.translations.find(t => t.language === language).formulation}" från plats ${oldOrderNumber} till "${newOrderNumber}"?`,
        }}
      />
      <FinalFormModal
        modalHTMLIdentifier={"DeleteQuestionModal"}
        inputFields={[]}
        onSubmit={submitDeleteQuestion}
        submitButtonName={"Ja"}
        cancelButtonName={"Nej"}
        formInfo={{
          header: "Är du säker på att du vill ta bort frågan?",
        }}
      />
      <FinalFormQuestionModal
        modalHTMLIdentifier={"questionsModal"}
        inputFields={[]}
        onSubmit={submitUpdateQuestion}
        submitButtonName={"Spara"}
        cancelButtonName={"Avbryt"}
        formInfo={{
          header: "Ändra fråga",
        }}
      />
      <FinalFormCreateQuestionModal
        modalHTMLIdentifier={"createQuestionModal"}
        inputFields={[]}
        onSubmit={submitCreateQuestion}
        submitButtonName={"Skapa"}
        cancelButtonName={"Avbryt"}
        formInfo={{
          header: "Skapa fråga",
        }}
      />
      {/* )} */}
      
    </div>
  );
};

export default QuestionList;
