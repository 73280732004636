import React, { createRef, useContext, useEffect, useLayoutEffect, useState } from "react";
import M from "materialize-css";
import RemoveProject from "../../../Utils/functions/RemoveProject";
import FinalFormModal from "../../General/FinalFormModal/FinalFormModal";
import { validator } from "../../../Utils/functions/inputFieldValidation";
import UpdateProjectName from "../../../Utils/functions/UpdateProjectName";
import { CustomerContext } from "../../../Utils/contexts/CustomerContext";
import DropDownMenu from "../../General/DropDownMenu/DropDownMenu";
import { useSnackbar } from "notistack";

const ProjectListItem = ( {project, createNotification} ) => {

  const {
    selectedCustomerId,
    setSelectedParticipantId,
    selectedProjectId, setSelectedProjectId,
    fetchProjects,
  } = useContext(CustomerContext);

  const [showTooltip, setShowTooltip] = useState(false);
  const ref = createRef();
  useEffect(() => {
    M.AutoInit();
    let elems = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });

    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, []);

  useLayoutEffect(() => {
    var elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, {enterDelay: 700});  
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    }
  }, [ref]);

  const submitRemoveProjectForm = async () => {
    try {
      if (project.id === selectedProjectId) {
        setSelectedParticipantId(-1);
        setSelectedProjectId(-1);
      } 
      await RemoveProject(project.id);
      document.getElementById(`removeProjectModal${project.id}_${selectedCustomerId}`).M_Modal.close();
      createNotification(`"${project.projectName}" har raderats.`, "success");
      await fetchProjects(selectedCustomerId);
    } catch (error) {
    }
  };

  const openUpdateProjectModal = (e) => {
    e.stopPropagation();
    document.getElementById(`updateProjectModal${project.id}_${selectedCustomerId}`).M_Modal.open();
    document.getElementById(`Ändra ${project.id}`).focus();
  };

  const openRemoveProjectModal = (e) => {
    e.stopPropagation();
    document.getElementById(`removeProjectModal${project.id}_${selectedCustomerId}`).M_Modal.open();
  };

  const selectProject = (e) => {
    e.stopPropagation();
    setSelectedParticipantId(-1);
    setSelectedProjectId(project.id);
  };

  const submitUpdateProjectForm = async (values, form) => {
    try {
      const newProjectName = values["Ändra projektnamn"];
      if(validator.validate({inputString: newProjectName}).error !== undefined){
        createNotification("Felaktigt projektnamn", "error");
        return { "Ändra projektnamn": "Felaktigt projektnamn" };
      };
      document.getElementById(`updateProjectModal${project.id}_${selectedCustomerId}`).M_Modal.close();
      await UpdateProjectName(project, newProjectName);
      createNotification(`"${project.projectName}" har ändrats till "${newProjectName}."`, "success");
      setSelectedProjectId(project.id);
      await fetchProjects(selectedCustomerId);
      form.reset();
    } catch (error) {
    }
  };

  const menuObjects = [
    {
      text:"Ändra",
      icon:"edit",
      customOnClick: openUpdateProjectModal
    },
    {
      text:"Ta bort",
      icon:"delete",
      customOnClick: openRemoveProjectModal
    }
  ];

  return (
    <div
      className="Item-card"
      onClick={selectProject}
      id={`project-list-${project.id}`}
    >

      <FinalFormModal
        //Must match data-targer of trigger
        modalHTMLIdentifier={`updateProjectModal${project.id}_${selectedCustomerId}`}
        //variable amount of input fields as array
        inputFields={[
          {
            header: "Ändra projektnamn",
            defaultValue: "",
            id: `Ändra ${project.id}`,
            placeHolder: "Skriv in projektnamn",
            type: "text"
          }
        ]}
        //must match name of headers in input field array
        initialValues={
          {
            "Ändra projektnamn": project.projectName,
          }
        }
        //u know wat onsubmit does lmao
        onSubmit={submitUpdateProjectForm}
        submitButtonName={"Spara"}
        cancelButtonName={"Avbryt"}
        formInfo={{
          header: "Ändra projektnamn"
        }}
      />

      <FinalFormModal
        //Must match data-targer of trigger
        modalHTMLIdentifier={`removeProjectModal${project.id}_${selectedCustomerId}`}
        //variable amount of input fields as array
        inputFields={[]}
        //must match name of headers in input field array
        initialValues={{Projektnamn: project.projectName}}
        //u know wat onbmit does lmao
        onClick={(e) => e.stopPropagation()}
        onSubmit={submitRemoveProjectForm}

        submitButtonName={"Ja"}
        cancelButtonName={"Nej"}
        formInfo={{
          header: `Vill du ta bort ${project.projectName}?`
        }}
      />

      <div className={(project.id === selectedProjectId) ? "body-green" : "body"}>
        <div className="Body-container">
          <p className={showTooltip ? "tooltipped" : ""} data-position="top" data-tooltip={project.projectName} ref={ref}>{project.projectName}</p>
          <DropDownMenu ulId={`projectId_${project.id}`} menuObjects={menuObjects} />
        </div>
      </div>
    </div>
  );
};

export default ProjectListItem;
