import React, { useEffect, useContext, useState } from "react";
import TraitItem from "../TraitItem/TraitItem";
import Preloader from "../../General/Preloader/Preloader";
import TraitTitle from "../../Questions/TraitTitle/TraitTitle";
import "../../Questions/QuestionList/QuestionList.css";
import { AutoInit, Dropdown } from "materialize-css";
import { QuestionContext } from "../../../Utils/contexts/QuestionContext";
import { API, graphqlOperation } from "aws-amplify";
import { updateTrait, createTrait } from "../../../graphql/mutations";
import { useSnackbar } from "notistack";
import { validator } from "../../../Utils/functions/inputFieldValidation";

import FinalFormTraitModal from "../FinalFormTraitModal/FinalFormTraitModal";

const TraitList = () => {
  const {
    traits,
    isLoaded,
    fetchTraits,
    selectedTraitId,
    setSelectedTraitId,
  } = useContext(QuestionContext);

  const [newTraitId, setNewTraitId] = useState("");
  useEffect(() => {
    if (newTraitId !== ""){
      document.getElementById(`trait-item-${newTraitId}`)?.scrollIntoView();
    }
  }, [newTraitId]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    AutoInit();
    let elems = document.querySelectorAll(".dropdown-trigger");
    Dropdown.init(elems, { inDuration: 300, outDuration: 225 });

    let elem = document.querySelectorAll(".modal");
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(elem, options);
  }, [traits]);
  
  const validateString = (value) => {
    const validation = validator.validate({ inputString: value });
    if (!(validation.error === undefined)) {
      return false;
    }
  
    return true;
  };

  const update = async (id, names, idealScenes) => {
    await API.graphql(
      graphqlOperation(updateTrait, { id, names, idealScenes })
    );
    await fetchTraits();
  };
  const create = async (names, idealScenes) => {
    await API.graphql(graphqlOperation(createTrait, { names, idealScenes }));
    return await fetchTraits();
  };
  const openCreateTraitModal = () => {
    setSelectedTraitId(0);
    document.getElementById("traitModal").M_Modal.open();
    document.getElementById("addSwedishCategoryName").focus();
  };

  const openUpdateTraitModal = () => {
    document.getElementById("traitModal").M_Modal.open();
  };

  const submitTrait = async (values, form) => {
    if(selectedTraitId === 0) {
      return submitCreateTrait(values, form);
    } else {
      return submitUpdateTrait(values, form);
    }
    
  };

  const submitUpdateTrait = async (values, form) => {
    if(!validateString(values["Kategorinamn"])){
      return {"Kategorinamn": "Fel i kategorinamn"};
    }
    if(!validateString(values["Area name"])){
      return {"Area name": "Fel i area name"};
    }
    if(!validateString(values["Idealt scenario"])){
      return {"Idealt scenario": "Fel i idealt scenario"};
    }
    if(!validateString(values["Ideal scene"])){
      return {"Ideal scene": "Fel i ideal scene"};
    }
    const names = [
      { language: "SE", value: values["Kategorinamn"] },
      { language: "EN", value: values["Area name"] },
    ];
    const idealScenes = [
      { language: "SE", value: values["Idealt scenario"] },
      { language: "EN", value: values["Ideal scene"] },
    ];
    update(selectedTraitId, names, idealScenes)
      .then(() => {
        document.getElementById("traitModal").M_Modal.close();
        createNotification("Kategorin har uppdaterats.", "success");
        form.reset();
      })
      .catch(() => {
        createNotification(
          "Ett problem har uppstått i servern. Vänligen kontakta support.",
          "error"
        );
        return;
      });
  };
  const submitCreateTrait = async (values, form) => {
    if(!validateString(values["Kategorinamn"])){
      return {"Kategorinamn": "Fel i kategorinamn"};
    }
    if(!validateString(values["Area name"])){
      return {"Area name": "Fel i area name"};
    }
    if(!validateString(values["Idealt scenario"])){
      return {"Idealt scenario": "Fel i idealt scenario"};
    }
    if(!validateString(values["Ideal scene"])){
      return {"Ideal scene": "Fel i ideal scene"};
    }
    const names = [
      { language: "SE", value: values["Kategorinamn"] },
      { language: "EN", value: values["Area name"] },
    ];
    const idealScenes = [
      { language: "SE", value: values["Idealt scenario"] },
      { language: "EN", value: values["Ideal scene"] },
    ];
    create(names, idealScenes)
      .then((returnedTraitData) => {
        document.getElementById("traitModal").M_Modal.close();
        createNotification("Kategorin har lagts till.", "success");
        form.reset();
        setNewTraitId(returnedTraitData.find(t => t.name[0].value === names[0].value).id);
      })
      .catch(() => {
        createNotification(
          "Ett problem har uppstått i servern. Vänligen kontakta support.",
          "error"
        );
        return;
      });
  };

  const createNotification = (text, variant) => {
    enqueueSnackbar(`${text}`, { variant });
  };

  return (
    <div className="Questions-container">
      <div className="Card-list">
        <TraitTitle title="Kategorier" />
        <div className="body">
          <div className="Items-questions Items-questions-min-width">
            <div>
              {isLoaded ? (
                traits.map((trait) => {
                  return <TraitItem key={trait.id} trait={trait} openModal={openUpdateTraitModal} />;
                })
              ) : (
                <Preloader size="big" />
              )}
            </div>
          </div>
          <p
            className="button"
            onClick={openCreateTraitModal}
            data-testid={selectedTraitId}
          >
            <i className="material-icons">add</i>Lägg till ny kategori
          </p>
        </div>
      </div>
      <FinalFormTraitModal
        modalHTMLIdentifier={"traitModal"}
        inputFields={[]}
        onSubmit={submitTrait}
        submitButtonName={"Spara"}
        cancelButtonName={"Avbryt"}
        formInfo={{
          header: "Ändra kategori",
        }}
      />
    </div>
  );
};

export default TraitList;
